import { FC, useState } from "react";

import {
  ConfirmationDialog,
  Paragraph,
  PlayIcon,
  Tooltip,
  TooltipProps,
  useToast,
  WarningIcon,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useRunIdentityResolutionMutation } from "src/graphql";
import { useOutletContext } from "src/router";

import { OutletContext } from "src/pages/identity-resolution/graph";
import { isRunningGraphDisabled } from "src/pages/identity-resolution/utils";
import { PermissionedButton } from "src/components/permission";

type Props = {
  tooltipPlacement?: TooltipProps["placement"];
};

export const RunGraphButton: FC<Props> = ({ tooltipPlacement }) => {
  const { toast } = useToast();
  const { graph } = useOutletContext<OutletContext>();

  const runMutation = useRunIdentityResolutionMutation();

  const [isFullReRunConfirmationOpen, setIsFullReRunConfirmationOpen] =
    useState(false);

  const isDisabled = isRunningGraphDisabled(graph);

  const startRun = async ({ fullReRun }: { fullReRun: boolean }) => {
    try {
      await runMutation.mutateAsync({
        id: graph.id,
        fullReRun: fullReRun,
      });

      toast({
        id: "run-graph",
        title: "Identity graph run has been initiated.",
        variant: "success",
      });
    } catch (error) {
      captureException(error);
      toast({
        id: "run-graph",
        title: "There was a problem running your identity graph.",
        message: error.message,
        variant: "error",
      });
    }
  };

  const runGraph = () => {
    if (graph.full_rerun) {
      setIsFullReRunConfirmationOpen(true);
    } else {
      startRun({ fullReRun: false });
    }
  };

  return (
    <>
      <Tooltip
        isDisabled={!isDisabled}
        message="To run the graph, the graph must have at least one model and one merge rule defined"
        placement={tooltipPlacement}
      >
        <PermissionedButton
          isLoading={runMutation.isLoading}
          isDisabled={isDisabled}
          variant="primary"
          icon={isDisabled ? WarningIcon : PlayIcon}
          onClick={runGraph}
          permission={{
            v2: {
              resource: "idr",
              grant: "can_update",
              id: graph.id,
            },
          }}
        >
          Run
        </PermissionedButton>
      </Tooltip>

      <ConfirmationDialog
        title="Full re-run required"
        isOpen={isFullReRunConfirmationOpen}
        confirmButtonText="Confirm"
        variant="warning"
        onClose={() => setIsFullReRunConfirmationOpen(false)}
        onConfirm={() => startRun({ fullReRun: true })}
      >
        <Paragraph>
          This graph must be fully re-run. Would you like to proceed?
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};

import { FC, memo, ReactElement, useCallback, useState } from "react";

import { Box, Column, Spinner, Text } from "@hightouchio/ui";

import { AnalyticsGraph, SupportedGraphType } from "./analytics-graph";
import { GraphScale } from "./constants";
import {
  getTableHeight,
  MAX_SELECTED_SERIES,
  MAX_TABLE_HEIGHT,
  ResultsTable,
} from "./results-table";
import { Graph } from "./types";

type Props = {
  graph: Graph;
  isLoading?: boolean;
  placeholder: ReactElement;
  scale: GraphScale;
  selectedRows: (string | number)[];
  onSelect: (key: string | number | (string | number)[]) => void;
  type?: SupportedGraphType;
};

export const CrossAudienceGraph: FC<Props> = ({
  graph,
  isLoading,
  placeholder,
  scale = GraphScale.Linear,
  selectedRows,
  onSelect,
  type,
}) => {
  const [hoveredLine, setHoveredLine] = useState<string>();

  const onMouseEnterRow = useCallback(
    (key: string | number) => {
      if (typeof key === "string") {
        setHoveredLine(key);
      }
    },
    [setHoveredLine],
  );

  const onMouseLeaveRow = useCallback(() => {
    setHoveredLine(undefined);
  }, [setHoveredLine]);

  if (isLoading) {
    return (
      <Column
        align="center"
        justifyContent="center"
        flex={1}
        minHeight={0}
        gap={4}
      >
        <Spinner size="lg" />
        <Text color="text.secondary" size="sm">
          Query processing...
        </Text>
      </Column>
    );
  }

  // Don't show more than MAX_LINES to prevent page from crashing.
  const series = graph.series
    .filter(({ key }) => selectedRows.includes(key))
    .slice(0, MAX_SELECTED_SERIES);

  if (!graph.series.length) {
    return placeholder;
  }

  const tableHeight = getTableHeight(graph.series.length);

  const hasSummaryStats = Boolean(graph.summary.length);
  const height = hasSummaryStats
    ? "400px"
    : `calc(100% - ${tableHeight > MAX_TABLE_HEIGHT ? MAX_TABLE_HEIGHT : tableHeight}px)`;

  return (
    <>
      <Box pr={6} pb={1} height={height} overflowX="hidden">
        <AnalyticsGraph
          graphSeries={series}
          hoveredLine={hoveredLine}
          onHoverLine={setHoveredLine}
          height="100%"
          scale={scale}
          type={type}
        />
      </Box>
      <MemoizedResultsTable
        graph={graph}
        scale={scale}
        selectedRows={selectedRows}
        onSelect={onSelect}
        onMouseEnterRow={onMouseEnterRow}
        onMouseLeaveRow={onMouseLeaveRow}
      />
    </>
  );
};

// Results table is a pretty heavy component, so memoize it to prevent unnecessary re-renders
// TODO: it may also be useful to clean up the internals of the table
const MemoizedResultsTable = memo(ResultsTable);

import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  AudienceIcon,
  AudienceTemplateIcon,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CloseIcon,
  CodeIcon,
  Column,
  Combobox,
  Dialog,
  EmptyState,
  IconButton,
  InformationIcon,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  RedoIcon,
  RefreshIcon,
  Row,
  Spinner,
  SqlIcon,
  Switch,
  Text,
  Tooltip,
  UndoIcon,
  useToast,
} from "@hightouchio/ui";
import { motion } from "framer-motion";
import { useFlags } from "launchdarkly-react-client-sdk";

import { ActionBar, ActionBarHeight } from "src/components/action-bar";
import {
  SlideInAnimationTime,
  SlideInOutAndHide,
  SlideOutAnimationTime,
} from "src/components/animations";
import { Editor } from "src/components/editor";
import { QueryBuilder } from "src/components/explore/query-builder";
import { PermissionedButton } from "src/components/permission";
import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  useAudienceExploreDependenciesQuery,
  useRelatedAudiencesQuery,
  useVisualQuerySqlQuery,
} from "src/graphql";
import { useMeasureHeightOnce } from "src/hooks/use-measured-height-once";
import * as analytics from "src/lib/analytics";
import {
  AndCondition,
  AudienceTemplateDefinition,
  isMergedColumn,
  OrCondition,
  RootCondition,
  SizeCap,
} from "src/types/visual";
import { SIZES } from "src/ui/box";
import { EMPTY_AUDIENCE_DEFINITION, useModelRun } from "src/utils/models";
import { commaNumber } from "src/utils/numbers";
import { useCalculateAudienceSize } from "src/utils/use-calculate-audience-size";
import { Magic } from "src/components/explore/magic";
import { SubsetSelector } from "src/components/explore/subset-selector";
import {
  cssVariableTopOffset,
  cssVariableTopSectionHeight,
  cssVariableVisibleTopSectionHeight,
} from "src/components/layout/detail-page";
import { DefaultPageContainerPadding } from "src/components/layout/page-container";
import {
  ResourcePermissionInput,
  useResourcePermission,
} from "src/components/permission/use-resource-permission";
import { AudienceTemplateSelectorModal } from "src/pages/templates/audience-templates/selector-modal";
import { Warning } from "src/components/warning";

import { AudienceSizeCap } from "./audience-size-cap";
import { NumericFontStyles } from "./constants";
import { MembersDrawer } from "./member-details-drawer/member-drawer";
import { AudienceExplorePageProps } from "./types";
import { hasQueryChanged, useLastUsedQuery } from "./use-last-used-query";
import { toSingleCondition } from "./utils";
import { ShowInsightsButton } from "./insights/show-insights-button";
import { AudienceInsights } from "./insights";
import audiencePlaceholder from "src/assets/placeholders/audience.svg";
import { AudienceTemplateSelector } from "./audience-template-selector";
import { FullParentModel } from "src/components/audiences/types";

const actionBarDefaultHeight = 48;
const footerDefaultHeight = 0;
const insightsPadding = 24;
// Card width + padding + scrollbar width
const insightsWidth = 400 + 24 * 2 + 6;

export const AudienceExplore: FC<Readonly<AudienceExplorePageProps>> = ({
  audience,
  bodyOverflow = "",
  parentModel,
  source,
  onSave,
  modelState,
  variant,
}) => {
  const { toast } = useToast();
  const {
    aiAudienceGeneration,
    appDestinationRulesEnabled,
    audienceTemplatesEnabled,
    enableMemberDetails,
    restrictMergedColumns,
    enableRealtimeAudiences,
  } = useFlags();

  const { hasValidationErrors } = useFormErrorContext();

  const [destinationId, setDestinationId] = useState<string | undefined>();
  const [isInsightsOpen, setIsInsightsOpen] = useState(false);

  const [showMergedColumnsInPreview, setShowMergedColumnsInPreview] =
    useState(false);

  const audienceExploreDependencies = useAudienceExploreDependenciesQuery(
    {
      parentModelId: parentModel?.id?.toString() ?? "",
      loadAudienceDependencies: variant === "audience",
    },
    {
      enabled: Boolean(parentModel?.id),
      select: (data) => data.segments_by_pk,
    },
  );

  const dependenciesLoading = audienceExploreDependencies.isLoading;
  const parentModelDependencies = audienceExploreDependencies.data;

  const hasSampledSegments = Boolean(
    parentModelDependencies?.enabled_sampled_segments_aggregate.aggregate
      ?.count,
  );
  const [isFastQueryEnabled, setIsFastQueryEnabled] =
    useState(hasSampledSegments);

  useEffect(() => {
    if (parentModelDependencies) {
      setIsFastQueryEnabled(hasSampledSegments);
    }
  }, [parentModelDependencies, hasSampledSegments]);

  const fullParentModel: FullParentModel | undefined = useMemo(() => {
    if (!parentModel || !parentModelDependencies) {
      return undefined;
    }

    return {
      ...parentModel,
      ...parentModelDependencies,
    };
  }, [parentModel, parentModelDependencies]);

  const {
    runQuery,
    cancelQuery,
    resetRunState,
    rows,
    numRowsWithoutLimit,
    loading,
    error,
    usedSampledModels: usedFastQueryForRun,
  } = useModelRun(
    modelState.state,
    // Arguments for the model run query
    {
      columns: parentModel?.columns,
      includeMergedColumns: enableMemberDetails && showMergedColumnsInPreview,
      mergedColumns: (parentModel?.filterable_audience_columns ?? []).filter(
        (col) => isMergedColumn(col.column_reference),
      ),
      useSampledModels: isFastQueryEnabled,
      onCompleted: (data, error) => {
        if (data) {
          setTransformedSql((state) => ({
            ...state,
            // @ts-expect-error upgraded type from `any`, transformedSql is returned at another level
            sql: data?.transformedSql,
            fetchedAt: Date.now(),
            loading: false,
          }));
        }
        if (error) {
          toast({
            id: "audience-preview-error",
            title: "Audience preview failed",
            message: error,
            variant: "error",
          });
        }
      },
    },
  );

  useEffect(() => {
    if (showMergedColumnsInPreview) {
      resetRunState();
      runQuery({ limit: true, disableRowCounter: true });
    }
  }, [showMergedColumnsInPreview]);

  const visualQueryFilter = modelState.state.visual_query_filter;
  const rootCondition = toSingleCondition(visualQueryFilter?.conditions)?.[0];
  const emptyConditions =
    !rootCondition || rootCondition.conditions.length === 0;

  const isCreatingAudience =
    modelState.state.id == null && variant === "audience";

  const showAudienceTemplateSelector = isCreatingAudience && emptyConditions;

  const subsetIds = useMemo(
    () =>
      modelState.state.subsets?.map((subset) => subset.subset_value.id) ?? [],
    [modelState.state.subsets],
  );
  const sizeCap = visualQueryFilter?.sizeCap;

  const breakdownColumnOptions = useMemo(() => {
    const columns = parentModel?.filterable_audience_columns ?? [];
    return restrictMergedColumns
      ? columns.filter((column) => column.column_reference.type === "raw")
      : columns;
  }, [parentModel?.filterable_audience_columns, restrictMergedColumns]);

  const {
    audienceSize,
    audienceSizeUpdatedAt,
    isLoading: calculatingAudienceSize,
    lastUsedQuery: lastUsedAudienceSizeQuery,
    calculateAudienceSize,
    cancelCalculateAudienceSize,
    updateAudienceSize,
  } = useCalculateAudienceSize({
    audienceId: modelState.state.id?.toString(),
    parentModelId: parentModel?.id?.toString() ?? "",
    sourceId: source?.id?.toString() ?? "",
    visualQueryFilter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
    subsetIds,
    isFastQuery: isFastQueryEnabled,
    destinationId,
  });

  const sizeLoading = loading || calculatingAudienceSize;

  const cancelQueryAndSizeCalculation = () => {
    cancelCalculateAudienceSize();
    cancelQuery();
  };

  const saveSizeCap = async (payload: SizeCap | undefined): Promise<void> => {
    const newVisualQueryFilter = {
      ...(visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION),
      sizeCap: payload,
    };

    // If the audience is being created or cloned, we don't want to actually "save" the size cap:
    //   1) the onSave callback prop is undefined
    //   2) that would attempt to save the full audience entity prematurely
    // Instead, we'll just update the visual query object.
    if (onSave == undefined) {
      modelState.onChange({ visual_query_filter: newVisualQueryFilter });
    } else {
      setSaveLoading(true);
      await onSave({ visual_query_filter: newVisualQueryFilter }, false);
      setSaveLoading(false);
    }

    resetRunState();
  };

  const { refetch: refetchVisualQuerySql } = useVisualQuerySqlQuery(
    {
      audience_id: modelState.state.id?.toString(),
      connection_id:
        modelState.state.connection?.id?.toString() ??
        source?.id?.toString() ??
        "",
      parent_model_id:
        modelState.state.visual_query_parent_id?.toString() ??
        parentModel?.id?.toString() ??
        "",
      filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
      subset_ids: subsetIds,
      useSampledModels: isFastQueryEnabled,
      destinationId,
    },
    {
      enabled: false,
    },
  );

  const [audienceTemplateModal, setAudienceTemplateModal] = useState<{
    isOpen: boolean;
    highlightTemplateId?: string;
  }>({ isOpen: false });

  const [transformedSql, setTransformedSql] = useState<{
    sql: string | undefined | null;
    fetchedAt: number | undefined;
    loading: boolean;
    visible: boolean;
  }>({
    sql: undefined,
    fetchedAt: undefined,
    loading: false,
    visible: false,
  });

  const sentinelRef = useRef<HTMLDivElement>(null);
  const actionBarRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [isMembersDrawerOpen, setIsMembersDrawerOpen] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [isActionBarStuckToTop, setIsActionBarStuckToTop] = useState(false);

  // Add box shadow to action bar when it's stuck to top
  useEffect(() => {
    const sentinelElementIntersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isStuck =
            entry.boundingClientRect.top <= 0 && !entry.isIntersecting;

          setIsActionBarStuckToTop(isStuck);
        });
      },
      {
        threshold: [1],
      },
    );

    if (sentinelRef.current) {
      sentinelElementIntersectionObserver.observe(sentinelRef.current);
    }

    return () => {
      sentinelElementIntersectionObserver.disconnect();
    };
  }, []);

  const footerBarHeight = useMeasureHeightOnce(footerRef, footerDefaultHeight);

  const calculateSizePermission: ResourcePermissionInput<"model", "audience"> =
    {
      v2: {
        resource: "model",
        grant: "can_create",
        creationOptions: {
          type: "audience",
          parentModelId: parentModel?.id?.toString(),
        },
      },
      v1: { resource: "audience", grant: "preview", id: modelState.state.id },
    };

  const accessDataPermission: ResourcePermissionInput<"model", "audience"> = {
    v2: modelState.state.id
      ? { resource: "model", id: modelState.state.id, grant: "can_preview" }
      : {
          resource: "model",
          grant: "can_preview",
          creationOptions: {
            type: "audience",
            parentModelId: parentModel?.id?.toString(),
          },
        },
    v1: { resource: "audience", grant: "preview", id: modelState.state.id },
  };

  const viewInsightsPermissions: ResourcePermissionInput<"model", "audience"> =
    {
      v2: modelState.state.id
        ? { resource: "model", id: modelState.state.id, grant: "can_update" }
        : {
            resource: "model",
            grant: "can_create",
            creationOptions: {
              type: "audience",
              parentModelId: parentModel?.id?.toString(),
            },
          },
      v1: { resource: "audience", grant: "preview", id: modelState.state.id },
    };

  const { isPermitted: isCalculateSizePermitted } = useResourcePermission(
    calculateSizePermission,
  );
  const { isPermitted: isAccessDataPermitted } =
    useResourcePermission(accessDataPermission);

  const [lastUsedInsightsQuery, setLastUsedInsightsQuery] =
    useLastUsedQuery(null);

  const initialPreviewRun = Boolean(lastUsedAudienceSizeQuery);

  const currentQuery = {
    filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
    subsetIds,
    isFastQuery: isFastQueryEnabled,
    destinationId,
  };

  // Once audience members is 100%: refactor to be 2 variables -
  // one for isCountStale and another for isPreviewStale
  const isDataStale =
    initialPreviewRun &&
    hasQueryChanged(currentQuery, lastUsedAudienceSizeQuery);

  const copySQLToClipboard = () => {
    if (transformedSql.sql) {
      navigator.clipboard.writeText(transformedSql.sql);

      toast({
        id: "copy-sql",
        title: "SQL copied to clipboard",
        variant: "success",
      });
    }
  };

  const { data: relatedAudiences = [], isLoading: isRelatedAudiencesLoading } =
    useRelatedAudiencesQuery(
      {
        parentModelId: parentModel?.id?.toString() ?? "",
        modelId: modelState.state.id?.toString(),
        limit: 10000,
      },
      {
        refetchOnWindowFocus: true,
        staleTime: 1000 * 60, // 1 min
        notifyOnChangeProps: ["data", "isLoading"],
        select: (data) => data?.segments,
      },
    );

  const destinationOptions =
    parentModelDependencies?.destination_rules.map(({ destination }) => ({
      label: destination.name ?? destination.definition.name,
      value: String(destination.id),
      logo: destination.definition.icon,
    })) ?? [];

  const shouldFetchQuerySql = useMemo(() => {
    /**
     * If we have previewed or saved the query, then the generated SQL will have been cached in local state.
     * So we only need to regenerate the SQL if the filter conditions have changed.
     */
    if (transformedSql.sql && transformedSql.fetchedAt && isDataStale) {
      return audienceSizeUpdatedAt !== null
        ? audienceSizeUpdatedAt > transformedSql.fetchedAt
        : false;
    }

    /**
     * If the query hasn't been saved:
     * we don't have a way of knowing whether the query conditions have changed so we refetch to be safe.
     */
    return !transformedSql.sql || !rows;
  }, [
    audienceSizeUpdatedAt,
    transformedSql.sql,
    transformedSql.fetchedAt,
    isDataStale,
    rows,
  ]);

  const showSqlPreview = useCallback(async () => {
    if (shouldFetchQuerySql) {
      setTransformedSql((state) => ({
        ...state,
        loading: true,
        visible: true,
      }));
      try {
        const result = await refetchVisualQuerySql();
        setTransformedSql((state) => ({
          ...state,
          sql: result?.data?.visualQuerySQL?.sql,
          fetchedAt: Date.now(),
          loading: false,
        }));
      } catch (_err) {
        toast({
          id: "sql-preview",
          title: "SQL preview failed",
          message: "Please try again.",
          variant: "error",
        });
      }
    } else {
      setTransformedSql((state) => ({ ...state, visible: true }));
    }
  }, [shouldFetchQuerySql, refetchVisualQuerySql, setTransformedSql]);

  const handleSave = async () => {
    // Audience templates don't require validation upon saving
    // because they can intentionally leave fields blank
    if (variant === "audience" && hasValidationErrors()) {
      toast({
        id: `save-${variant}`,
        title: `Unable to save ${variant}`,
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    setSaveLoading(true);
    if (typeof onSave === "function") {
      await onSave();
    }
    setSaveLoading(false);
  };

  const handleRunQuery = useCallback(
    ({
      limitResults,
      recalculateSize = true,
    }: {
      limitResults: boolean;
      recalculateSize?: boolean;
    }) => {
      if (hasValidationErrors()) {
        toast({
          id: "preview-audience",
          title: "Unable to preview audience",
          message: "Check your query and try again.",
          variant: "error",
        });

        return;
      }

      setLastUsedInsightsQuery({
        filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
        subsetIds,
        isFastQuery: isFastQueryEnabled,
        destinationId,
      });

      // Run both the member preview and the size calculation in parallel
      runQuery({ limit: limitResults, disableRowCounter: true });
      if (recalculateSize) {
        calculateAudienceSize();
      }
    },
    [runQuery, visualQueryFilter],
  );

  const previewQuery = async ({
    recalculateSize,
  }: {
    recalculateSize: boolean;
  }) => {
    // If the user can't access row level data, then just return early from this call
    // They won't be able to view the member details anyway, but good to also skip fetching the data
    if (!isAccessDataPermitted) return;

    if (hasValidationErrors()) {
      toast({
        id: "preview-audience",
        title: "Unable to preview audience",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    await handleRunQuery({ limitResults: true, recalculateSize });
    analytics.track("Model Query Previewed", {
      model_type: source?.definition?.type,
      query_mode: "visual",
    });
  };

  useEffect(() => {
    if (numRowsWithoutLimit) {
      updateAudienceSize(
        {
          count: numRowsWithoutLimit,
          isEstimate: Boolean(usedFastQueryForRun),
        },
        {
          filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
          subsetIds,
          isFastQuery: isFastQueryEnabled,
          destinationId,
        },
      );
    }
  }, [numRowsWithoutLimit]);

  useEffect(() => {
    if (error) {
      analytics.track("Model Query Error", {
        model_type: source?.definition?.type,
        query_mode: "visual",
        error,
      });
    }
  }, [error]);

  useEffect(() => {
    const handler = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        handleRunQuery({ limitResults: true });
      }
    };
    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, [handleRunQuery]);

  useEffect(() => {
    return resetRunState();
  }, []);

  useEffect(() => {
    const handleKeyDown = (evt: KeyboardEvent) => {
      const metaKeyPressed = evt.metaKey || evt.ctrlKey;
      const shiftKeyPressed = evt.shiftKey;

      if (
        modelState.canRedo &&
        evt.key === "z" &&
        metaKeyPressed &&
        shiftKeyPressed
      ) {
        modelState.redo();
      } else if (
        modelState.canUndo &&
        evt.key === "z" &&
        metaKeyPressed &&
        !shiftKeyPressed
      ) {
        modelState.undo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [modelState]);

  // Set body height to stick action bar to top
  useEffect(() => {
    document.body.style.overflowY = bodyOverflow;

    return () => {
      // defaults are empty strings
      document.body.style.overflowY = "";
    };
  }, [bodyOverflow]);

  const setConditions = (
    conditions: AndCondition | OrCondition | null,
    audienceTemplateId?: string,
  ): void => {
    modelState.onChange({
      visual_query_filter: {
        ...modelState.state.visual_query_filter,
        conditions: conditions ? ([conditions] as RootCondition[]) : [],
      },
      // Replace the existing audience template if given
      ...(audienceTemplateId ? [{ id: audienceTemplateId }] : {}),
    });
  };

  // This is used for Copilot ONLY, not well tested for other use cases
  const addConditions = (
    conditions: AndCondition | OrCondition | null,
    audienceTemplateId?: string,
  ): void => {
    const existingConditions = rootCondition ?? {
      type: "and",
      conditions: [] as const,
    };

    let addedCondition = conditions ? conditions?.conditions : [];

    if (audienceTemplateId && conditions) {
      // If there are existing conditions and the top-level boolean condition doesn't match the
      // audience template, then we need to put the template in its own group.
      // Otherwise the existing top-level boolean condition will overwrite the template's boolean condition.
      if (
        existingConditions.conditions.length &&
        existingConditions.type !== conditions.type
      ) {
        // Create a new condition group
        addedCondition = [
          { type: conditions.type, conditions: addedCondition },
        ];
      }
    }

    const newConditions = [
      {
        type: existingConditions.type,
        conditions: [...existingConditions.conditions, ...addedCondition],
      },
    ];

    modelState.onChange({
      visual_query_filter: {
        ...modelState.state.visual_query_filter,
        conditions: newConditions as RootCondition[],
      },
      // Add an additional audience template if given
      ...(audienceTemplateId
        ? [
            ...(modelState.state.audience_templates ?? []),
            { id: audienceTemplateId },
          ]
        : {}),
    });
  };

  const applyAudienceTemplate = ({
    templateId,
    templateConditions,
    replaceConditions,
  }: {
    templateId: string;
    templateConditions: AudienceTemplateDefinition["conditions"];
    replaceConditions: boolean;
  }) => {
    const rootTemplateCondition = templateConditions?.[0];
    if (!rootTemplateCondition) {
      return;
    }

    // We can replace conditions even (if it's not explicitly requested) in case
    // there are no existing conditions.
    if (replaceConditions || emptyConditions) {
      setConditions(rootTemplateCondition, templateId);
    } else {
      addConditions(rootTemplateCondition, templateId);
    }
  };

  const parentModelHasTemplates = Boolean(
    parentModelDependencies?.audience_templates_aggregate?.aggregate?.count,
  );

  if (!parentModelDependencies && !audienceExploreDependencies.isLoading) {
    return (
      <Warning
        title="Unable to load dependencies"
        subtitle="Please try again later or refresh the page."
      />
    );
  }

  return (
    <>
      {/* Detail page scroll container is fixed in the viewport. Add an invisible element to track the scrolling position */}
      <Box ref={sentinelRef} style={{ height: "1px" }} />
      <Row
        ref={actionBarRef}
        height={`${actionBarDefaultHeight}px`}
        position="sticky"
        sx={{ top: `var(${cssVariableTopOffset})` }}
        boxShadow={isActionBarStuckToTop ? "sm" : undefined}
        borderBottom="1px solid"
        borderColor="base.border"
        bg="white"
        transition="boxShadow 0.3s ease-in-out"
        width="100%"
        zIndex={1}
      >
        <Row flex={1} minWidth={0} bg="base.lightBackground">
          <Row
            alignItems="center"
            color="text.secondary"
            flex={1}
            height="48px"
            justifyContent="space-between"
            maxWidth={SIZES.page}
            minWidth={0}
            mx="auto"
            position="relative"
            px={DefaultPageContainerPadding.X}
          >
            <Row gap={2}>
              <Tooltip message="Undo" keyboardShortcut="mod+z" placement="top">
                <IconButton
                  aria-label="Undo audience change."
                  icon={UndoIcon}
                  isDisabled={!modelState.canUndo}
                  onClick={modelState.undo}
                />
              </Tooltip>
              <Tooltip
                message="Redo"
                keyboardShortcut="mod+shift+z"
                placement="top"
              >
                <IconButton
                  aria-label="Redo audience change."
                  icon={RedoIcon}
                  isDisabled={!modelState.canRedo}
                  onClick={modelState.redo}
                />
              </Tooltip>
              {!audienceTemplatesEnabled && (
                <Tooltip message="View SQL" placement="top">
                  <IconButton
                    aria-label="Open SQL preview."
                    icon={CodeIcon}
                    onClick={showSqlPreview}
                  />
                </Tooltip>
              )}
              {hasSampledSegments && (
                <Row alignItems="center" gap={2} px={2}>
                  <Row gap={1}>
                    <Text fontWeight="medium">Fast queries</Text>
                    <Tooltip message="Fast queries generate an estimated size which is a close approximate to the exact size">
                      <InformationIcon />
                    </Tooltip>
                  </Row>
                  <Switch
                    size="sm"
                    aria-label="Enable fast queries."
                    isChecked={isFastQueryEnabled}
                    onChange={setIsFastQueryEnabled}
                  />
                </Row>
              )}
            </Row>

            <Row alignItems="center" gap={2}>
              {appDestinationRulesEnabled &&
                Boolean(destinationOptions.length) &&
                (isCalculateSizePermitted || isAccessDataPermitted) && (
                  <Tooltip
                    message="Preview the calculated size and membership list based on a destination rule"
                    placement="top"
                  >
                    <Column>
                      <Combobox
                        isClearable
                        optionAccessory={(option) => ({
                          type: "image",
                          url: option.logo,
                        })}
                        options={destinationOptions}
                        placeholder="Preview a destination..."
                        value={destinationId}
                        width="4xs"
                        onChange={setDestinationId}
                      />
                    </Column>
                  </Tooltip>
                )}

              <AudienceSizeCap
                properties={parentModel?.filterable_audience_columns ?? []}
                traits={parentModel?.traits ?? []}
                data={sizeCap}
                permission={
                  modelState.state.id
                    ? {
                        v1: {
                          resource: "audience",
                          grant: "update",
                          id: modelState.state.id,
                        },
                        v2: {
                          resource: "model",
                          grant: "can_update",
                          id: modelState.state.id,
                        },
                      }
                    : {
                        v1: {
                          resource: "audience",
                          grant: "create",
                        },
                        v2: {
                          resource: "model",
                          grant: "can_create",
                          creationOptions: {
                            type: "audience",
                            parentModelId: parentModel?.id?.toString(),
                          },
                        },
                      }
                }
                onSave={saveSizeCap}
              />

              {!calculatingAudienceSize &&
                audienceSize === null &&
                !sizeLoading && (
                  <PermissionedButton
                    tooltip="Calculate the size of this audience"
                    placement="top"
                    icon={AudienceIcon}
                    onClick={calculateAudienceSize}
                    unauthorizedTooltip="You do not have permission to calculate the size of this audience"
                    permission={calculateSizePermission}
                  >
                    Calculate size
                  </PermissionedButton>
                )}

              {(audienceSize !== null || sizeLoading) && (
                <Row align="center" gap={2} fontSize="20px">
                  {!sizeLoading && isDataStale && (
                    <Box
                      sx={{
                        button: {
                          color: "text.secondary",
                          _hover: { color: "text.secondary" },
                        },
                      }}
                    >
                      <Tooltip message="Refresh results" placement="top">
                        <IconButton
                          aria-label="Refresh results."
                          icon={RefreshIcon}
                          variant="secondary"
                          onClick={calculateAudienceSize}
                        />
                      </Tooltip>
                    </Box>
                  )}

                  {!enableMemberDetails &&
                    !calculatingAudienceSize &&
                    audienceSize === null &&
                    !sizeLoading && (
                      <Tooltip
                        message="Calculate the size of this audience"
                        placement="top"
                        openSpeed="slow"
                      >
                        <Button
                          icon={AudienceIcon}
                          onClick={calculateAudienceSize}
                        >
                          Calculate size
                        </Button>
                      </Tooltip>
                    )}

                  {(audienceSize !== null || sizeLoading) &&
                    !enableMemberDetails && (
                      <Box fontSize="20px">
                        <Row alignItems="center" gap={2} px={2}>
                          {sizeLoading ? (
                            <Row align="center">
                              <Row
                                justifyContent="center"
                                width="32px"
                                onMouseEnter={() => setShowCancelButton(true)}
                                onMouseLeave={() => setShowCancelButton(false)}
                              >
                                {showCancelButton ? (
                                  <Row justifyContent="center">
                                    <Box
                                      sx={{
                                        button: {
                                          color: "danger.base",
                                          _hover: { color: "danger.base" },
                                        },
                                      }}
                                    >
                                      <Tooltip
                                        message="Cancel query"
                                        placement="top"
                                        openSpeed="slow"
                                      >
                                        <IconButton
                                          aria-label="Cancel query."
                                          icon={CloseIcon}
                                          mr={2}
                                          onClick={
                                            cancelQueryAndSizeCalculation
                                          }
                                        />
                                      </Tooltip>
                                    </Box>
                                  </Row>
                                ) : (
                                  <Box as={Spinner} size="sm" />
                                )}
                              </Row>
                              <Text
                                color="text.secondary"
                                fontWeight="semibold"
                              >
                                Calculating...
                              </Text>
                            </Row>
                          ) : (
                            <AudienceIcon />
                          )}

                          {!sizeLoading && (
                            <Box
                              as={Text}
                              fontWeight="semibold"
                              color={
                                sizeLoading ? "text.tertiary" : "text.primary"
                              }
                              size="lg"
                              sx={NumericFontStyles}
                            >
                              {audienceSize !== null
                                ? `${
                                    audienceSize.isEstimate ? "~" : ""
                                  }${commaNumber(audienceSize.count)}`
                                : "--"}
                            </Box>
                          )}
                        </Row>
                      </Box>
                    )}
                  {enableMemberDetails && (
                    <ShowMembersButton
                      sizePermission={calculateSizePermission}
                      previewPermission={accessDataPermission}
                      isLoading={sizeLoading}
                      audienceSize={audienceSize}
                      onCancel={cancelQueryAndSizeCalculation}
                      onClick={() => {
                        if (audienceSize === null) {
                          previewQuery({ recalculateSize: true });
                        } else {
                          const isPreviewDataStale = hasQueryChanged(
                            currentQuery,
                            lastUsedInsightsQuery,
                          );

                          if ((!rows && !error) || isPreviewDataStale) {
                            resetRunState();
                            // Only recalculate size if the query has changed since last run
                            previewQuery({
                              recalculateSize: isDataStale,
                            });
                          }

                          setIsMembersDrawerOpen((prev) => !prev);
                        }
                      }}
                    />
                  )}
                </Row>
              )}

              <ShowInsightsButton
                isInsightsOpen={isInsightsOpen}
                onClick={() =>
                  setIsInsightsOpen((currentValue) => !currentValue)
                }
                permission={viewInsightsPermissions}
              />

              {audienceTemplatesEnabled && (
                <Menu>
                  <MenuActionsButton />
                  <MenuList>
                    <MenuItem icon={SqlIcon} onClick={showSqlPreview}>
                      View SQL
                    </MenuItem>
                    {variant === "audience" && (
                      <Tooltip
                        isDisabled={
                          dependenciesLoading || parentModelHasTemplates
                        }
                        message="There are no templates available for this parent model. Ask your admin to create templates to start using them."
                      >
                        <MenuItem
                          icon={AudienceTemplateIcon}
                          isDisabled={!parentModelHasTemplates}
                          onClick={() =>
                            setAudienceTemplateModal({ isOpen: true })
                          }
                        >
                          Add a template
                        </MenuItem>
                      </Tooltip>
                    )}
                  </MenuList>
                </Menu>
              )}

              <SlideInOutAndHide
                animate={isInsightsOpen ? "open" : "closed"}
                position="absolute"
                right={0}
                top={`${actionBarDefaultHeight}px`}
                height={`calc(
                  100vh
                  - var(${cssVariableVisibleTopSectionHeight})
                  - ${ActionBarHeight * 2 - insightsPadding}px)
                `}
                width={`${insightsWidth}px`}
              >
                <AudienceInsights
                  isOpen={isInsightsOpen}
                  isLoading={isRelatedAudiencesLoading}
                  sourceId={
                    modelState.state.connection?.id?.toString() ??
                    source?.id?.toString() ??
                    ""
                  }
                  parentModelId={
                    modelState.state.visual_query_parent_id?.toString() ??
                    parentModel?.id?.toString() ??
                    ""
                  }
                  audienceId={modelState.state.id?.toString()}
                  filter={visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION}
                  subsetIds={subsetIds}
                  destinationId={destinationId}
                  isFastQuery={isFastQueryEnabled}
                  audienceOptions={relatedAudiences}
                  breakdownColumnOptions={breakdownColumnOptions}
                />
              </SlideInOutAndHide>
            </Row>
          </Row>
        </Row>
      </Row>
      <Row
        justify="space-between"
        maxWidth={SIZES.page}
        pb={footerBarHeight ? `${footerBarHeight}px` : undefined}
        mx="auto"
        px={DefaultPageContainerPadding.X}
        height="100%"
        width="100%"
        minHeight={`calc(
          100vh
          - var(${cssVariableTopSectionHeight})
          - 48px
        )`}
      >
        <Column
          justifyContent="space-between"
          overflowX="auto"
          gap={4}
          py={4}
          width="100%"
          pr={isInsightsOpen ? 6 : 0}
        >
          <Column gap={4}>
            <Text size="lg" fontWeight="medium">
              Include in {variant} if...
            </Text>
            {variant === "audience" && (
              <SubsetSelector
                parentModelId={parentModel?.id}
                value={subsetIds}
                onChange={(subsets) => {
                  modelState.onChange({
                    subsets: subsets.map((id) => ({ subset_value: { id } })),
                  });
                }}
              />
            )}
            {variant === "audience" && enableRealtimeAudiences && (
              <Checkbox
                label="Realtime"
                isChecked={Boolean(
                  modelState.state.visual_query_filter?.isRealtime,
                )}
                onChange={(value) => {
                  modelState.onChange({
                    visual_query_filter: {
                      // TODO: is there a shared default filter somewhere?
                      ...(modelState.state.visual_query_filter ?? {
                        conditions: [],
                      }),
                      isRealtime: value.target.checked,
                    },
                  });
                }}
              />
            )}
            <Column id="query-builder" gap={4} width="100%">
              <QueryBuilder
                isLoading={dependenciesLoading}
                audience={audience}
                filter={rootCondition}
                parentModel={fullParentModel}
                setConditions={setConditions}
              />
              {variant === "template" && emptyConditions && (
                <Box
                  as={EmptyState}
                  bg="white"
                  imageUrl={audiencePlaceholder}
                  title="No filters selected"
                  message="Templates are reusable audience definitions that users in your workspace will be able to apply when they build audiences."
                />
              )}
            </Column>
          </Column>

          {(showAudienceTemplateSelector || aiAudienceGeneration) && (
            <Column gap={4}>
              {showAudienceTemplateSelector && (
                <AudienceTemplateSelector
                  parentModelId={String(parentModel?.id)}
                  onClick={(templateId?: string) => {
                    setAudienceTemplateModal({
                      isOpen: true,
                      highlightTemplateId: templateId,
                    });
                  }}
                />
              )}

              {aiAudienceGeneration && (
                <Row align="end" flex={1} minHeight={0}>
                  <Magic
                    height="min-content"
                    updateFilter={addConditions}
                    parentModelId={String(parentModel?.id)}
                  />
                </Row>
              )}
            </Column>
          )}
        </Column>

        {/* Pushes the query builder to the left when the insights are open. Uses the same animation timings */}
        <motion.div
          animate={isInsightsOpen ? "open" : "closed"}
          variants={{
            open: {
              width: `${insightsWidth - insightsPadding}px`,
              boxShadow: "var(--chakra-shadows-md)",
              transition: {
                width: { duration: SlideInAnimationTime, ease: "easeOut" },
              },
            },
            closed: {
              width: 0,
              boxShadow: "var(--chakra-shadows-none)",
              transition: {
                width: { duration: SlideOutAnimationTime, ease: "easeIn" },
              },
            },
          }}
        >
          <Row
            bg="transparent"
            height="100%"
            width={isInsightsOpen ? `${insightsWidth}px` : 0}
          />
        </motion.div>
      </Row>

      {onSave && (
        <ActionBar ref={footerRef}>
          <ButtonGroup>
            <PermissionedButton
              permission={
                variant === "audience"
                  ? {
                      v1: {
                        resource: "audience",
                        grant: "update",
                        id: modelState.state.id,
                      },
                      v2: {
                        resource: "model",
                        grant: "can_update",
                        id: modelState.state.id ?? "",
                      },
                    }
                  : {
                      v1: {
                        resource: "audience_schema",
                        grant: "update",
                      },
                      v2: {
                        resource: "model",
                        grant: "can_update",
                        id: modelState.state.parent?.id ?? "",
                      },
                    }
              }
              isLoading={saveLoading}
              // TODO(nishad): we probably want to track updates to subset changes so we can undo them.
              // We may need to merge the visual query and subset states if possible.
              // Otherwise only undo visual query changes.
              //isDisabled={!canUndo}
              size="lg"
              variant="primary"
              onClick={handleSave}
              isDisabled={!modelState.isDirty}
            >
              Save {variant}
            </PermissionedButton>
            <Button
              isDisabled={!modelState.canUndo || !modelState.isDirty}
              size="lg"
              onClick={() => {
                modelState.reset();
              }}
            >
              Discard changes
            </Button>
          </ButtonGroup>
        </ActionBar>
      )}

      <Dialog
        isOpen={transformedSql.visible}
        variant="info"
        width="xl"
        title="Transformed SQL"
        actions={
          <ButtonGroup>
            <Button onClick={copySQLToClipboard}>Copy SQL</Button>
            <Button
              variant="primary"
              onClick={() =>
                setTransformedSql((state) => ({ ...state, visible: false }))
              }
            >
              OK
            </Button>
          </ButtonGroup>
        }
        onClose={() =>
          setTransformedSql((state) => ({ ...state, visible: false }))
        }
      >
        {loading || transformedSql.loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Spinner size="lg" />
            <Text mt={4}>Fetching transformed SQL...</Text>
          </Box>
        ) : (
          <Editor readOnly language="sql" value={transformedSql.sql ?? ""} />
        )}
      </Dialog>
      <MembersDrawer
        audienceSize={audienceSize}
        audienceRows={rows ?? []}
        includeMergedColumns={enableMemberDetails && showMergedColumnsInPreview}
        isOpen={isMembersDrawerOpen}
        error={error}
        parentModel={parentModel}
        isLoading={loading}
        onToggleMergedColumns={() => {
          setShowMergedColumnsInPreview((value) => !value);
        }}
        onClose={() => setIsMembersDrawerOpen(false)}
      />

      {parentModel && (
        <AudienceTemplateSelectorModal
          highlightTemplateId={audienceTemplateModal.highlightTemplateId}
          isOpen={audienceTemplateModal.isOpen}
          parentModelId={parentModel.id}
          onAddTemplate={(templateId, templateConditions) => {
            applyAudienceTemplate({
              templateId,
              templateConditions,
              replaceConditions: false,
            });
          }}
          onReplaceAudience={
            // It only makes sense to _replace_ the audience conditions
            // if they already exist
            emptyConditions
              ? undefined
              : (templateId, templateConditions) => {
                  applyAudienceTemplate({
                    templateId,
                    templateConditions,
                    replaceConditions: true,
                  });
                }
          }
          onClose={() => setAudienceTemplateModal({ isOpen: false })}
        />
      )}
    </>
  );
};

const ShowMembersButton: FC<{
  audienceSize: { count: number; isEstimate: boolean } | null;
  isLoading?: boolean;
  onClick: () => void;
  onCancel: () => void;
  sizePermission: ResourcePermissionInput<"model", "audience">;
  previewPermission: ResourcePermissionInput<"model", "audience">;
}> = ({
  audienceSize,
  isLoading = false,
  onClick,
  onCancel,
  sizePermission,
  previewPermission,
}) => {
  const { isPermitted: isPreviewPermitted } =
    useResourcePermission(previewPermission);
  if (isLoading) {
    return (
      <Row align="center" pr={2}>
        <Row
          justifyContent="center"
          width="32px"
          _hover={{
            ".hovered": { display: "block" },
            ".unhovered": { display: "none" },
          }}
        >
          <Box className="unhovered" display="block" as={Spinner} size="sm" />
          <Row
            className="hovered"
            display="none"
            justifyContent="center"
            sx={{
              button: {
                color: "danger.base",
                _hover: { color: "danger.base" },
              },
            }}
          >
            <Tooltip message="Cancel query" placement="top" openSpeed="slow">
              <IconButton
                aria-label="Cancel query."
                icon={CloseIcon}
                mr={2}
                onClick={onCancel}
              />
            </Tooltip>
          </Row>
        </Row>
        <Text color="text.secondary" fontWeight="semibold">
          Calculating...
        </Text>
      </Row>
    );
  }

  const sizeText =
    audienceSize === null
      ? "Calculate size"
      : `${audienceSize.isEstimate ? "~" : ""}${commaNumber(
          audienceSize.count,
        )} members`;

  if (!isPreviewPermitted && audienceSize !== null) {
    return (
      <Row gap={1} px={1}>
        <AudienceIcon />
        <Text fontWeight="semibold">{sizeText}</Text>
      </Row>
    );
  }

  return (
    <PermissionedButton
      tooltip="Preview members"
      placement="top"
      isLoading={isLoading}
      onClick={onClick}
      icon={AudienceIcon}
      permission={audienceSize === null ? sizePermission : previewPermission}
    >
      {sizeText}
    </PermissionedButton>
  );
};

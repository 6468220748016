import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";

import {
  autocompletion,
  closeBrackets,
  closeBracketsKeymap,
  completionKeymap,
} from "@codemirror/autocomplete";
import {
  history,
  historyKeymap,
  indentWithTab,
  defaultKeymap as originalDefaultKeymap,
} from "@codemirror/commands";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { liquid } from "@codemirror/lang-liquid";
import { sql } from "@codemirror/lang-sql";
import { xml } from "@codemirror/lang-xml";
import {
  bracketMatching,
  defaultHighlightStyle,
  indentOnInput,
  LanguageSupport,
  syntaxHighlighting,
} from "@codemirror/language";
import { highlightSelectionMatches, searchKeymap } from "@codemirror/search";
import { Compartment, EditorState, Extension } from "@codemirror/state";
import {
  EditorView,
  keymap,
  lineNumbers,
  placeholder,
  ViewUpdate,
} from "@codemirror/view";
import { Box, BoxProps, Portal } from "@hightouchio/ui";

import css from "./editor.module.css";
import { highlightErroredLine } from "./highlight-errored-line";

const erroredLineCompartment = new Compartment();

// Disable ⌘ + Enter shortcut to insert a blank line, because it
// also triggers a preview in the SQL editor
const defaultKeymap = originalDefaultKeymap.filter((keyBinding) => {
  return keyBinding.key !== "Mod-Enter";
});

type Language =
  | "sql"
  | "json"
  | "xml"
  | "liquid"
  | "javascript"
  | LanguageSupport;

const initializeLanguage = (language: Language): LanguageSupport => {
  switch (language) {
    case "sql":
      return sql();
    case "json":
      return json();
    case "xml":
      return xml();
    case "liquid":
      return liquid();
    case "javascript":
      return javascript();
    default:
      return language;
  }
};

// Compartments allow replacing specific extensions without affecting all the other ones
// See https://codemirror.net/6/docs/ref/#state.Compartment
const languageCompartment = new Compartment();
const placeholderCompartment = new Compartment();
const readOnlyCompartment = new Compartment();

export interface Props {
  /**
   * Line number to highlight with a red background, in case there's an error
   */
  highlightErroredLine?: number;

  /**
   * Determines if user can edit the code
   */
  readOnly?: boolean;

  /**
   * Code to show in the editor
   */
  value: string;

  /**
   * Language of the code
   */
  language?: Language;

  /**
   * Text to show when there's no code
   */
  placeholder?: string;

  /**
   * CodeMirror extensions
   */
  extensions?: Extension[];

  /**
   * Callback for when editor is initialized
   */
  onInit?: (options: { view: EditorView }) => void;

  /**
   * Callback for when the code is changed
   */
  onChange?: ChangeCallback;

  /**
   * Minimum height of the editor
   * @default 200px
   */
  minHeight?: string;

  /**
   * Override the background color of the editor
   */
  bg?: BoxProps["bg"];

  /**
   * Show/hide line numbers
   */
  showLineNumbers?: boolean;
}

type ChangeCallback = (value: string) => void;

export const Editor: FC<Props> = ({
  highlightErroredLine: highlightErroredLineNumber,
  readOnly = false,
  value,
  language,
  placeholder: placeholderText,
  extensions,
  onInit,
  onChange,
  minHeight = "200px",
  bg,
  showLineNumbers = true,
}) => {
  const container = useRef<HTMLDivElement>(null);
  const [view, setView] = useState<EditorView | undefined>();

  const autocompleteContainer = useRef<HTMLDivElement>(null);

  // Store the reference to the latest `onChange` function, so that `updateListener`
  // always calls the latest `onChange` value without triggering `useEffect` update
  // and forcing CodeMirror to re-bind itself
  const lazyOnChange = useRef<ChangeCallback | undefined>(onChange);

  useLayoutEffect(() => {
    lazyOnChange.current = onChange;
  }, [onChange]);

  useEffect(() => {
    if (!container.current) {
      return undefined;
    }

    const updateListener = EditorView.updateListener.of(
      (viewUpdate: ViewUpdate) => {
        if (viewUpdate.docChanged) {
          const value = viewUpdate.state.doc.toString();

          if (typeof lazyOnChange.current === "function") {
            lazyOnChange.current(value);
          }
        }
      },
    );

    const state = EditorState.create({
      doc: value,
      extensions: [
        ...(showLineNumbers ? [lineNumbers()] : []),
        highlightSelectionMatches(),
        indentOnInput(),
        syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
        bracketMatching(),
        closeBrackets(),
        autocompletion(),
        history(),
        updateListener,
        keymap.of([
          ...closeBracketsKeymap,
          ...defaultKeymap,
          ...historyKeymap,
          ...searchKeymap,
          ...completionKeymap,
          indentWithTab,
        ]),
        ...(language
          ? [languageCompartment.of(initializeLanguage(language))]
          : []),
        placeholderCompartment.of(placeholder("")),
        readOnlyCompartment.of(EditorState.readOnly.of(readOnly)),
        erroredLineCompartment.of(
          highlightErroredLine(highlightErroredLineNumber),
        ),
        ...(extensions ?? []),
      ],
    });

    const view = new EditorView({
      state,
      parent: container.current,
    });

    setView(view);

    // Move the autocomplete element to the portal
    const moveAutocompleteToPortal = () => {
      const autocompleteElement = document.querySelector(
        ".cm-tooltip-autocomplete",
      );

      if (autocompleteElement && autocompleteContainer.current) {
        const cursorPos = view.state.selection.main.head;
        const coords = view.coordsAtPos(cursorPos);

        if (coords) {
          autocompleteContainer.current.style.position = "absolute";
          autocompleteContainer.current.style.left = "0";
          autocompleteContainer.current.style.top = `${coords.top}px`;
          autocompleteContainer.current.style.zIndex = "1";
          // TODO: fix height calculation of autocompleteElement
        }

        autocompleteContainer.current.appendChild(autocompleteElement);
      }
    };

    // Observe the changes in the editor view to relocate autocomplete element
    const autocompleteObserver = new MutationObserver(moveAutocompleteToPortal);
    autocompleteObserver.observe(view.dom, { childList: true, subtree: true });

    return () => {
      view.destroy();
      setView(undefined);
      autocompleteObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!view) {
      return;
    }

    view.dispatch({
      effects: erroredLineCompartment.reconfigure(
        highlightErroredLine(highlightErroredLineNumber),
      ),
    });
  }, [view, highlightErroredLineNumber]);

  useEffect(() => {
    if (view && typeof onInit === "function") {
      onInit({ view });
    }
  }, [view, onInit]);

  useEffect(() => {
    if (!view) {
      return;
    }

    if (language) {
      view.dispatch({
        effects: languageCompartment.reconfigure(initializeLanguage(language)),
      });
    }
  }, [view, language]);

  useEffect(() => {
    if (!view || !placeholderText) {
      return;
    }

    view.dispatch({
      effects: placeholderCompartment.reconfigure(placeholder(placeholderText)),
    });
  }, [view, placeholderText]);

  useEffect(() => {
    if (!view) {
      return;
    }

    view.dispatch({
      effects: readOnlyCompartment.reconfigure(
        EditorState.readOnly.of(readOnly),
      ),
    });
  }, [view, readOnly]);

  useEffect(() => {
    if (!view) {
      return;
    }

    const currentValue = view.state.doc.toString();

    // Update editor content only when `value` is updated, except when it's
    // updated as a result of this component calling `onChange` as user is typing
    if (value !== currentValue) {
      view.dispatch({
        changes: {
          from: 0,
          to: currentValue.length,
          insert: value ?? "",
        },
      });
    }
  }, [view, value]);

  return (
    <Box
      display="flex"
      flexDir="column"
      ref={container}
      className={css.editor}
      height="100%"
      width="100%"
      flex={1}
      overflow="hidden"
      minHeight={minHeight}
      bg={bg || (readOnly ? "gray.50" : "white")}
      sx={{ "& .cm-gutters": { bg: bg || (readOnly ? "gray.50" : "white") } }}
      pl={showLineNumbers ? undefined : 2}
    >
      <Portal>
        <Box ref={autocompleteContainer} />
      </Portal>
    </Box>
  );
};

import { useMemo, useState } from "react";
import { isEmpty, sortBy } from "lodash";
import { Box, Column, Row, Switch, Text } from "@hightouchio/ui";

import {
  NormalizationType,
  SplitGroupId,
} from "@hightouch/lib/query/visual/types/analytics";
import { ENGAGEMENT_OUTCOME_EXPERIMENT_FILTER } from "@hightouch/lib/query/visual/analytics/decision-engine-utils";

import {
  TimeOptions,
  MeasurementScope,
  MeasuringMode,
  ParentModel,
  GraphType,
} from "src/pages/analytics/types";
import { AnalyticsFrequency, SyntheticMetricType } from "src/types/visual";
import { useMetricSeries } from "src/pages/analytics/hooks/use-metric-series";
import { AggregationOption } from "src/pages/metrics/constants";

import { Flow } from "..";
import { getDateRangeForMetricSeries } from "src/pages/decision-engines/flows/utils";
import { Outcome } from "src/pages/decision-engines/flows/flow/outcomes";
import { GraphScale } from "src/components/analytics/cross-audience-graph/constants";
import { transformMetricDataForGraph } from "src/components/analytics/cross-audience-graph/utils";
import { Chart } from "src/components/charts/chart";
import {
  SPLIT_GROUP_LABEL,
  SPLIT_GROUP_SORT_ORDER,
} from "src/pages/decision-engines/flows/flow/utils";

type OutcomeConversionChartProps = {
  flow: Flow;
  outcome: Outcome;
  relationshipId: string;
  lastFlowRunStartedAt: string;
  allTimeStartDate: string;
};

export const OutcomeConversionChart = ({
  flow,
  outcome,
  relationshipId,
  lastFlowRunStartedAt,
  allTimeStartDate,
}: OutcomeConversionChartProps) => {
  const { messages: flowMessages, audience } = flow;
  const parentModelId = audience?.parent?.id;

  const [showConfidenceBounds, setShowConfidenceBounds] = useState(false);

  const { data, isPolling, pollingError, errors } = useMetricSeries({
    enabled: true,
    measuringSelection: {
      scope: MeasurementScope.DecisionEngineFlow,
      id: flow.id,
    },
    parentModelId,
    measuringMode: MeasuringMode.Incrementality,
    frequency: AnalyticsFrequency.Weekly,
    cumulative: true,
    metricSelection: [
      {
        id: outcome.id,
        resourceId: outcome.id,
        eventModelId: outcome.segment.id,
        relationshipId,
        name: "AI Decisioning Interactions",
        source: SyntheticMetricType.DecisionEngineInteractions,
        aggregationMethod: AggregationOption.UniqueUsers,
        normalization: NormalizationType.RatePerUser,
        conditions: outcome.attribution?.campaign_id_column
          ? ENGAGEMENT_OUTCOME_EXPERIMENT_FILTER
          : [],
        description: null,
        attributionWindow: undefined,
      },
    ],
    timeValue: TimeOptions.Custom,
    customDateRange: getDateRangeForMetricSeries(lastFlowRunStartedAt, {
      type: "custom",
      startDate: allTimeStartDate,
    }),
    measuringMetricResources: { outcomes: [outcome], flowMessages },
    audiences: [],
    groupByColumns: [],
    metrics: [],
    useSampledModels: false,
    errorOnNoData: false,
  });

  const graphSeries = useMemo(() => {
    const sortedMetricResults = data.map((res) => {
      if ("data" in res.result) {
        // Keep a constant sort order so split groups don't change position across different charts.
        const sortedData = sortBy(
          res.result.data,
          (d) => SPLIT_GROUP_SORT_ORDER.indexOf(d.splitId as SplitGroupId) * -1,
        );

        return {
          ...res,
          result: {
            ...res.result,
            data: sortedData,
          },
        };
      }

      return res;
    });

    const graph = transformMetricDataForGraph({
      audiences: [],
      events: [],
      groupByColumns: [],
      metricResults: sortedMetricResults,
      metrics: [],
      parent: { id: parentModelId } as unknown as ParentModel,
    });

    // We know that we are going to have a series per split group in the experiment.
    // So we can give the series more descriptive names given the context.
    return graph.series.map((series) => {
      const name = series.splitName
        ? (SPLIT_GROUP_LABEL[series.splitName] ?? series.splitName)
        : series.metricName;

      return {
        ...series,
        legendName: name,
        metricName: name,
      };
    });
  }, [data, parentModelId]);

  const isError = Boolean(pollingError) || !isEmpty(errors);

  return (
    <Column height="350px" gap={2} mb={-4}>
      <Row alignItems="center" justify="space-between">
        <Text fontWeight="medium">Cumulative conversion rate</Text>
        <Box alignItems="center" display="flex" gap={2}>
          <Switch
            isChecked={showConfidenceBounds}
            aria-label="Toggle confidence bounds"
            size="sm"
            onChange={setShowConfidenceBounds}
            isDisabled={isPolling}
          />
          {/* Note: doesn't completely vertically align with the switch unless we add a couple pixels of margin at the top */}
          <Text size="sm" mt={0.5}>
            Show 95% confidence interval
          </Text>
        </Box>
      </Row>
      <Chart
        height="100%"
        graphSeries={graphSeries}
        type={GraphType.Line}
        scale={GraphScale.Percent}
        isLoading={isPolling}
        isError={isError}
        showConfidenceBounds={showConfidenceBounds}
      />
    </Column>
  );
};

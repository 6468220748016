import { PointerEvent, useState } from "react";

import { Box, Column, DragIcon, Row, Text } from "@hightouchio/ui";
import { motion, useDragControls } from "framer-motion";
import { useReactFlow } from "reactflow";

import { IconBox } from "src/components/icon-box";
import { usePermissionContext } from "src/components/permission";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";

import { NodeOptionProps } from "./types";

export const NodeOption = ({ color, label, icon, type }: NodeOptionProps) => {
  const result = useReactFlow();
  const { isEditMode, reactFlowInstance, sidebarRef, onAddNode } =
    useGraphContext();
  const { unauthorized } = usePermissionContext();

  const [isDragging, setIsDragging] = useState(false);
  const controls = useDragControls();

  const canDrag = isEditMode && !unauthorized;

  const startDrag = (event: PointerEvent<HTMLDivElement>) => {
    if (isEditMode) {
      setIsDragging(true);
      controls.start(event);
    }
  };

  const endDrag = (event: PointerEvent<HTMLDivElement>) => {
    setIsDragging(false);
    if (!isEditMode) {
      return;
    }
    // Don't drop if on sidebar

    const isInsideSidebar = (event: PointerEvent<HTMLElement>) => {
      const { clientX, clientY } = event;
      const sidebarBoundingBox = sidebarRef.current?.getBoundingClientRect();

      return (
        sidebarBoundingBox &&
        clientX >= sidebarBoundingBox.left &&
        clientX <= sidebarBoundingBox.right &&
        clientY >= sidebarBoundingBox.top &&
        clientY <= sidebarBoundingBox.bottom
      );
    };

    if (sidebarRef.current && isInsideSidebar(event)) {
      return;
    }

    const wrapperInstance = reactFlowInstance?.current;
    const eventPosition = {
      x: event.pageX,
      y: event.pageY,
    };

    if (wrapperInstance) {
      const boundingRect = wrapperInstance.getBoundingClientRect();

      // move to left side of node
      eventPosition.x -= boundingRect.left;
      eventPosition.y -= boundingRect.top;
    }

    const position = result.project(eventPosition);

    onAddNode({
      type,
      position: isNaN(position.x) ? eventPosition : position,
    });
  };

  return (
    <Box
      as={motion.div}
      dragSnapToOrigin
      drag={canDrag}
      dragTransition={{ restDelta: 10000 }}
      dragControls={controls}
      onPointerDown={startDrag}
      onPointerUp={endDrag}
    >
      <Row
        as="li"
        align="center"
        justify="space-between"
        py={2}
        px={4}
        background="white"
        boxShadow={canDrag && isDragging ? "md" : "none"}
        cursor={canDrag ? (isDragging ? "grabbing" : "grab") : "auto"}
        _hover={{
          bg: canDrag ? "base.lightBackground" : "white",
          "> div:last-child": {
            opacity: 1,
          },
        }}
      >
        <Row align="center" gap={2}>
          <IconBox
            bg={`${color}.400`}
            icon={icon}
            boxSize="24px"
            iconSize="14px"
          />
          <Column>
            <Text color={`${color}.600`} fontWeight="semibold">
              {label}
            </Text>
          </Column>
        </Row>
        {canDrag && (
          <Row
            align="center"
            justify="center"
            height="20px"
            width="20px"
            fontSize="20px"
            opacity={0}
          >
            <DragIcon />
          </Row>
        )}
      </Row>
    </Box>
  );
};

import { FC, useEffect, useState } from "react";

import { TextInput, TextInputProps } from "@hightouchio/ui";
import { format, isValid as isValidDate, parse } from "date-fns";

const ACCEPTED_DATE_STRING_FORMATS = [
  "M/d/yyyy",
  "MM/dd/yyyy",
  "M/dd/yyyy",
  "MM/d/yyyy",
  "M/d/yy",
  "MM/dd/yy",
  "M/dd/yy",
  "MM/d/yy",
];

const getDateString = (date: Date | null) => {
  if (!date || !isValidDate(date)) {
    return "";
  }

  return format(date, "M/d/yyyy"); // display the input string in this format
};

// This function is supposed to behave like `moment(dateString, [formatStrings], true)`.
// Parse a date string given multuple formats using strict mode.
const parseDate = (dateString: string): Date | null => {
  // Unlike `moment`, `date-fns` doesn't have a function to parse date formats
  // at once, so we have to make something custom.
  for (let i = 0; i < ACCEPTED_DATE_STRING_FORMATS.length; i++) {
    const formatString = ACCEPTED_DATE_STRING_FORMATS[i]!;

    // We wrap this with a try/catch so that attempting to parse and format
    // a bad date string doesn't throw an error (RangeError)
    try {
      // We want the date string to exactly match the specified format.
      // In `moment`, this is called "strict mode", but `date-fns` does not have that.
      // So we first parse the date, convert it back to a string and see if it matches the original input.
      const parsedDate = parse(dateString, formatString, new Date());
      const formattedDate = format(parsedDate, formatString);

      if (formattedDate === dateString) {
        return parsedDate;
      }
    } catch (_error) {
      continue;
    }
  }

  return null;
};

interface DayInputProps extends Omit<TextInputProps, "value" | "onChange"> {
  isValid: boolean;
  value: Date | null;
  onChange: (date: Date) => void;
  setIsValid: (valid: boolean) => void;
}

export const DayInput: FC<DayInputProps> = ({
  isValid,
  value,
  onChange,
  setIsValid,
  ...props
}) => {
  const [displayedValue, setDisplayedValue] = useState(getDateString(value));

  useEffect(() => {
    setDisplayedValue(getDateString(value));
  }, [value]);

  const updateValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateString = event.target.value;
    setDisplayedValue(dateString);

    const parsed = parseDate(dateString);

    const valid = isValidDate(parsed);
    setIsValid(valid);

    if (parsed && valid) {
      onChange(parsed);
    }
  };

  return (
    <TextInput
      {...props}
      isInvalid={!isValid}
      value={displayedValue}
      onChange={updateValue}
    />
  );
};

import { ReactNode, useMemo, useCallback } from "react";

import {
  Box,
  Checkbox,
  ChevronDownIcon,
  ChevronRightIcon,
  IconButton,
} from "@hightouchio/ui";

import { Cell } from "./cells";
import { RowClickHandler } from "./table";

export type RowProps<RowData extends Record<string, unknown>> = {
  children: ReactNode;
  selected?: boolean;
  disabled?: boolean;
  clickable?: boolean;
  row: RowData;
  onClick?: RowClickHandler<RowData>;
  onSelect?: ((value: any) => unknown) | undefined;
  sortable?: boolean;
  primaryKey: any;
  isExpanded?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
};

export function Row<RowData extends Record<string, unknown>>({
  selected,
  children,
  disabled,
  clickable,
  onSelect,
  row,
  onClick,
  sortable,
  primaryKey,
  isExpanded = false,
  setIsExpanded,
}: RowProps<RowData>) {
  const handleClick = useCallback(
    (event: any) => {
      if (onClick && !window?.getSelection()?.toString()) {
        onClick(row, event);
      }
    },
    [onClick, row],
  );

  const handleSelect = useCallback(() => {
    if (typeof onSelect === "function") {
      onSelect(row[primaryKey]);
    }
  }, [onSelect, row, primaryKey]);

  const style = useMemo(
    () => ({
      display: "contents",
      cursor: clickable ? "pointer" : "auto",
      td: {
        opacity: disabled ? 0.6 : 1,
        bg: selected ? "gray.50" : undefined,
      },
      ":hover td": {
        bg: selected ? "forest.50" : clickable ? "gray.100" : undefined,
      },
    }),
    [selected, disabled],
  );

  return (
    <Box as="tr" sx={style} onClick={handleClick}>
      {setIsExpanded && (
        <Cell onClick={stopPropagation}>
          <IconButton
            icon={isExpanded ? ChevronDownIcon : ChevronRightIcon}
            aria-label="Expand row"
            onClick={() => setIsExpanded(!isExpanded)}
            variant="tertiary"
            size="sm"
          />
        </Cell>
      )}
      {onSelect && (
        <Cell onClick={stopPropagation}>
          <Checkbox
            isChecked={Boolean(selected)}
            isDisabled={disabled}
            onChange={handleSelect}
          />
        </Cell>
      )}
      {children}
      {sortable && <Cell />}
    </Box>
  );
}

const stopPropagation = (event) => {
  event.stopPropagation();
};

import { FC, ReactNode } from "react";
import { RouterLink } from "src/router";

import {
  Box,
  BoxProps,
  LinkProps,
  Row,
  PaddingProps,
  SectionHeading,
} from "@hightouchio/ui";
import { Markdown } from "src/ui/markdown";

export type CardProps = Omit<BoxProps, keyof PaddingProps> & {
  children: ReactNode;
  heading?: ReactNode;
  subheading?: ReactNode;
  p?: number;
  footer?: ReactNode;
  href?: LinkProps["href"];
  rightContent?: ReactNode;
};

const defaultPadding = 6;

export const Card: FC<Readonly<CardProps>> = ({
  children,
  footer,
  onClick,
  href,
  p = defaultPadding,
  heading,
  subheading,
  rightContent,
  ...props
}) => {
  const isClickable = Boolean(onClick || href);
  return (
    <Box
      as={href ? RouterLink : "div"}
      to={href}
      bg="white"
      display="flex"
      flexDirection="column"
      p={p}
      pb={footer ? 4 : undefined}
      onClick={onClick}
      border="1px"
      borderColor="base.border"
      borderRadius="md"
      boxShadow="sm"
      cursor={isClickable ? "pointer" : "auto"}
      transition="border-color 100ms, background-color 100ms, box-shadow 250ms"
      _hover={{
        borderColor: isClickable ? "gray.400" : undefined,
      }}
      {...props}
    >
      {(heading || rightContent) && (
        <Row justify="space-between" gap={4}>
          <Box>
            {heading && (
              <Box as={SectionHeading}>
                {typeof heading === "string" ? (
                  <Markdown disableParagraphs>{heading}</Markdown>
                ) : (
                  heading
                )}
              </Box>
            )}
            {subheading && (
              <Box mt={1}>
                {typeof subheading === "string" ? (
                  <Markdown>{subheading}</Markdown>
                ) : (
                  subheading
                )}
              </Box>
            )}
          </Box>
          {rightContent}
        </Row>
      )}
      {children}
      {footer && (
        <>
          <Box mt="auto" />
          <Row
            pt={4}
            mt={p}
            borderTop="1px"
            borderColor="base.border"
            px={p}
            mx={-p}
          >
            {footer}
          </Row>
        </>
      )}
    </Box>
  );
};

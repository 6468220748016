import {
  Button,
  Checkbox,
  EditableDescription,
  EditableHeading,
  EditableText,
  IconButton,
  MenuButton,
  MenuItem,
  Select,
  Switch,
} from "@hightouchio/ui";

import { DeleteButton } from "src/components/delete-button";
import { LinkButton } from "src/router";

import { withPermission } from "./with-permission";

export const PermissionedButton = withPermission(Button);
export const PermissionedLinkButton = withPermission(LinkButton);
export const PermissionedMenuItem = withPermission(MenuItem);
export const PermissionedMenuButton = withPermission(MenuButton);
export const PermissionedIconButton = withPermission(IconButton);
export const PermissionedSwitch = withPermission(Switch);
export const PermissionedEditableHeading = withPermission(
  EditableHeading,
  // The edit icon is invisible when unauthorized, so the tooltip isn't necessary
  { disableUnauthorizedTooltip: true },
);
export const PermissionedEditableDescription = withPermission(
  EditableDescription,
  // The edit icon is invisible when unauthorized, so the tooltip isn't necessary
  { disableUnauthorizedTooltip: true },
);
export const PermissionedEditableText = withPermission(
  EditableText,
  // The edit icon is invisible when unauthorized, so the tooltip isn't necessary
  { disableUnauthorizedTooltip: true },
);
export const PermissionedCheckbox = withPermission(Checkbox);
export const PermissionedSelect = withPermission(Select);
export const PermissionedDeleteButton = withPermission(DeleteButton);

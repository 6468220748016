import { useState } from "react";

import { Outlet, useOutletContext } from "src/router";

import {
  SyncOp,
  useAttemptedRowsByPrimaryKeyQuery,
  useAttemptedRowsQuery,
} from "src/graphql";
import { getSyncRunOperations } from "src/utils/syncs";
import { RowsTable } from "./table";
import { Context } from "..";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Column } from "@hightouchio/ui";
import { SqlEditor } from "src/components/sql-editor";

export const SuccessfulRows = () => RowsPage(RowType.Successful);
export const RejectedRows = () => RowsPage(RowType.Rejected);

enum RowType {
  Successful = "successful",
  Rejected = "rejected",
}

const RowsPage = (rowType: RowType) => {
  const { sync, syncRequest } = useOutletContext<Context>();

  const [page, setPage] = useState<number>(0);
  const [pageKeys, setPageKeys] = useState<string[]>([]);

  const resetPagination = () => {
    setPageKeys([]);
    setPage(0);
  };

  const [searchInput, setSearchInput] = useState<string>("");
  const [search, setSearch] = useState<string>("");

  const [syncOpFilters, _setSyncOpFilters] = useState<Record<SyncOp, boolean>>({
    ADDED: true,
    CHANGED: true,
    REMOVED: true,
  });

  const setSyncOpFilters = (filters: Record<SyncOp, boolean>) => {
    resetPagination();
    _setSyncOpFilters(filters);
  };

  const showSuccessful = rowType === RowType.Successful;
  const showRejected = rowType === RowType.Rejected;

  const syncId = sync.id;
  const runId = syncRequest.id;
  const model = sync?.segment;
  const source = model?.connection;
  const attempt = syncRequest.sync_attempts?.[0];

  const syncError =
    syncRequest.error ??
    (attempt?.error ? { message: attempt.error } : undefined);
  const primaryKey = syncRequest.sync?.segment?.primary_key;
  const plannerType = syncRequest.planner_type;
  const errorCodeDetail = syncRequest.error_code_detail;

  if (syncError && errorCodeDetail) syncError.errorCodeDetail = errorCodeDetail;

  const { successful, rejected } = getSyncRunOperations({
    attempt,
    syncRequest,
    queryRun: syncRequest.query_run,
  });

  const addedRows: number | undefined | null = showSuccessful
    ? successful.added
    : rejected.added;

  const changedRows: number | undefined | null = showSuccessful
    ? successful.changed
    : rejected.changed;
  const removedRows: number | undefined | null = showSuccessful
    ? successful.removed
    : rejected.removed;

  let totalRows = 0;
  const opTypes: SyncOp[] = [];

  if (syncOpFilters.ADDED) {
    totalRows += addedRows ?? 0;
    opTypes.push(SyncOp.Added);
  }
  if (syncOpFilters.CHANGED && plannerType !== "all") {
    totalRows += changedRows ?? 0;
    opTypes.push(SyncOp.Changed);
  }
  if (syncOpFilters.REMOVED && plannerType !== "all") {
    totalRows += removedRows ?? 0;
    opTypes.push(SyncOp.Removed);
  }

  const limit = 10;
  const pages = Math.ceil(totalRows / limit);

  const {
    data: attemptedRowsData,
    isLoading: attemptedRowsLoading,
    isRefetching: attemptedRowsRefetching,
    error: attemptedRowsQueryError,
  } = useAttemptedRowsQuery(
    {
      destinationInstanceId: Number(syncId),
      syncRequestId: Number(runId),
      onlyRejected: showRejected,
      onlySuccessful: showSuccessful,
      pageKey: pageKeys.slice(-1)[0],
      opTypes,
      limit,
      plannerType: String(plannerType),
    },
    {
      // Only run once we have a response from the SyncAttempt query,
      // otherwise this will run twice: once on initial load, and once after the attempt is loaded.
      // Also, don't run this query if we are not on tab that has rows (eg. on config or pending tabs).
      enabled:
        Boolean(attempt) &&
        !(plannerType === "all" && totalRows === 0) &&
        Object.values(syncOpFilters).some((filter) => filter),
    },
  );

  const {
    data: attemptedRowsByPKData,
    isLoading: attemptedRowsByPKLoading,
    isRefetching: attemptedRowsByPKRefetching,
  } = useAttemptedRowsByPrimaryKeyQuery(
    {
      destinationInstanceId: Number(syncId),
      id: search,
      onlyRejected: showRejected,
      onlySuccessful: showSuccessful,
      plannerType: String(plannerType),
      syncRequestId: Number(runId),
    },
    {
      enabled: Boolean(search) && plannerType !== "all",
      keepPreviousData: true,
    },
  );

  const { appRunDebuggerEnabled } = useFlags();
  if (!appRunDebuggerEnabled) {
    const auditTable = source?.plan_in_warehouse_config?.schema?.audit;
    return (
      <Column gap={4} mt={4}>
        The debugger isn’t available in this workspace due to your privacy
        settings. Sync logs are instead written to your data source after the
        conclusion of every run. To inspect{" "}
        {showSuccessful ? "successful" : "failed"} rows, run the following query
        in your data source.
        <Column>
          <SqlEditor
            source={source}
            value={`SELECT
	*
FROM
	${typeof auditTable === "string" ? auditTable.toUpperCase() + "." : ""}SYNC_CHANGELOG
WHERE
	SYNC_ID = ${sync.id}
	AND SYNC_RUN_ID = ${runId}
	AND STATUS = '${showSuccessful ? "succeeded" : "failed"}';`}
            isBeautifyable={false}
            readOnly
            minHeight="0"
            showLineNumbers={false}
          />
        </Column>
      </Column>
    );
  }

  return (
    <>
      <RowsTable
        addedRows={addedRows}
        attemptedRowsByPKData={attemptedRowsByPKData}
        attemptedRowsByPKLoading={
          attemptedRowsByPKLoading || attemptedRowsByPKRefetching
        }
        attemptedRowsData={attemptedRowsData}
        attemptedRowsLoading={attemptedRowsLoading || attemptedRowsRefetching}
        attemptedRowsQueryError={attemptedRowsQueryError}
        changedRows={changedRows}
        page={page}
        pages={pages}
        plannerType={plannerType}
        primaryKey={primaryKey}
        removedRows={removedRows}
        search={search}
        searchInput={searchInput}
        setPage={setPage}
        setPageKeys={setPageKeys}
        setSearch={setSearch}
        setSearchInput={setSearchInput}
        setSyncOpFilters={setSyncOpFilters}
        syncOpFilters={syncOpFilters}
        showRejected={showRejected}
        source={source}
        sync={sync}
        syncError={syncError}
        syncRequest={syncRequest}
      />
      {/* Required to render debugger */}
      <Outlet />
    </>
  );
};

import { FC, useCallback, useMemo } from "react";

import { useNavigate } from "src/router";
import { DetailPage } from "src/components/layout";
import { PermissionedLinkButton } from "src/components/permission";
import { Table, useTableConfig } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { openUrl } from "src/utils/urls";
import genericPlaceholder from "src/assets/placeholders/generic.svg";

import { useFunctionsQuery } from "src/graphql";
import { lodashOrderBy } from "src/ui/table/use-table-config";

enum SortKeys {
  Name = "name",
  Description = "description",
  ConnectedSyncs = "function_resources_aggregate.aggregate.count",
  UpdatedAt = "updated_at",
}

export const FunctionsOverview: FC = () => {
  const navigate = useNavigate();

  const { sortKey, sortDirection, columnSortState } = useTableConfig<{
    [key in SortKeys]: "asc" | "desc";
  }>({
    defaultSortKey: SortKeys.UpdatedAt,
    sortOptions: Object.values(SortKeys),
  });

  const { data, isLoading } = useFunctionsQuery(
    {},
    {
      select: (data) => data.functions,
    },
  );

  // Sort in memory because we don't pagination event functions yet
  const sortedFns = useMemo(() => {
    if (!data || !sortKey || !sortDirection) return data;

    return lodashOrderBy(data, sortKey, sortDirection);
  }, [data, sortKey, sortDirection]);

  const onRowClick = useCallback(
    ({ id }, event) => openUrl(`/events/functions/${id}`, navigate, event),
    [navigate],
  );

  return (
    <DetailPage
      heading="Event functions"
      contentFullWidth
      actions={
        <PermissionedLinkButton
          permission={{
            v1: { resource: "workspace", grant: "update" },
            v2: {
              resource: "workspace",
              grant: "can_update",
            },
          }}
          variant="primary"
          href="new"
        >
          Create function
        </PermissionedLinkButton>
      }
    >
      <Table
        columns={[
          {
            name: "Name",
            ...columnSortState(SortKeys.Name),
            cell: ({ name }) => {
              return (
                <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
              );
            },
          },
          {
            name: "Description",
            ...columnSortState(SortKeys.Description),
            cell: ({ description }) => {
              return <TextWithTooltip>{description || "--"}</TextWithTooltip>;
            },
          },
          {
            name: "Connected syncs",
            ...columnSortState(SortKeys.ConnectedSyncs),
            cell: ({ function_resources_aggregate: { aggregate } }) => {
              return <TextWithTooltip>{aggregate?.count || 0}</TextWithTooltip>;
            },
          },
          {
            ...columnSortState(SortKeys.UpdatedAt),
            ...LastUpdatedColumn,
          },
        ]}
        data={sortedFns}
        loading={isLoading}
        onRowClick={onRowClick}
        placeholder={{
          image: genericPlaceholder,
          title: "No functions yet...",
          body: "Functions can be used to transform event data before syncing it to a destination.",
          error: "Functions failed to load, please try again.",
        }}
      />
    </DetailPage>
  );
};

import { FC, useMemo, useState } from "react";

import {
  Box,
  PlayIcon,
  Row,
  Text,
  ToggleButton,
  ToggleButtonGroup,
} from "@hightouchio/ui";
import { getOutgoers } from "reactflow";
import { useFormContext } from "react-hook-form";

import { IconBox } from "src/components/icon-box";
import { NODE_WITH_BRANCHES_LIBRARY_OPTIONS } from "src/pages/journeys/node-library";
import {
  JourneyEventType,
  JourneyGraph,
  JourneyNode,
  JourneyNodeRun,
} from "src/pages/journeys/types";
import { Table } from "src/ui/table";
import { roundToDecimalPlace } from "src/utils/numbers";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  JOURNEY_NODES_WITH_BRANCH_NODES,
  PERFORMANCE_TABLE_CELL_STYLES,
  PERFORMANCE_TABLE_HEADER_STYLES,
  PERFORMANCE_TABLE_RIGHT_ALIGNED_CELL_STYLES,
  PERFORMANCE_TABLE_RIGHT_ALIGNED_HEADER_STYLES,
} from "src/pages/journeys/constants";
import { JourneyNodeType } from "src/types/journeys";
import { CellContent } from "src/pages/journeys/components/journey-node-performance-cell";
import { useGraphContext } from "src/pages/journeys/graph/use-graph-context";

const getPercentage = (branchCount: number, parentCount: number): number =>
  roundToDecimalPlace((branchCount / parentCount) * 100, 1);

export type PerformanceCellRowData = {
  label: string | JSX.Element;
  total?: string | null;
  unique?: string | null;
  text?: string;
  branchId?: string;
  eventType?: JourneyEventType;
};

type JourneyNodePerformanceTableProps = {
  selectedNode: JourneyNode;
  nodeRunStats?: JourneyNodeRun[];
  onOpenDrawer: () => void;
};
const isEntryNode = (nodeConfig: JourneyNodeType): boolean =>
  nodeConfig == JourneyNodeType.EntryCohort ||
  nodeConfig == JourneyNodeType.EntryEvent;

export const JourneyNodePerformanceTable: FC<
  JourneyNodePerformanceTableProps
> = ({ selectedNode, nodeRunStats, onOpenDrawer }) => {
  const { nodes } = useGraphContext();
  const [showCount, setShowCount] = useState<boolean>(true);
  const currentNodeStats = useMemo(() => {
    return nodeRunStats?.find((nodeRun) => nodeRun.node_id === selectedNode.id);
  }, [selectedNode.id, nodeRunStats]);

  const isEntry = isEntryNode(selectedNode.data?.config?.type);
  const form = useFormContext<JourneyGraph>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Condition types
  const edges = form.watch("edges");
  const [branchNodes, isTerminalNode] = useMemo(() => {
    if (selectedNode) {
      const outgoers = getOutgoers(selectedNode, nodes, edges);
      const expectedBranch = JOURNEY_NODES_WITH_BRANCH_NODES.includes(
        selectedNode.data.config.type,
      );
      if (expectedBranch) {
        return [outgoers, outgoers?.length == 0];
      }
      return [[], outgoers?.length == 0];
    }
    return [[], true];
  }, [selectedNode, nodes, edges]);

  if (!selectedNode || !currentNodeStats) {
    return null;
  }

  const branchNodeData: PerformanceCellRowData[] = branchNodes?.length
    ? [
        {
          label: (
            <ToggleButtonGroup
              size="sm"
              marginY={1}
              value={showCount ? "#" : "%"}
              onChange={() => setShowCount(!showCount)}
            >
              <ToggleButton label="#" value="#" />
              <ToggleButton label="%" value="%" />
            </ToggleButtonGroup>
          ),
          total: "",
          unique: "",
        },
        ...branchNodes.map((branchNode) => {
          const branchRunStats = nodeRunStats?.find(
            (nodeRun) => nodeRun.node_id === branchNode.id,
          );
          const entryCount = branchRunStats?.entry_count || 0;
          const uniqueEntryCount = branchRunStats?.unique_entry_count || 0;
          let total = entryCount;
          let unique = uniqueEntryCount;
          if (!showCount) {
            total = branchRunStats
              ? getPercentage(
                  Number(entryCount),
                  Number(currentNodeStats?.entry_count || 0),
                )
              : "";
            unique = branchRunStats
              ? getPercentage(
                  Number(uniqueEntryCount),
                  Number(currentNodeStats?.unique_entry_count || 0),
                )
              : "";
          }

          return {
            label: (
              <Box borderLeft="border" pl={2}>
                <Box pl={2} borderLeftWidth={2}>
                  <Text color="text.secondary">{branchNode.data.name}</Text>
                </Box>
              </Box>
            ),
            text: branchNode.data.name,
            total: String(total),
            unique: String(unique),
            branchId: branchNode.id,
            eventType: "entered-tile" as JourneyEventType,
          };
        }),
      ]
    : [];

  return (
    <Table
      rowHeight="24px"
      data={[
        {
          label: "Currently in tile",
          total: selectedNode.data.number_users,
          unique: selectedNode.data.number_unique_users,
          eventType: "in-progress",
        },
        {
          label: "Entered tile",
          total: currentNodeStats.entry_count,
          unique: currentNodeStats.unique_entry_count,
          eventType: isEntry ? "entered-journey" : "entered-tile",
        },
        {
          label: "Met exit criteria",
          total: currentNodeStats.exit_count,
          unique: currentNodeStats.unique_exit_count,
          eventType: "exited-journey-by-criteria",
        },
        {
          label: "Advanced from tile",
          total: isTerminalNode
            ? currentNodeStats.completed_count
            : currentNodeStats.advanced_count,
          unique: isTerminalNode
            ? currentNodeStats.unique_completed_count
            : currentNodeStats.unique_advanced_count,
          eventType: isTerminalNode ? "completed-journey" : "advanced-from",
        },
        ...branchNodeData,
      ]}
      columns={[
        {
          headerSx: {
            ...PERFORMANCE_TABLE_HEADER_STYLES,
            pl: "0 !important",
          },
          cellSx: {
            ...PERFORMANCE_TABLE_CELL_STYLES,
            pl: "0 !important",
          },
          min: "120px",
          header: () => {
            const tileMetadata = NODE_WITH_BRANCHES_LIBRARY_OPTIONS.find(
              ({ type }) => type === selectedNode?.data.config.type,
            );

            return (
              <Row
                gap={2}
                color={`${tileMetadata?.color ?? "gray"}.600`}
                overflowX="hidden"
              >
                <IconBox
                  bg={`${tileMetadata?.color ?? "gray"}.400`}
                  boxSize="20px"
                  icon={tileMetadata?.icon ?? <PlayIcon />}
                  iconSize="14px"
                />
                <TextWithTooltip
                  color={`${tileMetadata?.color ?? "gray"}.600`}
                  fontWeight="semibold"
                  message={selectedNode?.data.name}
                >
                  {selectedNode?.data.name}
                </TextWithTooltip>
              </Row>
            );
          },
          cell: (row: PerformanceCellRowData, index) => (
            <Row
              gap={0}
              fontWeight={index === 0 ? "medium" : "normal"}
              overflowX="hidden"
            >
              <TextWithTooltip message={row.text}>{row.label}</TextWithTooltip>
            </Row>
          ),
        },
        {
          headerSx: {
            ...PERFORMANCE_TABLE_HEADER_STYLES,
            ...PERFORMANCE_TABLE_RIGHT_ALIGNED_HEADER_STYLES,
          },
          cellSx: {
            ...PERFORMANCE_TABLE_CELL_STYLES,
            ...PERFORMANCE_TABLE_RIGHT_ALIGNED_CELL_STYLES,
          },
          header: () => <Text color="text.secondary">Total</Text>,
          cell: (row, index) => (
            <CellContent
              showCount={showCount}
              onOpenDrawer={onOpenDrawer}
              fontWeight={index == 0 ? "semibold" : "normal"}
              nodeId={row.branchId || selectedNode.id}
              text={row.total}
              isBranch={row.branchId != null}
              eventType={row.eventType}
            />
          ),
        },
        {
          headerSx: {
            ...PERFORMANCE_TABLE_HEADER_STYLES,
            ...PERFORMANCE_TABLE_RIGHT_ALIGNED_HEADER_STYLES,
          },
          cellSx: {
            ...PERFORMANCE_TABLE_CELL_STYLES,
            ...PERFORMANCE_TABLE_RIGHT_ALIGNED_CELL_STYLES,
          },
          header: () => <Text color="text.secondary">Uniques</Text>,
          cell: (row, index) => (
            <CellContent
              showCount={showCount}
              onOpenDrawer={onOpenDrawer}
              fontWeight={index == 0 ? "semibold" : "normal"}
              nodeId={row.branchId || selectedNode.id}
              text={row.unique}
              isBranch={row.branchId != null}
              eventType={row.eventType}
            />
          ),
        },
      ]}
    />
  );
};

import { FC } from "react";

import { Box, Column, Skeleton, SkeletonBox, Text } from "@hightouchio/ui";

import {
  BreakdownTable,
  BreakdownTableProps,
} from "src/components/analytics/breakdowns/breakdown-table";
import { MoveableBreakdownColumn } from "src/components/analytics/breakdowns/utils";
import { HTImage } from "src/components/image";
import { AnalyticsPlaceholderImage } from "src/pages/analytics/placeholders";
import errorImg from "src/ui/table/error.svg";

export type BreakdownChartProps = BreakdownTableProps & {
  columnsOrder: MoveableBreakdownColumn[];
  rowsLimit: number;
  isLoading?: boolean;
  isError?: boolean;
};

// Roughly the height of the breakdown table with 5 rows
const MIN_CHART_HEIGHT = "300px";

export const BreakdownChart: FC<BreakdownChartProps> = ({
  columnsOrder,
  isLoading = false,
  isError = false,
  rowsLimit,
  ...props
}) => {
  if (isLoading || !props.data) {
    return (
      <Skeleton isLoading={isLoading || !props.data}>
        <Box display="flex" flexDirection="column" gap={2}>
          {Array.from({ length: rowsLimit }).map((_, index) => (
            <SkeletonBox key={index} height="16px" borderRadius="sm" />
          ))}
        </Box>
      </Skeleton>
    );
  }

  if (isError) {
    return (
      <Column
        align="center"
        justify="center"
        gap={4}
        minHeight={MIN_CHART_HEIGHT}
      >
        <HTImage src={errorImg} alt="Error icon." />
        <Column gap={1} align="center">
          <Text color="danger.base" fontWeight="medium">
            Failed to load breakdown chart
          </Text>
        </Column>
      </Column>
    );
  }

  if (props.data.length === 0) {
    return (
      <Column
        align="center"
        justify="center"
        gap={4}
        textAlign="center"
        minHeight={MIN_CHART_HEIGHT}
      >
        <AnalyticsPlaceholderImage />
        <Text fontWeight="medium">No data to display</Text>
      </Column>
    );
  }

  return (
    <BreakdownTable
      data={props.data}
      groupByColumns={props.groupByColumns}
      enableSearch={false}
      showHeaders={false}
      rowsLimit={rowsLimit}
      columnsOrder={columnsOrder}
    />
  );
};

import {
  Alert,
  Button,
  ChakraUnorderedList,
  ChakraListItem,
  Column,
  FormField,
  Textarea,
  TextInput,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { AudienceExplore } from "src/components/audiences/audience-explore";
import { useHightouchForm } from "src/components/form";
import { PageSpinner } from "src/components/loading";
import { ParentModelSelect } from "src/components/models/parent-model-select";
import {
  useCreateAudienceTemplateMutation,
  useMinimalAudienceDefinitionQuery,
} from "src/graphql";
import { useModelState } from "src/hooks/use-model-state";
import { QueryType } from "src/types/models";
import { useWizardStepper } from "src/utils/use-wizard-stepper";
import { useNavigate, useSearchParams } from "src/router";
import { DeprecatedWizard, WizardStep } from "src/components/wizard";
import { getTagsFromLabels, LabelForm } from "src/components/labels/label-form";
import { ResourceType } from "src/components/labels/use-labels";
import * as localStorage from "src/utils/storage";
import { useFetchQueryBuilderParentModel } from "src/components/explore/hooks";
import { useAudienceCreationPermission } from "src/components/permission/creation/model";

export const validationSchema = Yup.object()
  .shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
  })
  .required();

const HIDE_TEMPLATE_CALLOUT_LOCAL_STORAGE_KEY =
  "hide-audience-template-callout";

export const CreateAudienceTemplate = () => {
  const navigate = useNavigate();

  const [hideCallout, setHideCallout] = useState(
    localStorage.load(HIDE_TEMPLATE_CALLOUT_LOCAL_STORAGE_KEY),
  );

  const [parentModelId, setParentModelId] = useState<string | undefined>();
  const { parentModel, isLoading: isParentModelLoading } =
    useFetchQueryBuilderParentModel({
      parentModelId: parentModelId ?? "",
    });
  const source = parentModel?.connection;

  // If we're creating a template from an audience,
  // fetch the parent model ID and conditions.
  // Then automatatically set those values.
  const [searchParams] = useSearchParams();
  const audienceId = searchParams.get("audienceId");
  const {
    data: minimalAudienceDefinition,
    isLoading: isMinimalAudienceDefinitionLoading,
  } = useMinimalAudienceDefinitionQuery(
    { audienceId: audienceId ?? "" },
    { enabled: Boolean(audienceId) },
  );
  useEffect(() => {
    if (minimalAudienceDefinition) {
      setParentModelId(
        minimalAudienceDefinition.segments_by_pk?.visual_query_parent_id.toString(),
      );
      setActiveStep((s) => s + 1);
    }
  }, [minimalAudienceDefinition]);

  const modelState = useModelState({
    query_type: QueryType.Visual,
    connection: source,
    parent: parentModel,
    visual_query_filter: {
      conditions: minimalAudienceDefinition
        ? minimalAudienceDefinition.segments_by_pk?.visual_query_filter
            .conditions
        : [],
    },
  });

  const createAudienceTemplate = useCreateAudienceTemplateMutation();
  const form = useHightouchForm<{
    name: string;
    description: string;
    labels: { key: string; value: string }[];
  }>({
    resolver: yupResolver(validationSchema),
    success: "Audience template created",
    onSubmit: async ({ name, description, labels }) => {
      const { insert_audience_templates_one } =
        await createAudienceTemplate.mutateAsync({
          input: {
            name,
            description,
            parent_model_id: parentModel?.id,
            definition: modelState.state.visual_query_filter,
            labels: getTagsFromLabels(labels),
          },
        });

      navigate(
        `/templates/audience-templates/${insert_audience_templates_one?.id}`,
      );
    },
    defaultValues: {
      labels: [{ key: "", value: "" }],
    },
  });

  const { parentModelFilter } = useAudienceCreationPermission();

  const [activeStep, setActiveStep] = useWizardStepper(0);

  const steps: WizardStep[] = [
    {
      title: "Choose a parent model",
      continue: "Click on a parent model to continue",
      onContinue: () => setActiveStep((s) => s + 1),
      render: () =>
        audienceId && isMinimalAudienceDefinitionLoading ? (
          <PageSpinner />
        ) : (
          <ParentModelSelect
            showAudienceRatio
            isOptionAvailable={parentModelFilter}
            onSelect={(model) => {
              setParentModelId(model.id);
              setActiveStep((s) => s + 1);
            }}
          />
        ),
    },
    {
      title: "Define template",
      onContinue: () => setActiveStep((s) => s + 1),
      render: () => (
        <Column minHeight={0} flex={1} background="base.lightBackground">
          {isParentModelLoading ? (
            <PageSpinner />
          ) : (
            <Column>
              {!hideCallout && (
                <Alert
                  actions={
                    <Button
                      onClick={() => {
                        localStorage.save(
                          HIDE_TEMPLATE_CALLOUT_LOCAL_STORAGE_KEY,
                          true,
                        );

                        setHideCallout(true);
                      }}
                    >
                      Don't show this again
                    </Button>
                  }
                  message={
                    <ChakraUnorderedList>
                      <ChakraListItem>
                        With templates, you don’t have to specify every property
                        — you can leave blanks for others to fill in.
                      </ChakraListItem>
                      <ChakraListItem>
                        Templates can’t be synced.
                      </ChakraListItem>
                      <ChakraListItem>
                        Editing or deleting a template will not affect any
                        audiences that were originally created using the
                        template.
                      </ChakraListItem>
                    </ChakraUnorderedList>
                  }
                  onDismiss={() => setHideCallout(true)}
                  title="You’re creating an audience template"
                  type="info"
                  variant="banner"
                />
              )}
              <AudienceExplore
                modelState={modelState}
                parentModel={parentModel}
                source={source}
                variant="template"
              />
            </Column>
          )}
        </Column>
      ),
    },
    {
      title: "Finalize settings for this template",
      render: () => (
        <Column gap={6}>
          <Controller
            control={form.control}
            name="name"
            render={({ field, fieldState }) => (
              <Column>
                <FormField
                  label="Template name"
                  error={fieldState.error?.message}
                >
                  <TextInput {...field} placeholder="Enter a name..." />
                </FormField>
              </Column>
            )}
          />
          <Controller
            control={form.control}
            name="description"
            render={({ field, fieldState }) => (
              <Column>
                <FormField
                  label="Description"
                  description="How and when should this template be used?"
                  error={fieldState.error?.message}
                >
                  <Textarea {...field} placeholder="Enter a description..." />
                </FormField>
              </Column>
            )}
          />
          <LabelForm
            heading="Add labels"
            hint="Example keys: team, project, region, env."
            resourceType={ResourceType.AudienceTemplate}
          />
        </Column>
      ),
    },
  ];

  const onCancel = () => {
    if (audienceId) {
      // If we're creating a template from an audience,
      // navigate back to the audience
      navigate(`/audiences/${audienceId}`);
    } else {
      navigate("/templates/audience-templates");
    }
  };

  return (
    <FormProvider {...form}>
      <DeprecatedWizard
        fullscreen={activeStep === 1}
        setStep={setActiveStep}
        step={activeStep}
        steps={steps}
        title="Create audience template"
        onCancel={onCancel}
        onSubmit={form.submit}
      />
    </FormProvider>
  );
};

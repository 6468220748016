import { FC, useCallback, useMemo, useState } from "react";

import {
  useToast,
  ButtonGroup,
  ConfirmationDialog,
  Paragraph,
  Text,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { uniqBy } from "lodash";
import pluralize from "pluralize";

import { useNavigate, useSearchParams } from "src/router";
import contractPlaceholder from "src/assets/placeholders/contracts.svg";
import {
  useCreateEventPlanMutation,
  useDeleteEventPlansMutation,
  useEventPlansQuery,
  useUpdateEventPlanSourcesMutation,
} from "src/graphql";
import { Table, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useRowSelect } from "src/ui/table/use-row-select";
import { PermissionedButton } from "src/components/permission";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { openUrl } from "src/utils/urls";
import {
  CreateContractDrawer,
  NewContractFormState,
} from "./create-contract-drawer";
import { DetailPage } from "src/components/layout";
import { generateEventContractSlug } from "./contract/event-schema/slugs";
import { useSlug } from "src/utils/slug";
import { lodashOrderBy } from "src/ui/table/use-table-config";

enum SortKeys {
  Name = "name",
  Events = "event_schemas_aggregate.aggregate.count",
  Description = "description",
  Sources = "event_sources_aggregate.aggregate.count",
  UpdatedAt = "updated_at",
}

export const EventContracts: FC = () => {
  const { toast } = useToast();
  const { getSlug } = useSlug();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [confirmBulkDelete, setConfirmBulkDelete] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();

  const { sortKey, sortDirection, columnSortState } = useTableConfig<{
    [key in SortKeys]: "asc" | "desc";
  }>({
    defaultSortKey: SortKeys.UpdatedAt,
    sortOptions: Object.values(SortKeys),
  });

  const { data: contracts, isLoading } = useEventPlansQuery(undefined, {
    select: (data) => {
      return data.event_plans;
    },
    notifyOnChangeProps: "tracked",
    keepPreviousData: true,
  });

  const createEventPlanMutation = useCreateEventPlanMutation();
  const deleteEventPlansMutation = useDeleteEventPlansMutation();
  const updateEventPlanSourcesMutation = useUpdateEventPlanSourcesMutation();

  const drawerOpen = searchParams.get("mode") === "create";
  const closeDrawer = () => navigate("");

  const bulkDeleteContracts = async () => {
    try {
      // first, remove event plan from event sources
      const sourcesToUpdate = uniqBy(
        selectedRows.flatMap((selectedId) => {
          return (
            contracts?.find(({ id }) => selectedId === id)?.event_sources ?? []
          );
        }),
        "id",
      );

      if (sourcesToUpdate.length > 0) {
        // TODO: remove once the backend can remove the relationship between
        // the event source and the event plan being deleted (on delete)
        // https://carryinternal.slack.com/archives/C03BQGJP46S/p1700164392435939?thread_ts=1700161669.309389&cid=C03BQGJP46S
        await updateEventPlanSourcesMutation.mutateAsync({
          ids: sourcesToUpdate.map(({ id }) => id),
          event_plan_id: null,
        });
      }

      await deleteEventPlansMutation.mutateAsync({
        ids: selectedRows.map(String),
      });

      toast({
        id: "delete-event-contract",
        title: `Event ${pluralize("contract", selectedRows.length)} deleted`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "delete-event-contract",
        title: `Failed to delete contract ${pluralize(
          "contract",
          selectedRows.length,
        )}`,
        message: error.message,
        variant: "error",
      });
    }
  };

  const createEventPlan = async (data: NewContractFormState) => {
    try {
      const slug = await generateEventContractSlug(getSlug, {
        name: data.name,
      });
      const result = await createEventPlanMutation.mutateAsync({
        object: {
          name: data.name,
          slug: slug,
          description: data.description || "",
          default_schema_version: "default", // important!! not done on the backend.
          on_undeclared_schema: data.blockUndeclaredEventTypes,
        },
      });

      await updateEventPlanSourcesMutation.mutateAsync({
        ids: data.sources,
        event_plan_id: result.insert_event_plans_one?.id ?? "",
      });

      toast({
        id: "create-event-contract",
        title: "Event contract created",
        variant: "success",
      });

      closeDrawer();

      if (result.insert_event_plans_one) {
        navigate(`${result.insert_event_plans_one.id}`);
      }
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "create-event-contract",
        title: "Failed to create event contract",
        message: error.message,
        variant: "error",
      });
    }
  };

  const onRowClick = useCallback(
    ({ id }, event) => openUrl(`/events/contracts/${id}`, navigate, event),
    [navigate],
  );

  // Sort in memory because we don't pagination event contracts yet
  const sortedContracts = useMemo(() => {
    if (!contracts || !sortKey || !sortDirection) return contracts;

    return lodashOrderBy(contracts, sortKey, sortDirection);
  }, [contracts, sortKey, sortDirection]);

  return (
    <DetailPage
      heading="Event contracts"
      contentFullWidth
      actions={
        contracts &&
        contracts.length > 0 && (
          <ButtonGroup size="lg">
            {selectedRows.length > 0 && (
              <PermissionedButton
                permission={{
                  v1: { resource: "workspace", grant: "update" },
                  v2: {
                    resource: "workspace",
                    grant: "can_update",
                  },
                }}
                variant="warning"
                onClick={() => setConfirmBulkDelete(true)}
              >
                Delete {pluralize("contract", selectedRows.length, true)}
              </PermissionedButton>
            )}
            <PermissionedButton
              permission={{
                v1: { resource: "workspace", grant: "update" },
                v2: {
                  resource: "workspace",
                  grant: "can_update",
                },
              }}
              variant="primary"
              onClick={() => setSearchParams({ mode: "create" })}
            >
              Create contract
            </PermissionedButton>
          </ButtonGroup>
        )
      }
    >
      <Table
        columns={[
          {
            name: "Name",
            ...columnSortState(SortKeys.Name),
            cell: ({ name }) => {
              return (
                <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
              );
            },
          },
          {
            name: "Events",
            ...columnSortState(SortKeys.Events),
            cell: ({ event_schemas_aggregate }) => {
              return (
                <Text fontWeight="medium">
                  {event_schemas_aggregate.aggregate?.count ?? "0"}
                </Text>
              );
            },
          },
          {
            name: "Description",
            ...columnSortState(SortKeys.Description),
            cell: ({ description }) => {
              return (
                <TextWithTooltip fontWeight="medium">
                  {description || "--"}
                </TextWithTooltip>
              );
            },
          },
          {
            name: "Sources",
            ...columnSortState(SortKeys.Sources),
            cell: ({ event_sources_aggregate }) => {
              return (
                <Text fontWeight="medium">
                  {event_sources_aggregate.aggregate?.count ?? "0"}
                </Text>
              );
            },
          },
          {
            ...columnSortState(SortKeys.UpdatedAt),
            ...LastUpdatedColumn,
          },
        ]}
        data={sortedContracts}
        loading={isLoading}
        placeholder={{
          ...placeholder,
          button: (
            <PermissionedButton
              permission={{
                v1: { resource: "workspace", grant: "update" },
                v2: {
                  resource: "workspace",
                  grant: "can_update",
                },
              }}
              variant="primary"
              onClick={() => setSearchParams({ mode: "create" })}
            >
              New contract
            </PermissionedButton>
          ),
        }}
        selectedRows={selectedRows}
        onRowClick={onRowClick}
        onSelect={onRowSelect}
      />

      <CreateContractDrawer
        isOpen={drawerOpen}
        onClose={closeDrawer}
        onSubmit={createEventPlan}
      />

      <ConfirmationDialog
        isOpen={confirmBulkDelete}
        title={`Delete ${pluralize("contract", selectedRows.length, true)}`}
        confirmButtonText={`Delete ${pluralize(
          "contract",
          selectedRows.length,
          true,
        )}`}
        variant="danger"
        onClose={() => setConfirmBulkDelete(false)}
        onConfirm={bulkDeleteContracts}
      >
        <Paragraph>
          Are you sure you want to delete{" "}
          {pluralize("this", selectedRows.length)}{" "}
          {pluralize("contract", selectedRows.length)}? You will not be able to
          undo this.
        </Paragraph>
      </ConfirmationDialog>
    </DetailPage>
  );
};

const placeholder = {
  image: contractPlaceholder,
  title: "No contracts added",
  body: "Contracts allow you to identify when your event source is delivering incomplete or inconsistent data, by defining what events and properties are required so that Hightouch can flag any violations.",
  error: "Contracts failed to load, please try again.",
};

import { useLocation, useMatch, useSearchParams } from "src/router";

/**
 * Checks if the current route matches the href.
 *
 * @param pathToCheck The href to check against
 * @param matchParam Optional search parameter name to match against. If provided, only this parameter will be checked
 */
export const useIsRouteActive = (
  pathToCheck: string,
  matchParam?: string,
): boolean => {
  const [pathname, search] = pathToCheck.split("?");

  const match = useMatch(pathname ?? "");
  const location = useLocation();
  const [searchParams] = useSearchParams();

  if (!match) return false;

  // If there's no search in the href and no matchParam specified, we're done
  if (!matchParam && searchParams.size === 0 && !pathToCheck)
    return !location.search;

  // If the pathToCheck is supposed to have search params, but none exist then it can't be active
  if (!search && searchParams.size > 0) return false;

  // Parse search params
  const hrefSearchParams = new URLSearchParams(search);

  if (matchParam) {
    // If matchParam is specified, only check that parameter
    const hrefValue = hrefSearchParams.get(matchParam);
    const locationValue = searchParams.get(matchParam);

    // Both should be null or equal
    return hrefValue === locationValue;
  }

  // Otherwise check all parameters in href
  for (const [key, value] of hrefSearchParams.entries()) {
    if (searchParams.get(key) !== value) return false;
  }

  return true;
};

import { JourneyNodeType } from "src/types/journeys";

export enum JourneyNodeCategory {
  Entry = "entry",
  Activation = "activation",
  Waits = "waits",
  FlowControl = "flow-control",
}

export type NodeOptionProps = {
  color: string;
  label: string;
  icon: JSX.Element;
  type: JourneyNodeType;
  category: JourneyNodeCategory;
};

import { FC, useState } from "react";

import {
  Column,
  ConfirmationDialog,
  Heading,
  Paragraph,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { keyBy, mapValues } from "lodash";

import { LinkButton, Link, useOutletContext } from "src/router";
import { FunctionOutletContext } from "src/events/functions/types";
import {
  useCreateFunctionResourceMutation,
  useDeleteFunctionResourceMutation,
  useFunctionEventSyncResourcesQuery,
} from "src/graphql";
import {
  EventSourceType,
  EventWarehouseDestinationType,
  eventSourceDefinitions,
  eventWarehouseDestinationDefinitions,
} from "src/events/types";
import { Table } from "src/ui/table";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { PermissionedButton } from "src/components/permission";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import genericPlaceholder from "src/assets/placeholders/generic.svg";

type IntegrationMetadata = {
  id: string;
  name: string;
  type: string;
  icon: string;
};

type EventSync = {
  id: string;
  type: "event_warehouse_syncs" | "event_forwarding_syncs";
  source: IntegrationMetadata;
  destination: IntegrationMetadata;
  functionResource?: {
    id: string;
    function: { id: string; name: string };
  };
};

export const FunctionSyncs: FC = () => {
  const { function: fn } = useOutletContext<FunctionOutletContext>();
  const { toast } = useToast();

  const [connectingSync, setConnectingSync] = useState<EventSync | null>(null);
  const [disconnectingSyncId, setDisconnectingSyncId] = useState<string | null>(
    null,
  );

  const createFunctionResourceMutation = useCreateFunctionResourceMutation();
  const deleteFunctionResourceMutation = useDeleteFunctionResourceMutation();

  const { data: eventSyncs } = useFunctionEventSyncResourcesQuery(
    {},
    {
      select: (data) => {
        const functionResourcesByResourceId = mapValues(
          keyBy(data.function_resources, "resource_id"),
          ({ id, function_versions }) => ({
            id,
            // Safe because a function_resource will always a function_version (see graphql query)
            function: function_versions[0]!,
          }),
        );

        const forwardingSyncs: EventSync[] = data.event_forwarding_syncs.map(
          ({ id, event_source: source, destination }) => ({
            id,
            type: "event_forwarding_syncs",
            source: {
              id: source.id,
              name: source.name,
              type: source.type,
              icon: eventSourceDefinitions[source.type as EventSourceType].icon,
            },
            destination: {
              id: destination.id,
              name: destination.name || destination.type,
              type: destination.type,
              icon: destination.definition.icon,
            },
            functionResource: functionResourcesByResourceId[id],
          }),
        );
        const warehouseSyncs: EventSync[] = data.event_warehouse_syncs.map(
          ({
            id,
            event_source: source,
            event_warehouse_destination: destination,
          }) => ({
            id: id,
            type: "event_warehouse_syncs",
            source: {
              id: source.id,
              name: source.name,
              type: source.type,
              icon: eventSourceDefinitions[source.type as EventSourceType].icon,
            },
            destination: {
              id: destination.id,
              name: destination.name,
              type: destination.type,
              icon: eventWarehouseDestinationDefinitions[
                destination.type as EventWarehouseDestinationType
              ].icon,
            },
            functionResource: functionResourcesByResourceId[id],
          }),
        );

        return [...forwardingSyncs, ...warehouseSyncs];
      },
      suspense: true,
    },
  );

  // Unexpected case since we are using suspense, but makes TS happy
  if (!eventSyncs) return null;

  const connectFunction = async (sync: EventSync) => {
    try {
      await createFunctionResourceMutation.mutateAsync({
        object: {
          function_id: fn.id,
          resource_id: sync.id,
          resource_type: sync.type,
        },
      });

      toast({
        id: "create-function-resource",
        title: `"${fn.name}" connected to sync`,
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "create-function-resource",
        title: "Failed to connect function",
        message: error.message,
        variant: "error",
      });
    }
  };

  const disconnectFunction = async (functionResourceId: string) => {
    try {
      await deleteFunctionResourceMutation.mutateAsync({
        id: functionResourceId,
      });

      toast({
        id: "delete-function-resource",
        title: `"${fn.name}" disconnected`,
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "delete-function-resource",
        title: "Failed to disconnect function",
        message: error.message,
        variant: "error",
      });
    }
  };

  return (
    <Column gap={4}>
      <Row align="center" justify="space-between" gap={4}>
        <Column>
          <Heading>Syncs</Heading>
          <Paragraph color="text.secondary">
            Syncs can only be connected to one function at a time.
          </Paragraph>
        </Column>
      </Row>
      <Table
        data={eventSyncs}
        columns={[
          {
            name: "Source",
            cell: ({ source }) => (
              <Row
                as={LinkButton}
                variant="tertiary"
                href={`/events/sources/${source.id}`}
                icon={() => (
                  <IntegrationIcon
                    size={5}
                    name={source.type}
                    src={source.icon}
                  />
                )}
                _hover={{ backgroundColor: "inherit" }}
              >
                <TextWithTooltip isTruncated fontWeight="medium">
                  {source.name}
                </TextWithTooltip>
              </Row>
            ),
          },
          {
            name: "Destination",
            cell: ({ destination }) => (
              <Row
                as={LinkButton}
                variant="tertiary"
                href={`/events/destinations/${destination.id}`}
                icon={() => (
                  <IntegrationIcon
                    size={5}
                    name={destination.type}
                    src={destination.icon}
                  />
                )}
                _hover={{ backgroundColor: "inherit" }}
              >
                <TextWithTooltip isTruncated fontWeight="medium">
                  {destination.name}
                </TextWithTooltip>
              </Row>
            ),
          },
          {
            name: "Connected to",
            cell: ({ functionResource }) => {
              if (!functionResource) {
                return <Text>--</Text>;
              }

              const isConnected = functionResource.function.id === fn.id;

              return isConnected ? (
                <TextWithTooltip>
                  {functionResource.function.name}
                </TextWithTooltip>
              ) : (
                <TextWithTooltip message={functionResource.function.name}>
                  <Link
                    href={`/events/functions/${functionResource.function.id}`}
                  >
                    {functionResource.function.name}
                  </Link>
                </TextWithTooltip>
              );
            },
          },
          {
            max: "150px",
            cell: (sync) => {
              const resource = sync.functionResource;
              const isDisabled = Boolean(
                resource && resource.function.id !== fn.id,
              );
              const isConnected = Boolean(
                resource && resource.function.id === fn.id,
              );

              return (
                <PermissionedButton
                  permission={{
                    v1: { resource: "workspace", grant: "update" },
                    v2: {
                      resource: "workspace",
                      grant: "can_update",
                    },
                  }}
                  isDisabled={isDisabled}
                  variant={isConnected ? "secondary" : "primary"}
                  isJustified
                  onClick={() =>
                    isConnected
                      ? setDisconnectingSyncId(resource!.id)
                      : setConnectingSync(sync)
                  }
                  tooltip={
                    isDisabled
                      ? "Sync is already connected to another function."
                      : false
                  }
                >
                  {isConnected ? "Disconnect" : "Connect"}
                </PermissionedButton>
              );
            },
          },
          {
            max: "max-content",
            cell: ({ id }) => (
              <LinkButton href={`/events/syncs/${id}`}>View Sync</LinkButton>
            ),
          },
        ]}
        placeholder={{
          image: genericPlaceholder,
          title: "No event syncs",
          body: "Create an event sync to start using functions.",
        }}
      />
      <ConfirmationDialog
        isOpen={connectingSync !== null}
        title="Confirm new connection"
        onConfirm={async () => {
          await connectFunction(connectingSync!);
          setConnectingSync(null);
        }}
        onClose={() => setConnectingSync(null)}
        confirmButtonText="Connect"
        variant="warning"
      >
        Events in the target event sync will be transformed by this function.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={disconnectingSyncId !== null}
        title="Confirm disconnect"
        onConfirm={async () => {
          await disconnectFunction(disconnectingSyncId!);
          setDisconnectingSyncId(null);
        }}
        onClose={() => setDisconnectingSyncId(null)}
        confirmButtonText="Disconnect"
        variant="danger"
      >
        Events in the target event sync will no longer be transformed by this
        function.
      </ConfirmationDialog>
    </Column>
  );
};

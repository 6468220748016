import { TableProps } from "@hightouch/formkit";
import {
  Box,
  IconButton,
  Row,
  TextInput,
  Button,
  CloseIcon,
} from "@hightouchio/ui";
import { Select as HightouchUiSelect } from "@hightouchio/ui";
import { FC, useMemo } from "react";

import { Table, TableColumn } from "src/ui/table";
import { usePermissionContext } from "src/components/permission";

type Props = TableProps & {
  value: Record<string, unknown>[];
  onChange: any;
  isDisabled?: boolean;
};

export const FormkitTable: FC<Readonly<Props>> = ({
  addRow,
  addRowLabel = "Add row",
  columns,
  value,
  isDisabled = false,
  onChange,
}) => {
  const permission = usePermissionContext();

  const buildDummyData = (columns) => {
    const dummyData: Record<string, unknown> = {};
    for (const column of columns) {
      dummyData[column.columnKey] = column.default ?? "";
    }
    return dummyData;
  };

  if (!value || value.length === 0) {
    onChange([buildDummyData(columns)]);
  }

  const TrashButton = (rowIndex: number) => (
    <Box flex="none" pl="16px">
      <IconButton
        aria-label="Delete key"
        icon={CloseIcon}
        isDisabled={isDisabled}
        onClick={() => {
          const valuesCopy = [...value];
          valuesCopy?.splice(rowIndex, 1);
          onChange(valuesCopy);
        }}
      />
    </Box>
  );

  const memoizedColumns = useMemo(() => {
    const tableColumns: TableColumn[] = columns.map((column, columnIndex) => {
      return {
        name: column.header,
        headerSx: {
          paddingLeft: "0px !important",
          paddingBottom: "0px !important",
          borderBottom: undefined,
        },
        cellSx: {
          p: `16px ${
            columnIndex === columns.length - 1 ? "0px" : "16px"
          } 0px 0px !important`,
          borderBottom: undefined,
        },
        cell: (rowInfo, rowIndex) => {
          function set(newValue: unknown) {
            const tableData = [...value];
            if (tableData[rowIndex]) {
              tableData[rowIndex] = {
                ...value[rowIndex],
                [column.columnKey]: newValue,
              };
            } else {
              tableData.push({ [column.columnKey]: newValue });
            }
            onChange(tableData);
          }
          if (column.componentType === "select") {
            return (
              <>
                <HightouchUiSelect
                  isClearable={column.enableClear}
                  isDisabled={isDisabled || permission.unauthorized}
                  options={column.options}
                  placeholder={column.placeholder}
                  value={rowInfo[column.columnKey] || column.default}
                  onChange={set}
                />
              </>
            );
          }

          if (column.componentType === "react") {
            return column.component(rowInfo[column.columnKey], set);
          }

          return (
            <>
              <TextInput
                isDisabled={isDisabled || permission.unauthorized}
                isReadOnly={column.readOnly}
                placeholder={column.placeholder}
                type={column.type}
                value={rowInfo[column.columnKey] ?? ""}
                min={column.min}
                max={column.max}
                width="100%"
                onChange={(event) => {
                  const newValue = event.target.value ?? undefined;
                  set(newValue);
                }}
              />
            </>
          );
        },
      };
    });

    // If we can add a row, allow deleting rows
    if (addRow) {
      tableColumns.push({
        headerSx: {
          paddingLeft: "0px !important",
          paddingBottom: "0px !important",
          borderBottom: undefined,
        },
        cellSx: {
          p: `16px 0px 0px 0px !important`,
          borderBottom: undefined,
        },
        max: "36px",
        cell: (_rowInfo, rowIndex) => {
          return TrashButton(rowIndex);
        },
      });
    }
    return tableColumns;
  }, [value, columns, permission, onChange]);

  return (
    <>
      <Table
        columns={memoizedColumns || []}
        data={value?.length ? value : [buildDummyData(columns)]}
        rowHeight="auto"
        alignFirstHeader={true}
      />
      {addRow && (
        <Row pt="16px">
          <Button
            variant="secondary"
            isDisabled={isDisabled || permission.unauthorized}
            onClick={() => {
              if (value) {
                onChange([...value, buildDummyData(columns)]);
              } else {
                onChange([buildDummyData(columns)]);
              }
            }}
            size="md"
          >
            {addRowLabel}
          </Button>
        </Row>
      )}
    </>
  );
};

import { FC } from "react";
import { useOutletContext } from "src/router";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import { Form, FormActions, useHightouchForm } from "src/components/form";
import {
  FunctionCodeFormState,
  FunctionOutletContext,
} from "src/events/functions/types";
import { useCreateFunctionVersionMutation } from "src/graphql";
import { FunctionCodeEditor } from "./code-editor";
import { codeValidator } from "src/events/functions/utils";
import pluralize from "pluralize";
import { ActionBar } from "src/components/action-bar";

const validationSchema = Yup.object().shape({
  code: codeValidator,
});

export const FunctionDetails: FC = () => {
  const { function: fn } = useOutletContext<FunctionOutletContext>();

  const createFunctionVersion = useCreateFunctionVersionMutation();

  const numConnectedSyncs = fn.function_resources.length;
  const hasConnectedSyncs = numConnectedSyncs > 0;

  const form = useHightouchForm<FunctionCodeFormState>({
    defaultValues: {
      code: fn.code,
    },
    resolver: yupResolver(validationSchema),
    error: "Failed to update function",
    success: hasConnectedSyncs
      ? `Changes to function “${
          fn.name
        }” were applied to ${numConnectedSyncs} connected ${pluralize(
          "sync",
          numConnectedSyncs,
        )}.`
      : `Changes to function “${fn.name}” were saved.`,
    onSubmit: async (data) => {
      await createFunctionVersion.mutateAsync({
        object: {
          id: fn.id,
          code: data.code,
        },
      });

      // We need to call this manually to reset the default values after update
      form.reset({
        code: data.code,
      });
    },
  });

  return (
    <Form form={form}>
      <FunctionCodeEditor />
      <ActionBar fit>
        <FormActions
          permission={{
            v1: { resource: "workspace", grant: "update" },
            v2: {
              resource: "workspace",
              grant: "can_update",
            },
          }}
          // If no syncs are connected, don’t show the confirmation modal.
          confirmation={
            hasConnectedSyncs
              ? {
                  title: "Confirm changes",
                  message: `${fn.function_resources.length} connected syncs will immediately use the new function code.`,
                }
              : undefined
          }
        />
      </ActionBar>
    </Form>
  );
};

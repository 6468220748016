import { useMemo } from "react";

import { Graph } from "src/components/analytics/cross-audience-graph/types";
import { transformMetricDataForGraph } from "src/components/analytics/cross-audience-graph/utils";
import { QueryEvaluateTimeSeriesMetricArgs } from "src/graphql";
import { formatGroupsFromDecisionEngineMetricResult } from "src/pages/analytics/decision-engine-utils";
import { useEvaluateAndPollTimeSeriesRequest } from "src/pages/analytics/hooks/use-evaluate-and-poll-time-series";
import {
  GroupByColumn,
  ParentModel,
  SyntheticColumnValuesGetter,
} from "src/pages/analytics/types";

export const useBreakdownMetricSeries = ({
  timeSeriesRequest,
  valuesTransformer,
  groupByColumns,
}: {
  timeSeriesRequest: QueryEvaluateTimeSeriesMetricArgs;
  valuesTransformer: SyntheticColumnValuesGetter;
  groupByColumns: GroupByColumn[];
}): { graph: Graph; isLoading: boolean; errorMessage: string | null } => {
  const { timeSeriesData, isPolling, pollingError } =
    useEvaluateAndPollTimeSeriesRequest({
      timeSeriesRequest,
      requestEnabled: true,
    });

  const metricResults = formatGroupsFromDecisionEngineMetricResult(
    timeSeriesData,
    valuesTransformer,
  );

  const audienceIds = timeSeriesRequest.cohortIds ?? [];

  const graph = useMemo(() => {
    return transformMetricDataForGraph({
      groupByColumns,
      metricResults,
      // Below are used to build the metric name but we will build our own for these breakdown charts
      audiences: audienceIds.map((id) => ({
        id: Number(id),
        name: "ignored",
        splits: [],
      })),
      events: [],
      metrics: [],
      parent: { id: "ignored" } as unknown as ParentModel,
      transformForPerformance: false,
    });
  }, [timeSeriesData]);

  const timeSeriesError = useMemo(() => {
    if (pollingError) {
      return pollingError?.message ?? "Unknown polling error";
    }

    const errorMessages: string[] = [];

    timeSeriesData?.forEach(({ ids, result }) => {
      const id = ids.cohortDefinition?.parentModelId ?? ids.cohortId;
      if (id) {
        if ("error" in result) {
          errorMessages.push(result.error);
        }
      }
    });

    return errorMessages.join(", ");
  }, [timeSeriesData]);

  return {
    graph,
    isLoading: isPolling,
    errorMessage: timeSeriesError,
  };
};

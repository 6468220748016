import { FC, useCallback, useEffect, useMemo, useState } from "react";

import {
  AudienceIcon,
  Badge,
  Box,
  Column,
  DeleteIcon,
  FolderIcon,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Paragraph,
  Row,
  SearchInput,
  TagIcon,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { format } from "date-fns/format";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEmpty, orderBy as lodashOrderBy } from "lodash";
import pluralize from "pluralize";
import { useNavigate } from "src/router";
import { formatFriendlyDistanceToNow } from "src/utils/time";

import audiencePlaceholder from "src/assets/placeholders/audience.svg";
import { AudiencesDemo } from "src/components/audiences/audiences-demo";
import {
  isFilterActive,
  labelFilter,
} from "src/components/folders/filter-helpers";
import {
  Filters,
  createdByFilterConfig,
  labelFilterConfig,
  parentModelFilterConfigForAudiences,
  sourceConfigForAudiences,
  subsetsFilterConfigForAudiences,
  useFilters,
} from "src/components/folders/filters";
import { Folders } from "src/components/folders/folder-list";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolderState } from "src/components/folders/use-folder-state";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { ResourceType } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { PageSidebar } from "src/components/layout/page-sidebar";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { PageAlert } from "src/components/page-alert";
import {
  PermissionedLinkButton,
  PermissionedMenuItem,
} from "src/components/permission";
import { PermissionProvider } from "src/components/permission/permission-context";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useUser } from "src/contexts/user-context";
import {
  OrderBy,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAddLabelsToAudiencesMutation,
  useAudienceFiltersQuery,
  useAudiencesQuery,
  useDeleteModelsMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import * as analytics from "src/lib/analytics";
import { SyncsCell } from "src/pages/syncs/sync/components/syncs-cell";
import {
  Pagination,
  SortOption,
  Table,
  useTableConfig,
  useTableSort,
} from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { abbreviateNumber } from "src/utils/numbers";
import { openUrl } from "src/utils/urls";
import { isPresent } from "ts-extras";
import { useSearchQueryState } from "src/hooks/use-search-query-state";
import { AudienceStats } from "src/components/filters";
import { EmptyPlaceholder } from "./empty-placeholder";
import { useHeaderHeight } from "src/contexts/header-height-context";

const initialSort: SortOption<keyof SegmentsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof SegmentsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  {
    key: "syncs_aggregate.count" as any,
    direction: OrderBy.Desc,
    label: "Number of syncs",
  },
  { key: "last_run_size", direction: OrderBy.DescNullsLast, label: "Largest" },
  { key: "last_run_size", direction: OrderBy.AscNullsLast, label: "Smallest" },
  initialSort,
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

// Audiences with not one sync that has run and is unpaused
export const inactiveSyncsBoolExp: Readonly<SegmentsBoolExp> = {
  syncs_aggregate: {
    count: {
      predicate: { _eq: 0 },
      filter: {
        _and: [
          { schedule_paused: { _neq: true } },
          { last_run_at: { _is_null: false } },
        ],
      },
    },
  },
};

export const Audiences: FC = () => {
  const { headerHeight } = useHeaderHeight();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { search, setSearch, debouncedSearch, isSearchPending } =
    useSearchQueryState();

  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const {
    selectedFolder,
    setSelectedFolder,
    setMovingToFolder,
    movingToFolder,
    header,
    refetchFolders,
    audienceCount,
    nestedFolders: folders,
    loadingFolders,
  } = useFolderState({
    search,
    resourceType: "models",
    folderType: "audiences",
  });

  const { limit, offset, page, setPage } = useTableConfig<SegmentsOrderBy>();
  const orderBy = useTableSort<SegmentsOrderBy>(initialSort, sortOptions);

  const { mutateAsync: addLabels } = useAddLabelsToAudiencesMutation();
  const { mutateAsync: bulkDelete } = useDeleteModelsMutation();
  const { data: allAudiences, isLoading: filtersLoading } =
    useAudienceFiltersQuery(undefined, {
      select: (data) => data.segments,
    });

  const memodLabelFilterConfig = useMemo(
    () => labelFilterConfig(allAudiences || []),
    [allAudiences],
  );

  const memodCreatedByFilterConfig = useMemo(
    () => createdByFilterConfig(allAudiences || []),
    [allAudiences],
  );

  const memodParentModelFilterConfig = useMemo(
    () =>
      parentModelFilterConfigForAudiences(
        allAudiences?.map((a) => a.parent).filter(isPresent) || [],
      ),
    [allAudiences],
  );

  const memodSourceFilterConfig = useMemo(
    () =>
      sourceConfigForAudiences(
        allAudiences?.map((a) => a.parent).filter(isPresent) || [],
      ),
    [allAudiences],
  );

  const memodSubsetFilterConfig = useMemo(
    () =>
      subsetsFilterConfigForAudiences(
        allAudiences?.flatMap(
          (a) =>
            a.subsets?.map((s) => ({
              name: s.subset_value.name,
              groupName: s.subset_value.subset_group.name,
              id: s.subset_value.id,
            })) ?? [],
        ) ?? [],
      ),
    [allAudiences],
  );

  const filterDefinitions = useMemo(() => {
    const filters: {
      viewKey: string;
      loading: boolean;
      filters: Record<string, any>;
    } = {
      viewKey: "audiences",
      loading: filtersLoading,
      filters: {
        parent: {
          options: memodParentModelFilterConfig,
          title: "Parent model",
        },
        source: {
          options: memodSourceFilterConfig,
          title: "Source",
        },
        created: { options: memodCreatedByFilterConfig, title: "Created by" },
        recently_created: {
          options: [
            { id: "24h", label: "Last 24 hours", order: 0 },
            { id: "7d", label: "Last 7 days", order: 1 },
            { id: "30d", label: "Last 30 days", order: 2 },
          ],
          title: "Recently created",
        },
        label: { options: memodLabelFilterConfig, title: "Labels" },
        duplicate_keys: {
          options: [
            { id: "true", label: "True" },
            { id: "false", label: "False" },
          ],
          title: "Duplicate primary keys",
        },
        subsets: {
          options: memodSubsetFilterConfig,
          title: "Subsets",
        },
        inactive_syncs: {
          options: [
            { id: "true", label: "True" },
            { id: "false", label: "False" },
          ],
          title: "Inactive syncs",
        },
        unhealthy_syncs: {
          options: [
            { id: "true", label: "True" },
            { id: "false", label: "False" },
          ],
          title: "Unhealthy syncs",
        },
        recently_synced: {
          options: [
            { id: "24h", label: "Last 24 hours", order: 0 },
            { id: "7d", label: "Last 7 days", order: 1 },
            { id: "30d", label: "Last 30 days", order: 2 },
          ],
          title: "Recently synced",
        },
      },
    };

    if (allAudiences?.some((f) => f.parent?.matchboosting_enabled)) {
      filters.filters.matchbooster_enabled = {
        canSearch: false,
        options: [
          { id: "true", label: "True" },
          { id: "false", label: "False" },
        ],
        title: "Match Boosted",
      };
    }

    return filters;
  }, [
    memodSubsetFilterConfig,
    memodLabelFilterConfig,
    memodCreatedByFilterConfig,
    memodParentModelFilterConfig,
  ]);

  const {
    result: { state: filterState, data: filterData },
    state: { creatingView, selectedView, viewNotSaved, views, updatingView },
    actions: {
      createView,
      deleteView,
      selectView,
      updateCurrentView,
      resetViewFilters,
      clearFilters,
    },
  } = useFilters(filterDefinitions);

  const hasuraFilters = useMemo(() => {
    if (!allAudiences?.length) {
      return {};
    }

    const folderFilter = (): SegmentsBoolExp => {
      const folderIds: string[] = [];

      if (selectedFolder?.id) {
        folderIds.push(selectedFolder.id);
      }
      if (selectedFolder?.flattenedChildren?.length) {
        folderIds.push(...selectedFolder.flattenedChildren.map((f) => f.id));
      }

      if (folderIds.length) {
        return {
          folder_id: { _in: folderIds },
        };
      } else {
        return {};
      }
    };

    const matchboosterFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.matchbooster_enabled)) {
        if (filterState.matchbooster_enabled.selected.length !== 1) {
          return {};
        }
        if (filterState.matchbooster_enabled.selected[0]?.id === "true") {
          return {
            parent: { matchboosting_enabled: { _eq: true } },
          };
        }
        if (filterState.matchbooster_enabled.selected[0]?.id === "false") {
          return {
            parent: {
              matchboosting_enabled: { _eq: false },
            },
          };
        }
      }
      return {};
    };

    const inactiveSyncsFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.inactive_syncs)) {
        if (filterState.inactive_syncs.selected.length !== 1) {
          return {};
        }
        if (filterState.inactive_syncs.selected[0]?.id === "true") {
          return inactiveSyncsBoolExp;
        }
        if (filterState.inactive_syncs.selected[0]?.id === "false") {
          return {
            _not: inactiveSyncsBoolExp,
          };
        }
      }
      return {};
    };

    const unhealthySyncsFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.unhealthy_syncs)) {
        if (filterState.unhealthy_syncs.selected.length !== 1) {
          return {};
        }
        if (filterState.unhealthy_syncs.selected[0]?.id === "true") {
          return {
            syncs: { health: { _eq: "unhealthy" } },
          };
        }
        if (filterState.unhealthy_syncs.selected[0]?.id === "false") {
          return {
            _not: { syncs: { health: { _eq: "unhealthy" } } },
          };
        }
      }
      return {};
    };

    const recentlySyncedFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.recently_synced)) {
        if (filterState.recently_synced.selected.length !== 1) {
          return {};
        }

        const timestamp = new Date();

        if (filterState.recently_synced.selected.find((f) => f.id === "30d")) {
          timestamp.setDate(timestamp.getDate() - 30);
        } else if (
          filterState.recently_synced.selected.find((f) => f.id === "7d")
        ) {
          timestamp.setDate(timestamp.getDate() - 7);
        } else if (
          filterState.recently_synced.selected.find((f) => f.id === "24h")
        ) {
          timestamp.setHours(timestamp.getHours() - 24);
        }

        return {
          syncs: { last_run_at: { _gte: timestamp.toISOString() } },
        };
      }
      return {};
    };

    const recentlyCreatedFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.recently_created)) {
        if (filterState.recently_created.selected.length !== 1) {
          return {};
        }

        const timestamp = new Date();

        if (filterState.recently_created.selected.find((f) => f.id === "30d")) {
          timestamp.setDate(timestamp.getDate() - 30);
        } else if (
          filterState.recently_created.selected.find((f) => f.id === "7d")
        ) {
          timestamp.setDate(timestamp.getDate() - 7);
        } else if (
          filterState.recently_created.selected.find((f) => f.id === "24h")
        ) {
          timestamp.setHours(timestamp.getHours() - 24);
        }

        return {
          created_at: { _gte: timestamp.toISOString() },
        };
      }
      return {};
    };

    const subsetsFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.subsets)) {
        return {
          subsets: {
            subset_value_id: {
              _in: filterState.subsets.selected.map((f) => f.id),
            },
          },
        };
      }
      return {};
    };

    const parentFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.parent)) {
        return {
          visual_query_parent_id: {
            _in: filterState.parent.selected.map((f) => f.id),
          },
        };
      }
      return {};
    };

    const sourceFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.source)) {
        return {
          connection_id: {
            _in: filterState.source.selected.map((f) => f.id),
          },
        };
      }
      return {};
    };

    const duplicateKeyFilter = (): SegmentsBoolExp => {
      if (isFilterActive(filterState.duplicate_keys)) {
        if (filterState.duplicate_keys.selected.length !== 1) {
          return {};
        }
        if (
          filterState.duplicate_keys.selected.map((f) => f.id)[0] === "true"
        ) {
          return {
            last_run_duplicate_primary_keys: { _gt: 0 },
            destination_instances: {
              last_run_planner_type: { _neq: "all", _is_null: false },
            },
          };
        }
        if (
          filterState.duplicate_keys.selected.map((f) => f.id)[0] === "false"
        ) {
          return {
            _and: [
              {
                _or: [
                  { last_run_duplicate_primary_keys: { _eq: 0 } },
                  { last_run_duplicate_primary_keys: { _is_null: true } },
                  {
                    _not: {
                      destination_instances: {
                        last_run_planner_type: { _neq: "all", _is_null: false },
                      },
                    },
                  },
                ],
              },
            ],
          };
        }
      }
      return {};
    };

    const createdFilter = () => {
      if (isFilterActive(filterState.created)) {
        return {
          _or: [
            {
              created_by: {
                _in: filterState.created.selected.map((f) => f.id),
              },
            },
            {
              created_by: { _is_null: true },
            },
          ],
        };
      }
      return {};
    };

    const andFilter = () => {
      const filter = {
        ...parentFilter(),
        ...sourceFilter(),
      };
      if (Object.keys(filter).length) {
        return {
          _and: [filter],
        };
      }
      return {};
    };

    const hasuraFilters: SegmentsBoolExp = {
      ...subsetsFilter(),
      ...andFilter(),
      ...folderFilter(),
      ...duplicateKeyFilter(),
      ...labelFilter(filterState.label),
      ...createdFilter(),
      ...recentlyCreatedFilter(),
      ...matchboosterFilter(),
      ...inactiveSyncsFilter(),
      ...unhealthySyncsFilter(),
      ...recentlySyncedFilter(),
    };

    return hasuraFilters;
  }, [selectedFolder, filterState]);

  const hasuraFiltersWithSearch = useMemo(() => {
    if (debouncedSearch) {
      return { ...hasuraFilters, name: { _ilike: `%${debouncedSearch}%` } };
    } else {
      return hasuraFilters;
    }
  }, [hasuraFilters, debouncedSearch]);

  const audiencesQuery = useAudiencesQuery(
    {
      filters: { ...hasuraFiltersWithSearch, query_type: { _eq: "visual" } },
      offset,
      limit,
      orderBy,
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
      meta: { isFastEndpoint: true },
    },
  );

  const audiences = audiencesQuery.data?.segments;
  const filteredAudienceCount =
    audiencesQuery.data?.segments_aggregate?.aggregate?.count ?? 0;

  const bulkDeleteAudiences = async () => {
    const count = selectedRows.length;
    const pluralizedLabel = pluralize("audience", count);

    try {
      await bulkDelete({ where: { id: { _in: selectedRows.map(String) } } });

      toast({
        id: "bulk-delete-audiences",
        title: `Deleted ${count} ${pluralizedLabel}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      if (
        error.message.startsWith("Foreign key violation") &&
        error.message.includes("priority_list")
      ) {
        const message =
          count > 1
            ? `One or more audiences are being used in a priority list. Remove the audiences from the priority list(s) and try again.`
            : `The audience is being used in a priority list. Remove the audience from the priority list and try again.`;

        toast({
          id: "bulk-delete-audiences",
          title: `Failed to delete ${pluralizedLabel}`,
          message,
          variant: "error",
        });

        setConfirmingDelete(false);
      } else if (error.message.startsWith("Foreign key violation")) {
        toast({
          id: "bulk-delete-audiences",
          title: `Failed to delete ${pluralizedLabel}`,
          message:
            "One or more audiences cannot be deleted because other resources rely on them.",
          variant: "error",
        });
      } else {
        toast({
          id: "bulk-delete-audiences",
          title: `Failed to delete ${pluralizedLabel}`,
          variant: "error",
        });

        Sentry.captureException(error);
      }
    }
  };

  const onRowClick = useCallback(
    ({ id }, event) => {
      openUrl(`/audiences/${id}`, navigate, event);
    },
    [navigate],
  );

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  return (
    <>
      <PermissionProvider
        permission={{
          v1: { resource: "audience", grant: "update" },
        }}
      >
        <Page
          header={
            <Column
              borderBottom="1px solid"
              borderColor="base.border"
              pt={6}
              pb={0}
            >
              <Row
                justify="space-between"
                borderBottom="1px solid"
                borderColor="base.border"
                px={6}
                pb={6}
              >
                <Heading size="xl">{header}</Heading>
                <Row flexShrink={0} gap={3} justify="right">
                  {selectedRows.length > 0 && (
                    <Row align="center" flexShrink={0} gap={2}>
                      <Text>{`${pluralize(
                        "audience",
                        selectedRows.length,
                        true,
                      )} selected`}</Text>
                      <Menu>
                        <MenuButton>Actions</MenuButton>
                        <MenuList>
                          <PermissionedMenuItem
                            permission={{
                              v1: {
                                resource: "audience",
                                grant: "update",
                              },
                            }}
                            icon={FolderIcon}
                            onClick={() => {
                              setMovingToFolder(true);
                            }}
                          >
                            Move to folder
                          </PermissionedMenuItem>
                          <PermissionedMenuItem
                            permission={{
                              v1: {
                                resource: "audience",
                                grant: "update",
                              },
                            }}
                            icon={TagIcon}
                            onClick={() => {
                              setAddingLabels(true);
                            }}
                          >
                            Add labels
                          </PermissionedMenuItem>
                          <MenuDivider />
                          <PermissionedMenuItem
                            permission={{
                              v1: {
                                resource: "audience",
                                grant: "delete",
                              },
                            }}
                            icon={DeleteIcon}
                            variant="danger"
                            onClick={() => {
                              setConfirmingDelete(true);
                            }}
                          >
                            Delete
                          </PermissionedMenuItem>
                        </MenuList>
                      </Menu>
                    </Row>
                  )}
                  <PermissionedLinkButton
                    href={`/audiences/new${
                      selectedFolder ? `?folder=${selectedFolder.id}` : ""
                    }`}
                    permission={{
                      v1: { resource: "audience", grant: "create" },
                    }}
                    variant="primary"
                    onClick={() => {
                      analytics.track("Add Audience Clicked");
                    }}
                  >
                    Add audience
                  </PermissionedLinkButton>
                </Row>
              </Row>
              <Column px={2}>
                <AudienceStats filter="all" view="audiences" />
              </Column>
            </Column>
          }
          sidebar={
            <PageSidebar
              header={
                <SearchInput
                  placeholder="Search audiences..."
                  value={search ?? ""}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              }
            >
              <Folders
                folders={folders ?? []}
                loading={loadingFolders}
                audienceCount={audienceCount}
                audiencesRootName="All audiences"
                refetchFolders={refetchFolders}
                rootFolder="audiences"
                selectedFolder={selectedFolder}
                setRootFolder={() => undefined}
                setSelectedFolder={setSelectedFolder}
                viewType="models"
              />
              <Filters
                clearFilters={clearFilters}
                createView={createView}
                creatingView={creatingView}
                deleteView={deleteView}
                filters={filterData}
                resetFilters={resetViewFilters}
                resource="audience"
                selectView={selectView}
                selectedView={selectedView}
                updateCurrentView={updateCurrentView}
                updatingView={updatingView}
                viewNotSaved={viewNotSaved}
                views={views}
              />
            </PageSidebar>
          }
          title="Audiences"
        >
          <Table
            top={`${headerHeight}px`}
            rowHeight="80px"
            columns={[
              {
                name: "Name",
                max: "1fr",
                headerSx: { position: "sticky" },
                cell: ({ name, description, labels, splits_aggregate }) => (
                  <Column gap={1} overflow="hidden">
                    <TextWithTooltip message={name} fontWeight="medium">
                      {name ?? "Private model"}
                    </TextWithTooltip>
                    {description && (
                      <TextWithTooltip color="text.secondary">
                        {description}
                      </TextWithTooltip>
                    )}
                    {!isEmpty(labels) || splits_aggregate?.aggregate?.count ? (
                      <Row align="center" flexWrap="wrap" gap={1}>
                        {splits_aggregate?.aggregate?.count &&
                        splits_aggregate.aggregate.count > 0 ? (
                          <Badge size="sm">
                            {splits_aggregate.aggregate.count} splits
                          </Badge>
                        ) : null}
                        <Labels labels={labels} />
                      </Row>
                    ) : null}
                  </Column>
                ),
              },
              {
                name: "Size",
                max: "max-content",
                headerSx: { position: "sticky" },
                cell: ({ query_runs }) => {
                  const queryRun = query_runs?.[0];
                  const querySize = queryRun
                    ? abbreviateNumber(queryRun.size)
                    : "--";

                  return (
                    <Tooltip
                      message={
                        queryRun ? (
                          <Column gap={1}>
                            <Text color="white">
                              Calculated:{" "}
                              {format(queryRun.created_at, "MMM d · p")}
                            </Text>

                            <Text color="gray.400">
                              Last sync size: {querySize}
                            </Text>
                          </Column>
                        ) : (
                          <Text color="white">Unknown audience size</Text>
                        )
                      }
                    >
                      <Row align="baseline" gap={2}>
                        <Box fontSize="20px">
                          <AudienceIcon color="text.secondary" />
                        </Box>
                        <Text>{querySize}</Text>
                      </Row>
                    </Tooltip>
                  );
                },
              },
              {
                name: "Syncing to",
                min: "200px",
                max: "max-content",
                headerSx: { position: "sticky" },
                disabled: ({ syncs }) => Boolean(syncs.length),
                cell: ({ syncs }) => <SyncsCell syncs={syncs} />,
              },
              {
                name: "Last synced",
                max: "max-content",
                headerSx: { position: "sticky" },
                cell: ({ syncs }) => {
                  const syncRequests = syncs.flatMap(
                    (sync) => sync.sync_requests,
                  );

                  const sorted = lodashOrderBy(
                    syncRequests.filter(
                      (sr) => sr.status_computed === "success",
                    ),
                    ["finished_at"],
                    ["desc"],
                  );

                  return sorted[0]?.finished_at
                    ? formatFriendlyDistanceToNow(
                        sorted[0].finished_at,
                      ).toLowerCase()
                    : "--";
                },
              },
              LastUpdatedColumn,
            ]}
            data={audiences}
            error={Boolean(audiencesQuery.error)}
            loading={audiencesQuery.isFetching || isSearchPending()}
            placeholder={{ custom: <EmptyPlaceholder /> }}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            onSelect={onRowSelect}
            sortOptions={sortOptions}
          />

          <Row justify="flex-end" width="100%" mt={4} px={4}>
            <Pagination
              label="audiences"
              count={filteredAudienceCount}
              page={page}
              setPage={setPage}
              rowsPerPage={50}
            />
          </Row>
        </Page>
      </PermissionProvider>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="audience"
        content={
          <Column gap={2}>
            <Paragraph>
              Are you sure you want to delete {selectedRows.length}{" "}
              {pluralize("audience", selectedRows.length)}? You will not be able
              to undo this.
            </Paragraph>

            <Paragraph>
              All syncs associated with the selected audiences will also be
              deleted.
            </Paragraph>
          </Column>
        }
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteAudiences}
      />
      <EditLabelModal
        rows={selectedRows.length}
        isOpen={addingLabels}
        resourceType={ResourceType.Model}
        onClose={() => setAddingLabels(false)}
        onSubmit={async (labels) => {
          await addLabels({ ids: selectedRows.map(String), labels });
          onRowSelect([]);
        }}
      />

      {movingToFolder && (
        <MoveFolder
          folder={null}
          folderType="audiences"
          modelIds={selectedRows.map((id) => id.toString())}
          viewType="models"
          onClose={() => {
            setMovingToFolder(false);
            audiencesQuery.refetch();
            onRowSelect([]);
          }}
        />
      )}
    </>
  );
};

const Loader = () => {
  const { resources } = useUser();
  const { data: entitlementsData } = useEntitlements(true, true);
  const { schemaV2 } = useFlags();
  const audiencesEnabled = entitlementsData.entitlements.audiences;

  if (!audiencesEnabled) {
    return <AudiencesDemo />;
  }

  if (resources?.audience) {
    return <Audiences />;
  }

  const pageAlertProps = {
    source: {
      title: "First, you need to configure a data source",
      message:
        "Hightouch must be connected to least one data source before you can create an audience. Your source can be a data warehouse, spreadsheet, or other data system.",
      button: (
        <PermissionedLinkButton
          href="/sources/new"
          permission={{ v2: { resource: "source", grant: "can_create" } }}
          variant="primary"
        >
          Configure data source
        </PermissionedLinkButton>
      ),
      href: "/sources/new",
    },
    parentModel: {
      title: "First, you need to configure a parent model",
      message:
        "A parent model typically defines users, companies, or anything else that represents a customer. Later, when creating an audience, you'll filter a parent model to include only customers who have certain attributes or have performed specific actions.",
      button: (
        <PermissionedLinkButton
          permission={{
            v1: {
              resource: "audience_schema",
              grant: "create",
            },
          }}
          href={schemaV2 ? `/schema-v2/new` : "/schema/parent-models/new"}
          variant="primary"
        >
          Configure parent model
        </PermissionedLinkButton>
      ),
      href: schemaV2 ? `/schema-v2/new` : "/schema/parent-models/new",
    },
  };

  let props;

  if (!resources?.source) {
    props = pageAlertProps.source;
  } else if (!resources?.parentModel) {
    props = pageAlertProps.parentModel;
  }

  return (
    <Page
      fullWidth
      outsideTopbar={props ? <PageAlert {...props} /> : null}
      title="Audiences"
    >
      <Row align="center" justify="space-between" mb={8}>
        <Heading isTruncated size="xl">
          Audiences
        </Heading>
      </Row>

      <Placeholder
        content={{
          image: audiencePlaceholder,
          title: "No audiences in this workspace",
          body: "An audience is a list of customers defined using a visual segment builder. This feature allows marketers to query the data warehouse and define experiments tailored to their campaigns using a no-code interface that doesn't require SQL knowledge.",
          button: props ? null : (
            <PermissionedLinkButton
              href="/audiences/new"
              permission={{ v1: { resource: "audience", grant: "create" } }}
              variant="primary"
            >
              Add audience
            </PermissionedLinkButton>
          ),
        }}
      />
    </Page>
  );
};

export default Loader;

import { useOutletContext } from "src/router";
import { DecisionEngine, OutletContext } from "..";
import {
  Text,
  Column,
  Row,
  Heading,
  Button,
  ButtonGroup,
  useDisclosure,
  Spinner,
  ConfirmationDialog,
  Paragraph,
  Box,
  useClipboard,
  CheckIcon,
  CopyIcon,
  Tooltip,
  EmptyState,
  Select,
} from "@hightouchio/ui";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Controller } from "react-hook-form";
import {
  useUpdateDecisionEngineFlowMutation,
  useResetStateMutation,
  useDecisionEngineTableNamesQuery,
  useUpsertExperimentsMutation,
  useFeatureSqlBackgroundQuery,
  useRunSqlResultQuery,
  useExperimentsQuery,
  useResetAttributionStateMutation,
} from "src/graphql";
import { Editor } from "src/components/editor";
import json5 from "json5";
import { DecisionEngineFlowConfig } from "@hightouch/lib/customer-data/decision-engine/types";
import { useEffect, useState } from "react";
import { FormField, TextInput, NumberInput } from "@hightouchio/ui";
import { Card } from "src/components/card";

export const Flows = () => {
  const { engine } = useOutletContext<OutletContext>();

  if (!engine.flows.length) {
    return <EmptyState title="No flows" message="" />;
  }

  return (
    <Column gap={6}>
      {engine.flows.sort().map((flow) => (
        <Flow key={flow.id} flow={flow} />
      ))}
    </Column>
  );
};

const TableReference = ({
  tableName,
  label,
}: {
  tableName: string;
  label: string;
}) => {
  const { onCopy, hasCopied } = useClipboard(tableName);

  return (
    <Row align="center" justify="space-between" w="100%" gap={4}>
      <Row gap={2} align="center">
        <Text>{label}</Text>
      </Row>

      <Tooltip placement="left" message="Copy to clipboard" openSpeed="slow">
        <Row
          onClick={onCopy}
          align="center"
          cursor="pointer"
          py={1}
          px={2}
          bg="gray.100"
          borderRadius="md"
          _hover={{
            bg: "gray.200",
            svg: {
              display: "inline-block",
            },
          }}
          gap={1}
          overflow="hidden"
        >
          {hasCopied ? (
            <CheckIcon />
          ) : (
            <Box as={CopyIcon} display="none" flexShrink={0} />
          )}
          <Box
            fontFamily="mono"
            color="text.secondary"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
            dir="rtl"
          >
            {tableName}
          </Box>
        </Row>
      </Tooltip>
    </Row>
  );
};

const Flow = ({ flow }: { flow: DecisionEngine["flows"][0] }) => {
  const { engine } = useOutletContext<OutletContext>();
  const {
    isOpen: resetStateOpen,
    onOpen: onResetStateOpen,
    onClose: onResetStateClose,
  } = useDisclosure();
  const {
    isOpen: resetAttributionOpen,
    onOpen: onResetAttributionOpen,
    onClose: onResetAttributionClose,
  } = useDisclosure();

  const updateMutation = useUpdateDecisionEngineFlowMutation();

  const resetMutation = useResetStateMutation();
  const resetAttributionMutation = useResetAttributionStateMutation();

  const tableNamesQuery = useDecisionEngineTableNamesQuery({
    decisionEngineId: engine.id,
  });

  const flowTables = tableNamesQuery.data?.getDecisionEngineTableNames.find(
    (tables) => tables.flowId === flow.id,
  );

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const config = data.config;
      const timing = data.timing;

      if (data.banditConfig !== "") {
        config.bandits = json5.parse(data.banditConfig);
      }

      if (data.actionModelConfig !== "") {
        config.action_model_configuration = json5.parse(data.actionModelConfig);
      }

      await updateMutation.mutateAsync({
        id: flow.id,
        input: {
          config: {
            ...config,
            flow_start_date: data.config.flow_start_date
              ? new Date(data.config.flow_start_date).toISOString()
              : null,
          },
          timing: {
            ...timing,
            discount_rate_override: data.discountRateOverride,
          },
        },
      });
    },
    values: {
      config: flow.config as DecisionEngineFlowConfig,
      timing: flow.timing,
      banditConfig: flow.config.bandits
        ? JSON.stringify(flow.config.bandits, null, 2)
        : "",
      actionModelConfig: flow.config.action_model_configuration
        ? JSON.stringify(flow.config.action_model_configuration, null, 2)
        : "",
      discountRateOverride: flow.timing?.discount_rate_override,
    },
  });

  function formatDateToDatetimeLocal(date: Date) {
    const pad = (num) => String(num).padStart(2, "0");

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  return (
    <>
      <Column>
        <Heading>{flow.name}</Heading>
        <Text size="sm" color="text.secondary">
          {flow.id}
        </Text>
      </Column>

      <Card gap={4}>
        <Heading>Warehouse Tables</Heading>
        {tableNamesQuery.isLoading ? (
          <Spinner />
        ) : flowTables ? (
          <Column gap={2}>
            <TableReference
              label="Recommendations Table"
              tableName={flowTables.qualifiedRecommendationsTableName}
            />
            <TableReference
              label="Attribution Table"
              tableName={flowTables.qualifiedAttributionTableName}
            />
          </Column>
        ) : (
          <Text color="text.secondary">No table information available</Text>
        )}
      </Card>

      <Form form={form}>
        <Card
          footer={
            <Row justify="space-between" w="100%">
              <FormActions />
              <ButtonGroup>
                {flow.status === "TRAINING" && (
                  <Button
                    size="lg"
                    onClick={async () => {
                      await updateMutation.mutate({
                        id: flow.id,
                        input: {
                          status: "READY",
                        },
                      });
                    }}
                    isLoading={updateMutation.isLoading}
                  >
                    Ready agent
                  </Button>
                )}
                <Button
                  size="lg"
                  variant="danger"
                  onClick={onResetStateOpen}
                  isDisabled={resetMutation.isLoading}
                >
                  Reset Warehouse state
                </Button>
                <Button
                  size="lg"
                  variant="danger"
                  onClick={onResetAttributionOpen}
                  isDisabled={resetAttributionMutation.isLoading}
                >
                  Reset Attribution state
                </Button>
              </ButtonGroup>
            </Row>
          }
          gap={4}
        >
          <Heading>Configuration</Heading>
          <Controller
            name="config.flow_start_date"
            control={form.control}
            render={({ field }) => (
              <FormField label="Start date">
                <TextInput
                  type="datetime-local"
                  {...field}
                  value={
                    field.value
                      ? formatDateToDatetimeLocal(new Date(field.value))
                      : ""
                  }
                />
              </FormField>
            )}
          />

          <Controller
            name="config.attribution.window.unit"
            render={({ field }) => (
              <FormField label="Attribution Window Unit">
                <Select
                  {...field}
                  options={["minute", "hour", "day", "week", "month", "year"]}
                  optionValue={(value) => value}
                  optionLabel={(value) => value}
                />
              </FormField>
            )}
          />
          <Controller
            name="config.attribution.window.value"
            render={({ field }) => (
              <FormField label="Attribution Window Value">
                <NumberInput {...field} />
              </FormField>
            )}
          />
          <Controller
            name="banditConfig"
            render={({ field }) => (
              <FormField
                label="Bandits"
                description={`Defaults to {"default": {"algorithm": "squarecb", "gamma_scale": 2.0, "gamma_exp": 0.7, "new_arm_min_serves": 10_000}}`}
              >
                <Column overflow="hidden" flex={1} maxHeight="400px">
                  <Editor language="json" bg="base.background" {...field} />
                </Column>
              </FormField>
            )}
          />
          <Controller
            name="actionModelConfig"
            render={({ field }) => (
              <FormField label="Action model configuration">
                <Column overflow="hidden" flex={1} maxHeight="400px">
                  <Editor language="json" bg="base.background" {...field} />
                </Column>
              </FormField>
            )}
          />
          <Controller
            name="discountRateOverride"
            render={({ field }) => (
              <FormField
                label="Discount rate override"
                description="Scheduling discount rate override, expressed as a decimal between 0 and 1"
              >
                <NumberInput {...field} min={0} max={1} />
              </FormField>
            )}
          />
        </Card>
      </Form>

      <Experiments flowId={flow.id} />

      <FeatureSql flowId={flow.id} />

      <ConfirmationDialog
        isOpen={resetStateOpen}
        title="Reset state"
        confirmButtonText="Reset state"
        variant="danger"
        onClose={onResetStateClose}
        onConfirm={async () => {
          resetMutation.mutate({
            decisionEngineId: engine.id,
            flowId: flow.id,
          });
        }}
      >
        <Paragraph>
          Are you sure you want to reset the state? You won't be able to undo
          this.
        </Paragraph>
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={resetAttributionOpen}
        title="Reset attribution state"
        confirmButtonText="Reset attribution state"
        variant="danger"
        onClose={onResetAttributionClose}
        onConfirm={async () => {
          resetAttributionMutation.mutate({
            decisionEngineId: engine.id,
            flowId: flow.id,
          });
        }}
      >
        <Paragraph>
          Are you sure you want to reset the attribution state? You won't be
          able to undo this.
        </Paragraph>
      </ConfirmationDialog>
    </>
  );
};

const Experiments = ({ flowId }: { flowId: string }) => {
  const experimentsQuery = useExperimentsQuery({
    decisionEngineFlowId: flowId,
  });

  const mutation = useUpsertExperimentsMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutateAsync({
        experiments: [
          {
            name: "uniform",
            decision_engine_flow_id: flowId,
            audience_percent: data.uniform_audience_percent,
          },
          {
            name: "customer_managed",
            decision_engine_flow_id: flowId,
            audience_percent: data.customer_managed_audience_percent,
          },
        ],
      });
    },
    values: {
      uniform_audience_percent:
        experimentsQuery.data?.decision_engine_flow_experiments.find(
          (e) => e.name === "uniform",
        )?.audience_percent ?? 0,
      customer_managed_audience_percent:
        experimentsQuery.data?.decision_engine_flow_experiments.find(
          (e) => e.name === "customer_managed",
        )?.audience_percent ?? 0,
    },
  });

  return (
    <Form form={form}>
      <Card footer={<FormActions />} gap={4}>
        <Column gap={4}>
          <Heading>Experiments</Heading>

          <Controller
            name="uniform_audience_percent"
            render={({ field }) => (
              <FormField
                label="Uniform"
                description="Percent of audience for uniform experiment, expressed as a decimal between 0 and 1"
              >
                <NumberInput {...field} min={0} max={1} />
              </FormField>
            )}
          />

          <Controller
            name="customer_managed_audience_percent"
            render={({ field }) => (
              <FormField
                label="Customer Managed"
                description="Percent of audience for customer managed, expressed as a decimal between 0 and 1"
              >
                <NumberInput {...field} min={0} max={1} />
              </FormField>
            )}
          />
        </Column>
      </Card>
    </Form>
  );
};

const FeatureSql = ({ flowId }: { flowId: string }) => {
  const [runQuery, setRunQuery] = useState<null | { runSql: boolean }>(null);
  const [features, setFeatures] = useState<any[] | null>(null);
  const [shouldPoll, setShouldPoll] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { data } = useFeatureSqlBackgroundQuery(
    {
      flowId,
      runSql: runQuery?.runSql ?? false,
    },
    {
      enabled: Boolean(runQuery),
      select: (data) => data.featureSqlBackground,
    },
  );

  useEffect(() => {
    if (data?.jobId) {
      setShouldPoll(true);
    }
  }, [data?.jobId]);

  useRunSqlResultQuery(
    {
      jobId: String(data?.jobId),
      page: 0,
    },
    {
      enabled: Boolean(data?.jobId),
      refetchInterval: shouldPoll ? 1000 : 0,
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }

        if (
          data.backgroundPreviewQueryResult.__typename === "FailedQueryResponse"
        ) {
          setError(data.backgroundPreviewQueryResult.error);
        } else {
          setFeatures(data.backgroundPreviewQueryResult.rows);
        }

        setShouldPoll(false);
      },
    },
  );

  return (
    <Card
      footer={
        <Row gap={2}>
          <Button
            onClick={() => {
              setRunQuery({ runSql: true });
            }}
          >
            Run Query
          </Button>
          <Button
            onClick={() => {
              setRunQuery({ runSql: false });
            }}
          >
            Get SQL
          </Button>
        </Row>
      }
    >
      <Column gap={4}>
        <Column>
          <Heading>Feature SQL</Heading>
        </Column>
        {data?.querySql && (
          <Column maxHeight="400px">
            <Editor
              language="sql"
              bg="base.background"
              value={data?.querySql}
            />
          </Column>
        )}
        {error && (
          <Column>
            <Text>{error}</Text>
          </Column>
        )}

        <Column maxHeight="400px">
          {shouldPoll ? (
            <Spinner />
          ) : features ? (
            <Editor
              language="json"
              bg="base.background"
              value={JSON.stringify(features, null, 2)}
            />
          ) : null}
        </Column>
      </Column>
    </Card>
  );
};

import { useMemo } from "react";
import { Column, EmptyState } from "@hightouchio/ui";
import searchPlaceholder from "src/assets/placeholders/search.svg";
import { useLocation, useSearchParams } from "src/router";

export const EmptyPlaceholder = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const message = useMemo(() => {
    if (location.search === "") {
      return "No audiences to display";
    }

    if (searchParams.get("recently_created") === "30d") {
      return "No audiences have been created in the past month";
    }

    if (searchParams.get("recently_synced") === "30d") {
      return "No audiences have been synced in the past month";
    }

    if (searchParams.get("unhealthy_syncs") === "true") {
      return "No audiences with unhealthy syncs to display";
    }

    if (searchParams.get("inactive_syncs") === "true") {
      return "No inactive audiences to display";
    }

    return "No audiences to display";
  }, [location.search, searchParams.get]);

  return (
    <Column padding={8}>
      <EmptyState
        imageUrl={searchPlaceholder}
        title="No audiences found"
        message={message}
      />
    </Column>
  );
};

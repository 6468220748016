import { FC, ReactNode } from "react";

import { Card } from "src/components/card";

type Props = {
  heading?: ReactNode;
  subheading?: ReactNode;
  children?: ReactNode;
  rightContent?: ReactNode;
  isOptional?: boolean;
};

export const FormSection: FC<Readonly<Props>> = ({
  heading,
  subheading,
  children,
  rightContent,
  isOptional,
}) => {
  return (
    <Card
      gap={6}
      heading={heading}
      subheading={subheading}
      rightContent={rightContent}
      sx={
        isOptional
          ? {
              "h3::after": {
                content: "' (optional)'",
                color: "text.secondary",
                fontWeight: "normal",
              },
            }
          : undefined
      }
    >
      {children}
    </Card>
  );
};

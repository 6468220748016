import { FC } from "react";

import { Column } from "@hightouchio/ui";
import { Navigate, Outlet, useParams } from "src/router";

import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { DetailPage } from "src/components/layout";
import { SyncName } from "src/pages/syncs/sync/components/sync-name";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import {
  ResourceToPermission,
  SyncRunQuery,
  useLatestJourneyVersionFromDestinationInstanceIdQuery,
  useSyncQuery,
  useSyncRunQuery,
} from "src/graphql";
import { PageSpinner } from "src/components/loading";
import { getSyncRunOperations } from "src/utils/syncs";
import { isSyncMatchBoosted } from "src/pages/syncs/sync/matchbooster";
import { QueryType } from "src/types/models";
import { useFolder } from "src/components/folders/use-folder";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { Warning } from "src/components/warning";
import { Sync } from "src/pages/syncs/sync/utils/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useTabState } from "src/components/route-tabs";
import { LegacyMetadataRunStatusHeader } from "./summary/run-status-header";
import { useResourcePermission } from "src/components/permission/use-resource-permission";

export type Context = {
  sync: Sync;
  syncRequest: NonNullable<SyncRunQuery["sync_requests_by_pk"]>;
};

export const SyncRunWrapper: FC = () => {
  const { run_id: runId, sync_id: syncId } = useParams<{
    run_id: string;
    sync_id: string;
  }>();

  const {
    data: sync,
    error: syncError,
    isLoading: syncIsLoading,
  } = useSyncQuery(
    {
      id: syncId ?? "",
    },
    {
      enabled: Boolean(syncId),
      select: (data) => data.syncs[0],
    },
  );

  const {
    data: syncRequest,
    error: syncRequestError,
    isLoading: syncRequestLoading,
  } = useSyncRunQuery(
    {
      syncRequestId: runId ?? "",
    },
    {
      enabled: Boolean(runId),
      select: (data) => data.sync_requests_by_pk,
      refetchInterval: (syncRequest) => {
        if (syncRequest?.finished_at) {
          // Stop polling if this sync request is finished.
          return false;
        }

        return 5000;
      },
    },
  );

  if (!syncId || !runId) {
    return <Navigate to="/syncs" />;
  }

  if (syncError || syncRequestError) {
    return (
      <Warning title="Something went wrong" subtitle="Please try again later" />
    );
  }

  if (syncIsLoading || syncRequestLoading) {
    return <PageSpinner />;
  }

  if (!sync || !syncRequest) {
    return <Warning title="Sync run not found" />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={sync.draft || false}
      resourceId={syncId}
      resourceType={ResourceToPermission.Sync}
    >
      <SyncRunLayout sync={sync} syncRequest={syncRequest} />
    </DraftProvider>
  );
};

const SyncRunLayout = ({ sync, syncRequest }: Context) => {
  const { draft, editingDraft, onViewDraft, setEditingDraft } = useDraft();
  const { appSyncRunSummaryPageEnabled, appRunDebuggerEnabled } = useFlags();

  const { isPermitted: hasDebugPermission } = useResourcePermission({
    v2: { resource: "sync", grant: "can_debug", id: sync.id },
  });

  const syncId = sync.id;
  const { segment: model, destination } = sync;
  const source = model?.connection;
  const isJourneyTriggered = model?.query_type === QueryType.JourneyNode;

  const journeyQuery = useLatestJourneyVersionFromDestinationInstanceIdQuery(
    { destination_instance_id: syncId ?? "" },
    {
      enabled: Boolean(isJourneyTriggered && syncId),
      select: (data) => data.journeys,
    },
  );
  const journey = journeyQuery.data?.[0];
  const journeyId = journey?.id;

  const {
    successful: { total: successfulRows },
    rejected: { total: rejectedRows },
  } = getSyncRunOperations({
    attempt: syncRequest.sync_attempts?.[0],
    syncRequest,
    queryRun: syncRequest.query_run,
  });

  const pendingRows = syncRequest.query_run?.pending_rows ?? 0;

  const showPendingRows = isSyncMatchBoosted(sync.config) || pendingRows > 0;

  const folder = useFolder({
    folderId: model?.folder?.id || null,
    folderType: model?.query_type === "visual" ? "audiences" : "models",
    viewType: "syncs",
  });

  const tabs = [
    {
      title: "Summary",
      path: "summary",
      isHidden: !appSyncRunSummaryPageEnabled,
    },
    {
      title: "Successful",
      path: "successful",
      count: appRunDebuggerEnabled ? successfulRows : undefined,
      isHidden: !hasDebugPermission,
    },
    {
      title: "Pending",
      path: "pending",
      count: pendingRows,
      isHidden:
        !showPendingRows || !hasDebugPermission || !appRunDebuggerEnabled,
    },
    {
      title: "Rejected",
      path: "rejected",
      count: appRunDebuggerEnabled ? rejectedRows : undefined,
      isHidden: !hasDebugPermission,
    },
    {
      title: "Configuration",
      path: "configuration",
    },
  ];

  const { activeTab } = useTabState(tabs, 6);

  const folderPathCrumbs =
    folder?.path.split("/").map((path) => ({
      label: path,
      link: `/syncs?folder=${folder?.id}`,
    })) ?? [];

  const crumbs: Crumb[] = [
    { label: "All syncs", link: "/syncs" },
    ...folderPathCrumbs,
    {
      label: `Sync ${sync.id}`,
      link: `/syncs/${syncId}${editingDraft ? "?editing=true" : ""}`,
    },
    {
      label: "Runs",
      link: `/syncs/${syncId}/runs${editingDraft ? "?editing=true" : ""}`,
    },
    {
      label: `Run ${syncRequest.id}`,
    },
  ];

  const context: Context = { sync, syncRequest };

  return (
    <DetailPage
      crumbs={crumbs}
      tabs={tabs}
      tabDepth={6}
      hasBottomPadding
      outsideTopbar={
        draft && (
          <EditingDraftWarning
            draft={draft}
            editingDraft={editingDraft}
            resourceType={ResourceToPermission.Sync}
            setEditingDraft={setEditingDraft}
            onViewDraft={onViewDraft}
          />
        )
      }
      title={`Run - ${model?.name ?? "Private model"} to ${
        destination?.name ??
        destination?.definition?.name ??
        "private destination"
      } - Syncs`}
      bg={activeTab?.path === "summary" ? "base.lightBackground" : undefined}
      header={
        <Column gap={4}>
          <SyncName
            // Make sure journey is loaded
            isDisabled={journeyQuery.isLoading}
            destination={destination}
            model={model}
            source={source}
            sync={sync}
            journeyId={journeyId}
          />
          <LegacyMetadataRunStatusHeader syncRequest={syncRequest} />
        </Column>
      }
    >
      <Outlet context={context} />
    </DetailPage>
  );
};

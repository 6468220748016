import { FC, useCallback, useMemo } from "react";

import { Row, Text } from "@hightouchio/ui";

import { useNavigate } from "src/router";
import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { eventWarehouseDestinationDefinitions } from "src/events/types";
import { useEventDestinationsQuery } from "src/graphql";
import { Table, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { openUrl } from "src/utils/urls";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DetailPage } from "src/components/layout";
import { PermissionedLinkButton } from "src/components/permission";
import { lodashOrderBy } from "src/ui/table/use-table-config";

enum SortKeys {
  Name = "name",
  Type = "type",
  UpdatedAt = "updated_at",
}

export const EventDestinations: FC = () => {
  const navigate = useNavigate();

  const { sortKey, sortDirection, columnSortState } = useTableConfig<{
    [key in SortKeys]: "asc" | "desc";
  }>({
    defaultSortKey: SortKeys.UpdatedAt,
    sortOptions: Object.values(SortKeys),
  });

  const {
    data: destinations,
    isFetching,
    error,
  } = useEventDestinationsQuery(
    {},
    {
      select: (data) =>
        [
          ...data.event_warehouse_destinations,
          ...data.destinations.filter((d) => d.definition.eventForwarding),
        ].map((destination) => {
          const definition: { name: string; icon: string } =
            "definition" in destination
              ? destination.definition
              : eventWarehouseDestinationDefinitions[destination.type];

          return { ...destination, definition };
        }),
    },
  );

  // Sort in memory because we don't pagination event destinations yet
  // In addition, event destinations will be a little tricky because we have two different types of destinations
  const sortedDestinations = useMemo(
    () =>
      !sortKey || !sortDirection || !destinations
        ? destinations
        : lodashOrderBy(destinations, sortKey, sortDirection),
    [destinations, sortKey, sortDirection],
  );

  const onRowClick = useCallback(
    (destination, event) =>
      openUrl(`/events/destinations/${destination.id}`, navigate, event),
    [navigate],
  );

  return (
    <DetailPage
      heading="Event destinations"
      contentFullWidth
      actions={
        <PermissionedLinkButton
          permission={{
            v1: { resource: "workspace", grant: "update" },
            v2: {
              resource: "workspace",
              grant: "can_update",
            },
          }}
          href="/events/destinations/new"
          variant="primary"
          onClick={() => {}}
        >
          Create destination
        </PermissionedLinkButton>
      }
    >
      <Table
        data={sortedDestinations}
        error={Boolean(error)}
        loading={isFetching}
        placeholder={placeholder}
        onRowClick={onRowClick}
        columns={[
          {
            name: "Name",
            ...columnSortState(SortKeys.Name),
            cell: (destination) => (
              <TextWithTooltip fontWeight="medium">
                {destination.name ?? destination.definition.name}
              </TextWithTooltip>
            ),
          },
          {
            name: "Type",
            ...columnSortState(SortKeys.Type),
            cell: ({ definition }) => (
              <Row align="center" gap={2}>
                <IntegrationIcon name={definition.name} src={definition.icon} />
                <Text fontWeight="medium">{definition.name}</Text>
              </Row>
            ),
          },
          {
            ...columnSortState(SortKeys.UpdatedAt),
            ...LastUpdatedColumn,
          },
        ]}
      />
    </DetailPage>
  );
};

const placeholder = {
  image: genericPlaceholder,
  title: "No event destinations",
  body: "Create a new event destination",
  error: "Event destinations failed to load, please try again.",
};

import { BoxProps, Heading, Row } from "@hightouchio/ui";
import { ReactNode } from "react";
import { DefaultPageContainerPadding } from "./layout/page-container";

type Props = {
  variant: "table";
  title: string;
  actions?: ReactNode;
};

const variantStyles: Record<Props["variant"], BoxProps> = {
  table: {
    px: DefaultPageContainerPadding.X,
  },
};

export const ContentHeader = ({ title, actions, variant }: Props) => {
  return (
    <Row
      {...(variant ? variantStyles[variant] : {})}
      justify="space-between"
      gap={4}
    >
      <Heading>{title}</Heading>
      {actions && (
        <Row gap={4} align="center">
          {actions}
        </Row>
      )}
    </Row>
  );
};

import { FC } from "react";

interface ErrorBarProps {
  width: number; // width of the error bar in pixels
  color: string;
}

export const ErrorBar: FC<ErrorBarProps> = ({ width, color }) => {
  if (width <= 0) {
    return null;
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="8"
      viewBox={`0 0 ${width + 1} 8`}
      fill="none"
    >
      <line x1="0.5" y1="2.1857e-08" x2="0.5" y2="4" stroke={color} />
      <line y1="4.5" x2={width} y2="4.5" stroke={color} />
      <line x1={width - 0.5} y1="4" x2={width - 0.5} y2="8" stroke={color} />
    </svg>
  );
};

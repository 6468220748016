import {
  MonitoredResourceType,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import {
  ArrowRightIcon,
  Box,
  Button,
  Column,
  Dialog,
  Heading,
  Menu,
  MenuIconButton,
  MenuItem,
  MenuList,
  OverflowMenuIcon,
  Row,
  SectionHeading,
  Skeleton,
  SkeletonBox,
  StatsItemTitle,
  Text,
} from "@hightouchio/ui";
import { FC } from "react";
import { useNavigate, useSearchParams } from "src/router";
import {
  useClearManualNotificationOverridesMutation,
  useSyncTemplateChannelOverridesQuery,
} from "src/graphql";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Table } from "src/ui/table";
import { SubscribedResourceItem } from "./subscribed-resource-item";

export const SyncNotificationOverrides: FC = () => {
  const [search] = useSearchParams();

  const navigate = useNavigate();

  const parentResourceId = search.get("parentResourceId");

  const {
    data,
    isLoading,
    isRefetching,
    refetch: refetchOverrides,
  } = useSyncTemplateChannelOverridesQuery(
    {
      parentResourceId: parentResourceId ?? "",
      // :(
      parentResourceIdBigint: parentResourceId ?? "",
      parentResourceType: ParentResourceTypes.Destination,
      childResourceType: MonitoredResourceType.Sync,
    },
    {
      enabled: !!parentResourceId,
    },
  );

  const { mutateAsync: clearManualOverrides } =
    useClearManualNotificationOverridesMutation();

  const destination = data?.destinations_by_pk;

  const onClose = () => {
    navigate(-1);
  };

  type SyncOverrideMetadata = {
    disabled: boolean;
    channel_type: string;
    id: string;
    config: any;
  };

  const overrideData =
    data?.destinations_by_pk?.syncs.flatMap((sync) => {
      const syncOverrides: SyncOverrideMetadata[] =
        data.resource_notification_channels
          .filter(
            (channel) =>
              channel.resource_id === sync.id.toString() &&
              channel.resource_type === MonitoredResourceType.Sync,
          )
          .map((channel) => ({
            ...channel.workspace_notification_channel,
            disabled: channel.disabled,
          }));

      const templateWithoutOverrides: SyncOverrideMetadata[] =
        data.notification_channel_templates
          .filter(
            (template) =>
              !syncOverrides.find(
                (override) =>
                  override.id === template.workspace_notification_channel.id,
              ),
          )
          .map((template) => ({
            ...template.workspace_notification_channel,
            disabled: template.disabled,
          }));
      const allOverrides = [...syncOverrides, ...templateWithoutOverrides];
      return allOverrides.length > 0
        ? [
            {
              id: sync.id,
              overrides: allOverrides,
              connection: {
                icon: sync.segment?.connection?.definition.icon,
                name:
                  sync.segment?.name ??
                  sync.segment?.connection?.definition?.name,
              },
            },
          ]
        : [];
    }) || [];

  return (
    <Dialog
      isOpen
      onClose={onClose}
      actions={
        <Button onClick={onClose} variant="primary">
          Done
        </Button>
      }
      variant="info"
      title="Alerting Overrides"
      width={"4xl" as any}
    >
      <Box>
        {isLoading || !destination ? (
          <Skeleton isLoading={true}>
            <Column gap={2}>
              <Row gap={2} alignContent="start" p={2}>
                <SkeletonBox width="24px" height="24px" borderRadius="full" />
                <Column gap={1}>
                  <SkeletonBox height="20px" width="180px" />
                  <SkeletonBox height="16px" width="120px" />
                </Column>
              </Row>
              <SkeletonBox height="20px" width="100%" borderRadius="md" />
              <SkeletonBox height="20px" width="100%" borderRadius="md" />
              <SkeletonBox height="20px" width="100%" borderRadius="md" />
            </Column>
          </Skeleton>
        ) : (
          <Column gap={8}>
            <Row gap={14} w="100%">
              <Column gap={2}>
                <Row>
                  <StatsItemTitle>Resource</StatsItemTitle>
                </Row>
                <Row alignItems="center" gap={2}>
                  <IntegrationIcon
                    src={destination?.definition?.icon}
                    name={destination?.name || destination?.definition?.name}
                  />
                  <Column>
                    <Text>Syncs to “{destination?.name}”</Text>
                  </Column>
                </Row>
              </Column>
              <Column gap={2}>
                <Row>
                  <StatsItemTitle>Default Alert Recipients</StatsItemTitle>
                </Row>
                <Row alignItems="center" gap={2}>
                  <Box
                    display="flex"
                    alignItems="center"
                    flexWrap="wrap"
                    gap={2}
                  >
                    {data?.notification_channel_templates.length > 0
                      ? data?.notification_channel_templates.map((template) => {
                          return (
                            <SubscribedResourceItem
                              key={template.workspace_notification_channel.id}
                              channel={template.workspace_notification_channel}
                            />
                          );
                        })
                      : "None"}
                  </Box>
                </Row>
              </Column>
            </Row>
            <Column gap={4}>
              <Row justifyContent="space-between" w="100%">
                <SectionHeading>Overridden syncs</SectionHeading>
                <Button
                  size="sm"
                  isLoading={isRefetching}
                  onClick={async () => {
                    await clearManualOverrides({
                      resourceIds:
                        data?.destinations_by_pk?.syncs.map((sync) =>
                          sync.id.toString(),
                        ) ?? [],
                      resourceType: MonitoredResourceType.Sync,
                    });
                    await refetchOverrides();
                  }}
                  variant="danger"
                >
                  Clear all overrides
                </Button>
              </Row>
              <Box>
                <Table
                  data={overrideData}
                  columns={[
                    {
                      whitespace: "unset",
                      header: () => <StatsItemTitle>Sync</StatsItemTitle>,
                      max: "180px",
                      cell: (sync) => (
                        <Row gap={2} alignItems="center" w="200px">
                          <IntegrationIcon
                            src={sync.connection.icon}
                            name={sync.connection.name}
                          />
                          {sync.connection.name}
                        </Row>
                      ),
                    },
                    {
                      max: "50px",
                      cell: () => (
                        <Row w="100%" justifyContent="center">
                          <Heading size="lg" color="text.tertiary">
                            <ArrowRightIcon />
                          </Heading>
                        </Row>
                      ),
                    },
                    {
                      whitespace: "unset",
                      max: "180px",
                      cell: () => (
                        <Row gap={2} alignItems="center" w="200px">
                          <IntegrationIcon
                            src={destination.definition.icon}
                            name={
                              destination.name || destination.definition.name
                            }
                          />
                          {destination.name}
                        </Row>
                      ),
                    },
                    {
                      header: () => (
                        <StatsItemTitle>Added recipients</StatsItemTitle>
                      ),
                      whitespace: "unset",
                      cellSx: {
                        paddingY: 2,
                        "&:last-of-type": { borderBottom: "none" },
                      },
                      cell: (sync) => (
                        <Row gap={2} display="flex" flexWrap="wrap">
                          {sync.overrides.map((channelOverride) => {
                            if (channelOverride.disabled) return null;
                            return (
                              <Box
                                display="inline-block"
                                key={channelOverride.id}
                              >
                                <SubscribedResourceItem
                                  channel={channelOverride}
                                />
                              </Box>
                            );
                          })}
                        </Row>
                      ),
                    },
                    {
                      max: "60px",
                      cell: (sync) => (
                        <Row
                          display="flex"
                          flexDir="column"
                          alignItems="end"
                          w="100%"
                        >
                          <Menu>
                            <MenuIconButton
                              icon={OverflowMenuIcon}
                              aria-label="open-sync-overrides-option"
                            />
                            <MenuList>
                              <MenuItem
                                onClick={() => navigate(`/syncs/${sync.id}`)}
                              >
                                View Sync
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  clearManualOverrides({
                                    resourceIds: [sync.id.toString()],
                                    resourceType: MonitoredResourceType.Sync,
                                  })
                                }
                              >
                                Clear overrides
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Row>
                      ),
                    },
                  ]}
                />
                <Box
                  /**
                   * Sorry, this is completely deranged, but there's no good way to conditionally
                   * disable the bottom border of the table because each cell has its own bottom border
                   * and it's rendered with display: "contents" so we can't just add a border to the row.
                   * If you have a better idea please be my guest 🙏
                   */
                  background="white"
                  mt="-1px"
                  w="100%"
                  h="1px"
                  position="relative"
                />
              </Box>
            </Column>
          </Column>
        )}
      </Box>
    </Dialog>
  );
};

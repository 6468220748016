import {
  FormField,
  FormFieldProps,
  Row,
  Switch,
  SwitchProps,
} from "@hightouchio/ui";

export type Props = Omit<FormFieldProps & SwitchProps, "children">;

export const SwitchFormField = ({ label, description, ...props }: Props) => {
  return (
    <Row justify="space-between" align="center" gap={4}>
      <FormField label={label} description={description}>
        {null}
      </FormField>
      <Switch {...props} />
    </Row>
  );
};

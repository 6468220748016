import { FC } from "react";

import { ButtonGroup } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useOutletContext } from "src/router";

import { ActionBar } from "src/components/action-bar";
import {
  DiscardButton,
  Form,
  SaveButton,
  useHightouchForm,
} from "src/components/form";
import { useUpdateIdentityResolutionGraphMutation } from "src/graphql";
import { RulesForm } from "src/pages/identity-resolution/forms";
import { RulesFormState } from "src/pages/identity-resolution/types";
import {
  checkForBlockRuleLimitDecreases,
  getRulesFormState,
  GraphVersion,
  IDRv1IdentifieRulesSchema,
  IDRv2IdentifieRulesSchema,
} from "src/pages/identity-resolution/utils";

import { OutletContext } from ".";

export const Rules: FC = () => {
  const { identifiers, graph } = useOutletContext<OutletContext>();

  const updateMutation = useUpdateIdentityResolutionGraphMutation();

  const isIDRv2 = graph.version === GraphVersion.V2;
  const values = getRulesFormState(graph);

  const form = useHightouchForm<RulesFormState>({
    onSubmit: async (data) => {
      const r = await updateMutation.mutateAsync({
        input: {
          graphId: graph.id,
          mergeRules: data.merge_rules,
          blockRules: data.block_rules,
          resolutionRules: data.resolution_rules,
          fullRerun:
            graph.version === GraphVersion.V1
              ? graph.full_rerun // v1 doesn't use this field
              : checkForBlockRuleLimitDecreases(
                  graph.block_rules,
                  data.block_rules,
                ) || graph.full_rerun, // default to current value
        },
      });
      if (r.updateIDRGraph.__typename === "UpdateIDRGraphErrorResponse") {
        throw new Error(r.updateIDRGraph.error);
      }
    },
    success: "Rules updated",
    values,
    resolver: isIDRv2
      ? yupResolver(IDRv2IdentifieRulesSchema)
      : yupResolver(IDRv1IdentifieRulesSchema),
  });

  const oldBlockRules = graph.block_rules;
  const newBlockRules = form.watch("block_rules");

  const needsFullReRun =
    graph.version === GraphVersion.V2 &&
    checkForBlockRuleLimitDecreases(oldBlockRules, newBlockRules);

  return (
    <Form form={form}>
      <RulesForm
        identifiers={identifiers}
        showVersion2Rules={isIDRv2}
        sourceType={graph.source.type}
      />

      <ActionBar>
        <ButtonGroup>
          <SaveButton
            permission={{
              v2: {
                resource: "idr",
                grant: "can_update",
                id: graph.id,
              },
            }}
            confirmation={
              needsFullReRun
                ? {
                    title: "Graph needs to be re-run",
                    message:
                      "Reducing the limit on an existing identifier or adding a new identifier will cause the next run to be a full re-run. Would you like to proceed?",
                  }
                : undefined
            }
          />
          <DiscardButton />
        </ButtonGroup>
      </ActionBar>
    </Form>
  );
};

import { FormField, TextInput } from "@hightouchio/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { EventSchemaFormState } from "src/events/contracts/types";

export const NameField: FC = () => {
  const { control } = useFormContext<EventSchemaFormState>();

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Circular reference for ContractProperty type
    <Controller
      control={control}
      name="name"
      render={({ field, fieldState, formState }) => (
        <FormField
          label="Name"
          tip="This must match the function name in your event definition
          code (including capitalization, spaces, etc.)"
          error={
            formState.isSubmitted && fieldState.error?.message
              ? fieldState.error.message
              : undefined
          }
        >
          <TextInput
            placeholder="Add a name..."
            value={field.value ?? ""}
            onChange={field.onChange}
          />
        </FormField>
      )}
    />
  );
};

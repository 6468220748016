import { FC, ReactNode, useEffect, useMemo, useState } from "react";

import {
  Alert,
  Box,
  Button,
  Checkbox,
  CodeSnippet,
  Column,
  Combobox,
  FileInput,
  FileUploadError,
  FormField,
  GroupedCombobox,
  IconButton,
  MultiSelect,
  Paragraph,
  Radio,
  RadioGroup,
  RefreshIcon,
  Row,
  SectionHeading,
  Select,
  Switch,
  BadgeInput,
  Text,
  Textarea,
  TextareaProps,
  TextInput,
  Tooltip,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { get, isBoolean, isEqual, isObject, uniqBy } from "lodash";
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from "react-hook-form";

import { AccordionSection } from "src/components/accordion-section";
import { Card } from "src/components/card";
import { Editor } from "src/components/editor";
import { FieldError } from "src/components/field-error";
import { usePermissionContext } from "src/components/permission/permission-context";
import { LinkButton } from "src/router";
import { fileToBase64, fileToString } from "src/ui/file/fileUploader";
import { Markdown } from "src/ui/markdown";

import {
  AssociationMappingsProps,
  ButtonProps,
  CheckboxProps,
  CodeProps,
  CollapsibleProps,
  ColumnOrConstantProps,
  ColumnProps,
  ComponentType,
  EditorProps,
  FileProps,
  FormkitComponent,
  FormkitNode,
  getUnaryBooleanValue,
  GooglePickerProps,
  GraphQLVariables,
  HandlerGraphQLVariables,
  InputProps,
  isComponent,
  KeyValueMappingProps,
  LabelProps,
  LayoutType,
  MappingProps,
  MappingsProps,
  MessageProps,
  NestedCheckboxGroupProps,
  NestedRadioGroupProps,
  NodeType,
  Option,
  RadioGroupProps,
  RichTextEditorProps,
  SecretProps,
  SelectProps,
  SwitchProps,
  TableProps,
  toExtendedOption,
  UnaryBoolean,
} from "@hightouch/formkit";
import { toExtendedAssociationOption } from "@hightouch/formkit/src/api/components/associationOption";
import { CloudCredentialsSelectProps } from "@hightouch/formkit/src/api/components/cloud-credentials-select";
import { UseQueryOptions } from "react-query";
import { KeyValueMapping } from "src/components/destinations/key-value-mapping";
import { FormkitTable } from "src/components/destinations/table";
import { TunnelTable } from "src/components/destinations/tunnel-table";
import { SecretInput } from "src/components/secret-input";
import { ProviderSection } from "src/components/sources/setup/providers";
import { processReferences } from "./component-utils";
import {
  Collapsible,
  Form,
  FormkitInput,
  FormkitRTE,
  Mapper,
  Mapping,
  Mappings,
  Modifier,
  NestedCheckBoxGroup,
  NestedRadioGroup,
  FormSection,
  SyncOverride,
  SyncTemplateLock,
} from "./components";
import { useFormkitContext } from "./components/formkit-context";
import useDrivePicker from "./components/google-picker";
import { AssociationMappings as AssociationMappingsV2 } from "./components/mapper-v2/association-mappings";
import { useMissingFormkitRelationships } from "./components/sync-template-lock/use-missing-formkit-relationships";
import { JsonColumnProps } from "./components/types";
import { VALUE_LABEL_FUNCTION_MAP } from "./constants";
import { graphQLFetch } from "./hooks";
import { parseHeadings, SpecialQuery, specialQuery } from "./utils";

type ControllerField = ControllerRenderProps<FieldValues, string>;
type SpecialOptions<TVariables extends GraphQLVariables> = {
  query: UseQueryOptions<unknown, unknown>;
  variables: TVariables;
};
type QueryOptions = SpecialOptions<HandlerGraphQLVariables>;
type ControllerRenderer = ({
  field,
}: {
  field: ControllerField;
}) => JSX.Element;

interface SharedProps {
  name: string;
  error?: string;
  options?: QueryOptions;
  isSetup: boolean;
  isDisabled: boolean;
  query: SpecialQuery;
  fromQuery: SpecialQuery;
}

// Subtypes of the Select component
enum SelectType {
  Select,
  Combobox,
  GroupedCombobox,
  MultiSelect,
  BadgeInput,
}

// Subtypes of the Column component
enum ColumnType {
  Mapper,
  Box,
  Text,
}

// Subtypes of the Column or Constant component
enum CocType {
  ColumnModel,
  ColumnCustom,
  CreatableSelect,
  QuerySelect,
  MultiSelect,
  Select,
  RadioGroup,
  TextInput,
}

const BaseComponent: Record<ComponentType, FC<any>> = {
  [ComponentType.Collapsible]: ({
    label,
    children,
    name,
  }: CollapsibleProps & { children: any } & SharedProps) => (
    <Controller
      name={name}
      render={({ field }) => (
        <Collapsible
          label={label}
          value={field.value}
          onChange={field.onChange}
        >
          <Card>
            <Form>{children}</Form>
          </Card>
        </Collapsible>
      )}
    />
  ),
  [ComponentType.Switch]: ({
    name,
    label,
    isDisabled,
  }: SwitchProps & SharedProps) => (
    <Controller
      name={name}
      render={({ field }) => (
        <Box alignItems="center" display="flex" gap={3}>
          <Switch
            aria-label={`Toggle ${label}.`}
            isChecked={Boolean(field.value)}
            isDisabled={isDisabled}
            onChange={(value) => field.onChange(value)}
          />
          <Text fontWeight="semibold">{label}</Text>
        </Box>
      )}
    />
  ),
  [ComponentType.Checkbox]: ({
    name,
    label,
    isDisabled,
  }: CheckboxProps & SharedProps) => (
    <Controller
      name={name}
      render={({ field }) => (
        <Checkbox
          isChecked={Boolean(field.value)}
          isDisabled={isDisabled}
          label={label}
          onChange={field.onChange}
        />
      )}
    />
  ),
  [ComponentType.Select]: ({
    isSetup,
    creatable,
    default: defaultValue,
    grouped,
    createLabelPrefix = "object",
    error,
    multi,
    name,
    options,
    placeholder,
    searchable,
    valueLabelFunctionKey,
    width,
    query,
    isDisabled,
  }: SelectProps & { createLabelPrefix?: string } & SharedProps) => {
    const [customOptions, setCustomOptions] = useState<
      Array<{ label: string; value: string }>
    >([]);
    const [inputValue, setInputValue] = useState<string>("");

    if (searchable && options?.variables?.input?.variables) {
      options.variables.input.variables = {
        ...options.variables.input.variables,
        inputValue,
      };
    }

    useEffect(() => {
      if (query.enabled && query.refetch && inputValue && inputValue !== "") {
        query.refetch();
      }
    }, [inputValue]);

    // Destinations like `sfmc` can return objects without `label` or `value`, so they need to be removed
    const nonEmptyDynamicOptions = (query.data ?? []).filter((option) => {
      return "label" in option && "value" in option;
    });

    const staticOrDynamicOptions = query.enabled
      ? nonEmptyDynamicOptions
      : uniqBy(options as Option[], "value");

    const combinedOptions = [...staticOrDynamicOptions, ...customOptions];
    const isCreatableOrQuery = creatable || query.enabled;

    // Grouped options are only used by `salesforce` sync form, and
    // it's configured to allow single selection and disable creatable options.
    // Until more destinations use grouped options, it's not necessary to
    // implement multi selection and creatable options for grouped options
    const type: SelectType = grouped
      ? SelectType.GroupedCombobox
      : multi
        ? isCreatableOrQuery
          ? SelectType.BadgeInput
          : SelectType.MultiSelect
        : isCreatableOrQuery
          ? SelectType.Combobox
          : SelectType.Select;
    const sharedProps = {
      isDisabled,
      isInvalid: Boolean(error),
      isLoading: query.enabled ? query.isFetching : false,
      placeholder,
      width,
      valueLabel:
        valueLabelFunctionKey &&
        VALUE_LABEL_FUNCTION_MAP[valueLabelFunctionKey],
    };

    const sharedCreatableProps = {
      supportsCreatableOptions: creatable || searchable,
      createOptionMessage: (inputValue: string) => {
        return searchable
          ? `Search for "${inputValue}"`
          : `Create ${createLabelPrefix} "${inputValue}"`;
      },
    };
    const renderer: Record<SelectType, ControllerRenderer> = {
      [SelectType.Select]: ({ field }) => (
        <Select
          {...sharedProps}
          isClearable={true}
          options={combinedOptions}
          value={field.value ?? defaultValue ?? undefined}
          onChange={(newValue) => {
            field.onChange(newValue ?? defaultValue ?? null);
          }}
        />
      ),
      [SelectType.Combobox]: ({ field }) => {
        useEffect(() => {
          if (searchable && inputValue && !query.isFetching) {
            if (query.data?.length) {
              const firstLookup = query.data[0].value;
              // If async lookups and search returned a value, set field to trigger modifiers
              if (!field.value) {
                field.onChange(firstLookup);
              }
            } else {
              setInputValue("");
            }
          }
        }, [query.data]);
        return (
          <Combobox
            {...sharedProps}
            {...sharedCreatableProps}
            isClearable={true}
            options={combinedOptions}
            value={field.value ?? defaultValue ?? undefined}
            onChange={(newValue) => {
              setInputValue("");
              field.onChange(newValue ?? null);
            }}
            onCreateOption={async (inputValue) => {
              if (searchable) {
                setInputValue(inputValue);
                return;
              }
              const newOption = {
                label: inputValue,
                value: inputValue,
              };

              setCustomOptions((previousCustomOptions) => {
                return [...previousCustomOptions, newOption];
              });
              field.onChange(inputValue);
            }}
          />
        );
      },
      [SelectType.GroupedCombobox]: ({ field }) => (
        <GroupedCombobox
          {...sharedProps}
          optionGroups={query.data ?? []}
          value={field.value ?? defaultValue ?? undefined}
          onChange={(newValue) => {
            field.onChange(newValue ?? null);
          }}
        />
      ),
      [SelectType.MultiSelect]: ({ field }) => (
        <Box flex="1" minWidth={0}>
          <MultiSelect
            {...sharedProps}
            isClearable={true}
            options={combinedOptions}
            value={field.value ?? defaultValue ?? []}
            width="100%"
            onChange={(newValue) => {
              field.onChange(newValue ?? []);
            }}
          />
        </Box>
      ),
      [SelectType.BadgeInput]: ({ field }) => (
        <Box flex="1" minWidth={0}>
          <BadgeInput
            {...sharedProps}
            {...sharedCreatableProps}
            options={combinedOptions}
            value={field.value ?? defaultValue ?? []}
            width="100%"
            onChange={(newValue) => {
              field.onChange(newValue ?? []);
            }}
            onCreateOption={async (inputValue) => {
              const newOption = {
                label: inputValue,
                value: inputValue,
              };

              setCustomOptions((previousCustomOptions) => {
                return [...previousCustomOptions, newOption];
              });

              field.onChange([...(field.value ?? []), inputValue]);
            }}
          />
        </Box>
      ),
    };

    return (
      <Controller
        name={name}
        render={({ field }) => {
          const hasValue = multi
            ? Array.isArray(field.value) && field.value.length > 0
            : Boolean(field.value);

          // When user creates a custom option while creating a new destination,
          // this option must be added to a list of static/dynamic options on edit page,
          // otherwise selected option won't be displayed in a combobox
          if (!isSetup && creatable && hasValue) {
            const values = multi ? field.value : [field.value];

            for (const value of values) {
              const selectedOptionExists = combinedOptions.some((option) => {
                return option.value === value;
              });

              if (!selectedOptionExists) {
                combinedOptions.push({
                  label: value,
                  value,
                });
              }
            }
          }

          return (
            <Box alignItems="center" display="flex" gap={3}>
              {renderer[type]({ field })}
              {query.refetch && !searchable && (
                <Button icon={RefreshIcon} onClick={query.refetch}>
                  Refresh
                </Button>
              )}
            </Box>
          );
        }}
      />
    );
  },
  [ComponentType.Input]: ({
    default: defaultValue,
    error,
    max,
    min,
    name,
    placeholder,
    prefix,
    readOnly,
    style,
    type,
    value,
    query,
    isDisabled,
  }: InputProps & { type: any } & SharedProps) => {
    const { control, setValue } = useFormContext();
    const hardcodedValue = value
      ? typeof value === "string"
        ? value
        : query.data
      : undefined;

    useEffect(() => {
      if (hardcodedValue != null) {
        setValue(name, hardcodedValue);
      }
    }, [hardcodedValue]);

    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <FormkitInput
            prefix={prefix?.toString()}
            disabled={isDisabled}
            invalid={Boolean(error)}
            readonly={readOnly}
            min={min}
            max={max}
            placeholder={placeholder?.toString()}
            type={type}
            defaultValue={defaultValue}
            value={hardcodedValue ?? field.value ?? ""}
            onBlur={field.onBlur}
            style={style}
            onChange={(event) => {
              const value = event.target.value;
              if (type === "number") {
                field.onChange(Number(value));
              } else {
                field.onChange(value ?? undefined);
              }
            }}
          />
        )}
      />
    );
  },
  [ComponentType.Secret]: ({
    name,
    isSetup,
    multiline,
    placeholder,
    isDisabled,
  }: SecretProps & SharedProps) => (
    <Controller
      name={name}
      render={({ field }) => (
        <SecretInput
          isDisabled={isDisabled}
          isHidden={!isSetup}
          multiline={multiline}
          onChange={field.onChange}
          value={field.value}
          placeholder={placeholder?.toString()}
        />
      )}
    />
  ),
  [ComponentType.KeyValueMapping]: ({
    name,
    enableEncryption,
    isDisabled,
  }: KeyValueMappingProps & SharedProps) => (
    <Controller
      name={name}
      render={({ field }) => (
        <KeyValueMapping
          isDisabled={isDisabled}
          enableEncryption={enableEncryption}
          mapping={field.value}
          setMapping={field.onChange}
        />
      )}
    />
  ),
  [ComponentType.Table]: ({
    name,
    columns,
    addRow,
    addRowLabel,
    isDisabled,
  }: TableProps & SharedProps) => (
    <Controller
      name={name}
      render={({ field }) => (
        <FormkitTable
          isDisabled={isDisabled}
          addRow={addRow}
          addRowLabel={addRowLabel}
          columns={columns}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  ),
  [ComponentType.TunnelTable]: ({
    name,
    columns,
    addRow,
    addRowLabel,
    createTunnel,
    isDisabled,
  }: TableProps & SharedProps) => (
    <Controller
      name={name}
      render={({ field }) => (
        <TunnelTable
          isDisabled={isDisabled}
          addRow={addRow}
          addRowLabel={addRowLabel}
          createTunnel={createTunnel}
          columns={columns}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  ),
  [ComponentType.File]: ({
    name,
    acceptedFileTypes,
    transformation,
    isDisabled,
  }: FileProps & SharedProps) => (
    <Controller
      name={name}
      render={({ field }) => (
        <FileInput
          accept={acceptedFileTypes}
          isDisabled={isDisabled}
          onUpload={async (file) => {
            if (transformation === "base64") {
              const result = await fileToBase64(file);
              field.onChange(result);
            }

            if (transformation === "string") {
              const result = await fileToString(file);
              field.onChange(result);
            }

            if (transformation === "JSONParse") {
              try {
                const result = JSON.parse(await file.text());
                field.onChange(result);
              } catch {
                throw new FileUploadError("Uploaded file is not a valid JSON");
              }
            }

            if (typeof transformation === "function") {
              const result = await transformation(file);
              field.onChange(result);
            }
          }}
        />
      )}
    />
  ),
  [ComponentType.Textarea]: ({
    name,
    error,
    placeholder,
    isDisabled,
  }: TextareaProps & SharedProps) => {
    const { control } = useFormContext();
    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Textarea
            isDisabled={isDisabled}
            isInvalid={Boolean(error)}
            placeholder={placeholder}
            resize="vertical"
            rows={10}
            width="100%"
            value={field.value}
            onBlur={field.onBlur}
            onChange={field.onChange}
          />
        )}
      />
    );
  },
  [ComponentType.RichTextEditor]: ({
    name,
    placeholder,
    profile,
    handler,
    isDisabled,
  }: RichTextEditorProps & SharedProps) => {
    const { enableSlackRte } = useFlags();

    const basicRenderer = ({ field }) => (
      <Column
        border="1px"
        borderColor="base.border"
        borderRadius="md"
        maxHeight="300px"
        minHeight="200px"
        height="100%"
        overflow="hidden"
      >
        <Editor
          minHeight="200px"
          readOnly={isDisabled}
          value={field.value || ""}
          language="liquid"
          placeholder={placeholder}
          onChange={field.onChange}
        />
      </Column>
    );

    const rteRenderer = ({ field }) => (
      <Box border="1px" borderColor="base.border" borderRadius="md">
        <FormkitRTE
          isDisabled={isDisabled}
          onChange={field.onChange}
          value={field.value}
          profile={profile}
          placeholder={placeholder}
          handler={handler}
        />
      </Box>
    );

    return (
      <Controller
        name={name}
        render={enableSlackRte ? rteRenderer : basicRenderer}
      />
    );
  },
  [ComponentType.Editor]: ({
    beautifyJson,
    name,
    language,
    placeholder,
    isDisabled,
  }: EditorProps & { language: any } & SharedProps) => {
    const { setError, clearErrors } = useFormContext();
    return (
      <Controller
        name={name}
        render={({ field }) => (
          <>
            <Column
              border="1px"
              borderColor="base.border"
              borderRadius="md"
              maxHeight="300px"
              minHeight="200px"
              height="100%"
              overflow="hidden"
            >
              <Editor
                minHeight="200px"
                readOnly={isDisabled}
                value={field.value || ""}
                language={language}
                placeholder={placeholder?.toString()}
                onChange={field.onChange}
              />
            </Column>
            {beautifyJson && (
              <Button
                isDisabled={isDisabled}
                mt={2}
                onClick={() => {
                  try {
                    const parsed = JSON.parse(field.value);
                    field.onChange(JSON.stringify(parsed, null, 4));
                    clearErrors(name);
                  } catch {
                    setError(name, { message: "Text is not a valid JSON" });
                  }
                }}
              >
                Beautify
              </Button>
            )}
          </>
        )}
      />
    );
  },
  [ComponentType.Code]: ({
    name,
    title,
    content,
    query,
  }: CodeProps & SharedProps) => {
    const code = useMemo(
      () => (query.enabled ? (query.data ?? []) : content),
      [query, content],
    );
    return (
      <Controller
        name={name}
        render={() => <CodeSnippet code={code.join("\n")} label={title} />}
      />
    );
  },
  [ComponentType.Message]: ({ message, variant, title }: MessageProps) => {
    let titleToUse = title || "Information";
    if (variant && variant !== "subtle") {
      const firstLetter = variant.charAt(0).toUpperCase();
      const remainingLetters = variant.slice(1);
      titleToUse = firstLetter + remainingLetters;
    }
    return (
      <Alert
        variant="inline"
        type={variant ?? "info"}
        title={titleToUse}
        message={message && <Markdown>{message}</Markdown>}
      />
    );
  },
  [ComponentType.Label]: ({
    message,
    color,
    size,
    fontWeight,
  }: LabelProps & SharedProps) => (
    <Text size={size} color={color} fontWeight={fontWeight}>
      {message}
    </Text>
  ),
  [ComponentType.Mapping]: ({
    name,
    error,
    options,
    fromOptions,
    fromLabel,
    fromIcon,
    creatable,
    creatableTypes,
    advanced,
    templates,
    mappingTypes,
    isDisabled,
    query: toQuery,
    fromQuery,
  }: MappingProps & { creatable: any } & SharedProps) => (
    <Mapping
      isDisabled={isDisabled}
      advanced={advanced}
      creatable={getUnaryBooleanValue(creatable)}
      creatableTypes={creatableTypes}
      error={error}
      fromType={fromOptions ? "destinationOnlyMapping" : undefined}
      fromIcon={fromIcon?.toString()}
      fromLabel={fromLabel}
      fromLoadingOptions={fromQuery.enabled ? fromQuery.isFetching : undefined}
      fromOptions={toExtendedOption(
        fromQuery.enabled ? fromQuery.data : fromOptions,
      )}
      fromReloadOptions={fromQuery.refetch}
      loading={fromQuery.isFetching}
      mappingTypes={mappingTypes}
      name={name}
      options={toExtendedOption(toQuery.enabled ? toQuery.data : options)}
      reload={toQuery.refetch}
      templates={templates || []}
    />
  ),
  [ComponentType.Mappings]: ({
    name,
    allEnabled,
    autoSyncColumnsDefault,
    allEnabledKey,
    allEnabledLabel,
    creatable,
    creatableTypes,
    options,
    error,
    advanced,
    enableInLineMapper,
    required,
    excludeMappings,
    associationOptions,
    templates,
    componentSupportsMatchBoosting,
    matchboosterSemanticColumnsToMap,
    allowCreatableAutomapperWithFields,
    allowIgnoreNullForAssociations,
    isDisabled,
    query,
  }: MappingsProps & {
    allEnabledKey?: string;
    allEnabledLabel?: string;
    associationOptions: any;
    creatable: any;
  } & SharedProps) => (
    <Mappings
      advanced={advanced}
      allEnabled={allEnabled}
      allEnabledKey={allEnabledKey}
      allEnabledLabel={allEnabledLabel}
      allowCreatableAutomapperWithFields={allowCreatableAutomapperWithFields}
      allowIgnoreNullForAssociations={allowIgnoreNullForAssociations}
      associationOptions={toExtendedAssociationOption(associationOptions)}
      asyncOptions={query.enabled}
      autoSyncColumnsDefault={autoSyncColumnsDefault}
      creatable={getUnaryBooleanValue(creatable)}
      creatableTypes={toExtendedOption(creatableTypes)}
      enableInLineMapper={enableInLineMapper}
      componentSupportsMatchBoosting={componentSupportsMatchBoosting}
      matchboosterSemanticColumnsToMap={matchboosterSemanticColumnsToMap}
      error={error}
      excludeMappings={excludeMappings}
      isDisabled={isDisabled}
      loading={query.isFetching}
      name={name}
      options={toExtendedOption(query.enabled ? query.data : options)}
      reload={query.refetch}
      required={required}
      templates={templates}
    />
  ),
  [ComponentType.AssociationMappings]: ({
    name,
    options,
    error,
    excludeMappings,
    ascOptions,
    isDisabled,
    query,
  }: AssociationMappingsProps & SharedProps) => (
    <AssociationMappingsV2
      ascOptions={toExtendedOption(ascOptions)}
      error={error}
      excludeMappings={excludeMappings}
      isDisabled={isDisabled}
      loading={query.isFetching}
      name={name}
      options={toExtendedOption(query.enabled ? query.data : options)}
      reload={query.refetch}
    />
  ),
  [ComponentType.RadioGroup]: ({
    name,
    options,
    isDisabled,
    query,
  }: RadioGroupProps & SharedProps) => {
    const staticOrDynamicOptions = query.enabled ? (query.data ?? []) : options;
    return (
      <Controller
        name={name}
        render={({ field }) => {
          // `RadioGroup` component requires values to be strings, but
          // field options can be either completely missing or be booleans
          // so we're using option indexes as `RadioGroup` value instead
          const selectedIndex = staticOrDynamicOptions.findIndex(
            (option) => option.value === (field.value ?? undefined),
          );

          return (
            <RadioGroup
              isDisabled={isDisabled}
              orientation="vertical"
              value={String(selectedIndex)}
              onChange={(indexString) => {
                const option = staticOrDynamicOptions.find(
                  (_, index) => String(index) === indexString,
                );
                field.onChange(option?.value ?? null);
              }}
            >
              {staticOrDynamicOptions.map((option, index) => (
                <Radio
                  key={option.value ?? index}
                  description={
                    option.description && (
                      <Markdown>{option.description}</Markdown>
                    )
                  }
                  label={option.label}
                  value={String(index)}
                />
              ))}
            </RadioGroup>
          );
        }}
      />
    );
  },
  [ComponentType.Button]: ({
    label,
    mode,
    onClickUrlQuery,
    newTab,
    url,
    query,
    isDisabled,
  }: ButtonProps & { onClickUrlQuery: any } & SharedProps) => {
    query.data = query.data ?? "";
    const generatedUrl = url
      ? typeof url === "string"
        ? url
        : query.data
      : undefined;
    const { destination, model } = useFormkitContext();
    if (mode === "link" && (generatedUrl || onClickUrlQuery)) {
      return (
        <Button
          isDisabled={isDisabled}
          variant="primary"
          onClick={async () => {
            let onClickUrl;
            // For the edge case in which the page rerenders and reruns the above GraphQL query, causing credentials to be reset in OAuth
            // i.e. Google Sheets SA
            if (onClickUrlQuery) {
              const data = await graphQLFetch({
                destinationId: destination?.id,
                query: onClickUrlQuery.query,
                modelId: model?.id,
                variables: onClickUrlQuery.variables,
              });
              onClickUrl = data;
            }
            if (newTab) {
              window?.open(generatedUrl || onClickUrl, "_blank", "noreferrer");
            } else {
              location.href = generatedUrl || onClickUrl;
            }
          }}
        >
          {label}
        </Button>
      );
    }

    return (
      <>
        {mode === "link" && (
          <LinkButton href={generatedUrl}>{label}</LinkButton>
        )}
      </>
    );
  },
  [ComponentType.Column]: ({
    name,
    error,
    useStringColumnValue,
    advanced,
    templates,
    type,
    isDisabled,
  }: ColumnProps & SharedProps & { useStringColumnValue: any; type: any }) => {
    const { columns, reloadModel, loadingModel, model } = useFormkitContext();
    const [jsonColumnProperties, setJsonColumnProperties] =
      useState<JsonColumnProps>({
        selectedColumnProps: undefined,
        allColumnsProps: undefined,
      });

    // Determine the kind of component that will be rendered
    const base = advanced
      ? ColumnType.Mapper
      : model
        ? ColumnType.Box
        : ColumnType.Text;

    // Define onchange
    const onChange =
      (field: ControllerField) => (value: string | undefined) => {
        if (!value) {
          field.onChange(advanced ? { type: "standard" } : null);
          return;
        }

        field.onChange(useStringColumnValue ? value : { from: value });
      };

    const renderer: Record<ColumnType, ControllerRenderer> = {
      [ColumnType.Mapper]: ({ field }) => (
        <Mapper
          isClearable={true}
          isDisabled={isDisabled}
          isError={Boolean(error)}
          jsonColumnProperties={jsonColumnProperties}
          placeholder="Select a value..."
          selectedOption={undefined}
          templates={templates ?? []}
          value={
            field.value
              ? field.value
              : advanced
                ? { type: "standard" }
                : undefined
          }
          onChange={(value) => {
            if (!value) {
              field.onChange({ type: "standard" });
              return;
            }
            field.onChange(value);
          }}
          onChangeJsonColumnProperties={setJsonColumnProperties}
          onReloadEligibleInlineMapperColumns={() => {
            Sentry.captureException(
              new Error(
                "reloadJsonColumnProps called for column formkit component",
              ),
            );
          }}
        />
      ),
      [ColumnType.Box]: ({ field }) => (
        <Box alignItems="center" display="flex" gap={2}>
          <GroupedCombobox
            isClearable={true}
            isDisabled={isDisabled}
            isInvalid={Boolean(error)}
            isLoading={loadingModel}
            // GroupedCombobox expects options to be structured differently
            optionGroups={useMemo(
              () =>
                (columns ?? []).map((group) => ({
                  ...group,
                  options: group.options ?? [],
                })),
              [columns],
            )}
            placeholder="Select a column..."
            value={useStringColumnValue ? field.value : field.value?.from}
            onChange={onChange(field)}
          />

          <Button icon={RefreshIcon} onClick={reloadModel}>
            Refresh
          </Button>
        </Box>
      ),
      [ColumnType.Text]: ({ field }) => {
        return (
          <TextInput
            isDisabled={isDisabled}
            isInvalid={Boolean(error)}
            placeholder="Enter a column..."
            value={field.value?.from}
            onChange={(event) => onChange(field)(event.target.value)}
            type={type}
          />
        );
      },
    };

    return <Controller name={name} render={renderer[base]} />;
  },
  [ComponentType.ColumnOrConstant]: ({
    constantComponentType,
    creatable,
    createLabelPrefix = "object",
    error,
    name,
    options,
    type,
    multi,
    searchable,
    isDisabled,
    query,
  }: ColumnOrConstantProps & {
    creatable?: UnaryBoolean;
    createLabelPrefix?: string;
    searchable?: boolean;
  } & SharedProps) => {
    const { model, columns, reloadModel, loadingModel, isEventForwardingForm } =
      useFormkitContext();

    // User-input options
    const [customOptions, setCustomOptions] = useState<Option[]>([]);

    // Create a new custom option
    const createOption = (value: string) => {
      // Do nothing if it is already a custom value or already a preset value
      if (!combinedOptions.map((option) => option.value).includes(value)) {
        setCustomOptions([...customOptions, { label: value, value: value }]);
      }
    };

    // Options predefined by the form
    const existingOptions: Option[] = useMemo(
      () => (query.enabled ? query.data : options) ?? [],
      [query.data, options],
    );

    // Values that will be ignored if the user manually enters them
    const existingValues = useMemo(
      () => existingOptions.map((option) => option.value),
      [existingOptions],
    );

    // Combined Option list should include all options, both existing and custom
    const combinedOptions: Option[] = useMemo(
      () => [
        ...existingOptions,
        ...customOptions.filter(
          (option) => !existingValues.includes(option.value),
        ),
      ],
      [existingOptions, existingValues, customOptions],
    );

    // Items in `columns` have `options` field as optional,
    // but `GroupedCombobox` expects it to be required
    const optionGroups = useMemo(() => {
      return (columns ?? []).map((group) => ({
        ...group,
        options: group.options ?? [],
      }));
    }, [columns]);

    const isSelectOrNothing = [ComponentType.Select, undefined].includes(
      constantComponentType,
    );
    const isMulti = multi && isSelectOrNothing;
    const isSelect = options && isSelectOrNothing;
    const isRadioGroup =
      options && ComponentType.RadioGroup === constantComponentType;
    const isSearchable = searchable && isSelectOrNothing;

    // Render a grouped combobox
    const groupedCombobox = (field: ControllerField) => (
      <GroupedCombobox
        isClearable={true}
        isDisabled={isDisabled}
        isInvalid={Boolean(error)}
        isLoading={loadingModel}
        optionGroups={optionGroups}
        placeholder="Select a column..."
        value={field.value?.from}
        onChange={(value) => {
          field.onChange({
            from: value || undefined,
          });
        }}
      />
    );
    const comboBox = (field: ControllerField) => (
      <Combobox
        isClearable={true}
        isDisabled={isDisabled}
        isInvalid={Boolean(error)}
        isLoading={query.isFetching}
        options={combinedOptions}
        placeholder="Select an option..."
        value={field.value}
        onChange={field.onChange}
        supportsCreatableOptions={getUnaryBooleanValue(creatable ?? false)}
        onCreateOption={async (value) => {
          field.onChange(value);
          createOption(value);
        }}
        createOptionMessage={(value) =>
          `Create ${createLabelPrefix} "${value}"...`
        }
      />
    );
    const multiSelect = (field: ControllerField) => (
      <MultiSelect<Option, string>
        isClearable={!query.enabled}
        isDisabled={isDisabled}
        isInvalid={Boolean(error)}
        options={combinedOptions}
        isLoading={query.enabled ? query.isFetching : false}
        value={Array.isArray(field.value) ? field.value : []}
        placeholder="Select options..."
        onChange={(newValue) => {
          field.onChange(newValue ?? []);
        }}
      />
    );
    const isColumnn = (field: ControllerField) =>
      // Multi select is only avaliable on Select component.
      !(isMulti && Array.isArray(field.value)) &&
      typeof field.value === "object" &&
      ![null, undefined].includes(field.value);
    // Complex ternary logic for determining the subtype of this component
    const componentType = (field: ControllerField) =>
      isColumnn(field)
        ? model
          ? CocType.ColumnModel
          : CocType.ColumnCustom
        : isSelect
          ? creatable
            ? CocType.CreatableSelect
            : isMulti
              ? CocType.MultiSelect
              : isSearchable
                ? CocType.QuerySelect
                : CocType.Select
          : isRadioGroup
            ? CocType.RadioGroup
            : CocType.TextInput;

    const onSwitchChange = (field: ControllerField) => (value: boolean) => {
      const newValue = value
        ? { from: undefined }
        : constantComponentType === ComponentType.RadioGroup
          ? options[0].value
          : options
            ? // Dropdown
              isMulti
              ? []
              : null
            : // Input field
              "";
      field.onChange(newValue);
    };

    const renderer: Record<CocType, ControllerRenderer> = {
      [CocType.ColumnModel]: ({ field }) => (
        <Box alignItems="center" display="flex" gap={2}>
          {groupedCombobox(field)}

          <Button icon={RefreshIcon} onClick={reloadModel}>
            Refresh
          </Button>
        </Box>
      ),
      [CocType.ColumnCustom]: ({ field }) => (
        <TextInput
          isDisabled={isDisabled}
          isInvalid={Boolean(error)}
          placeholder="Enter a column..."
          value={field.value?.from ?? ""}
          onChange={({ target: { value } }) => {
            field.onChange({
              from: value || undefined,
            });
          }}
          type={type}
        />
      ),
      [CocType.CreatableSelect]: ({ field }) => (
        <Row>
          {comboBox(field)}
          {query.enabled ? (
            <Tooltip message="Reload options">
              <IconButton
                aria-label="Reload options"
                isLoading={query.isFetching}
                isDisabled={isDisabled}
                icon={RefreshIcon}
                onClick={() => {
                  if (query.refetch) {
                    query.refetch();
                  }
                }}
              />
            </Tooltip>
          ) : (
            <></>
          )}
        </Row>
      ),
      [CocType.QuerySelect]: ({ field }) => (
        <Box alignItems="center" display="flex" gap={2}>
          {(isMulti ? multiSelect : comboBox)(field)}
          <Button
            icon={RefreshIcon}
            variant="secondary"
            isDisabled={isDisabled}
            onClick={query.refetch}
          >
            Refresh
          </Button>
        </Box>
      ),
      [CocType.MultiSelect]: ({ field }) => multiSelect(field),
      [CocType.Select]: ({ field }) => (
        <Select
          isClearable={true}
          isDisabled={isDisabled}
          isInvalid={Boolean(error)}
          options={combinedOptions}
          placeholder="Select an option..."
          value={field.value}
          onChange={(value) => {
            field.onChange(value || undefined);
          }}
        />
      ),
      [CocType.RadioGroup]: ({ field }) => {
        const selectedRadioGroupIndex =
          Array.isArray(options) &&
          constantComponentType === ComponentType.RadioGroup
            ? options.findIndex(
                (option) => option.value === (field.value ?? undefined),
              )
            : -1;
        return (
          <RadioGroup
            isDisabled={isDisabled}
            value={String(selectedRadioGroupIndex)}
            onChange={(indexString) => {
              const option = combinedOptions.find(
                (_, index) => String(index) === indexString,
              );
              field.onChange(option?.value ?? null);
            }}
          >
            {combinedOptions.map((option, index) => (
              <Radio
                key={
                  isBoolean(option.value) || isObject(option.value)
                    ? index
                    : (option.value ?? index)
                }
                description={option.description}
                label={option.label}
                value={String(index)}
              />
            ))}
          </RadioGroup>
        );
      },
      [CocType.TextInput]: ({ field }) => (
        <TextInput
          isDisabled={isDisabled}
          isInvalid={Boolean(error)}
          placeholder="Enter a value..."
          value={field.value}
          onChange={field.onChange}
          type={type}
        />
      ),
    };

    const skeleton = ({ field }: { field: ControllerField }) => (
      <Row display="flex" justifyContent="space-between">
        {renderer[componentType(field)]({ field })}

        <Box>
          <Text
            textTransform="uppercase"
            size="sm"
            color="text.secondary"
            fontWeight="semibold"
          >
            {isEventForwardingForm ? "Use property" : "Use column"}
          </Text>

          <Switch
            aria-label={`Use ${isEventForwardingForm ? "property" : "column"}`}
            isChecked={isColumnn(field)}
            isDisabled={isDisabled}
            onChange={onSwitchChange(field)}
          />
        </Box>
      </Row>
    );

    return <Controller name={name} render={skeleton} />;
  },
  [ComponentType.NestedRadioGroup]: ({
    name,
    rootKey,
    listKey,
    options,
    query,
    isDisabled,
  }: NestedRadioGroupProps & { listKey: string } & SharedProps) => {
    const { getValues, setValue } = useFormContext();

    useMissingFormkitRelationships(
      name,
      useMemo(
        () => (rootKey ? [listKey, rootKey] : [listKey]),
        [listKey, rootKey],
      ),
    );

    return (
      <Controller
        name={name}
        render={({ field }) => (
          <NestedRadioGroup
            loading={query.isFetching}
            isDisabled={isDisabled}
            options={toExtendedOption(query.enabled ? query.data : options)}
            reload={query.refetch}
            value={getValues(listKey)}
            onChange={(value: string[]) => {
              field.onChange(value[value.length - 1]);
              setValue(listKey, value);
              if (rootKey) {
                setValue(rootKey, value[0]);
              }
            }}
          />
        )}
      />
    );
  },
  [ComponentType.NestedCheckboxGroup]: ({
    name,
    options,
    isDisabled,
    query,
  }: NestedCheckboxGroupProps & SharedProps) => (
    <Controller
      name={name}
      render={({ field }) => (
        <NestedCheckBoxGroup
          loading={query.isFetching}
          isDisabled={isDisabled}
          options={toExtendedOption(query.enabled ? query.data : options)}
          reload={query.refetch}
          value={field.value || []}
          onChange={(newValue: string[]) => {
            field.onChange(newValue ?? []);
          }}
        />
      )}
    />
  ),
  [ComponentType.CloudCredentialsSelect]: ({
    provider,
  }: CloudCredentialsSelectProps & SharedProps) => {
    const { credentialId, setCredentialId } = useFormkitContext();
    return (
      <ProviderSection
        provider={provider}
        credentialId={credentialId}
        setCredentialId={setCredentialId}
      />
    );
  },
  [ComponentType.GooglePicker]: ({
    name,
    viewId,
    isDisabled,
  }: GooglePickerProps & SharedProps) => {
    const [fileOptions, setFileOptions] = useState<
      { label: string; value: string }[]
    >([]);
    const { control } = useFormContext();
    const [openPicker, _authResult] = useDrivePicker();
    const handleOpenPicker = (onChange) => {
      openPicker({
        appId: import.meta.env.VITE_GOOGLE_APP_ID?.toString() ?? "",
        clientId: import.meta.env.VITE_GOOGLE_OAUTH_CLIENT_ID?.toString() ?? "",
        developerKey: import.meta.env.VITE_GOOGLE_API_KEY?.toString() ?? "",
        viewId: viewId || "SPREADSHEETS",
        showUploadView: true,
        showUploadFolders: true,
        supportDrives: true,
        multiselect: false,
        callbackFunction: (data) => {
          if (data.action === "cancel") {
            return;
          }
          if (data?.docs?.[0]?.id) {
            setFileOptions([
              { label: data.docs[0].name, value: data.docs[0].id },
            ]);
            onChange({
              id: data.docs[0].id,
              name: data.docs[0].name,
              parent: data.docs[0].parentId,
            });
          }
        },
      });
    };

    return (
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          if (
            field?.value?.id &&
            !fileOptions.some((opt) => opt.value === field.value.id)
          ) {
            setFileOptions([
              ...fileOptions,
              { label: field.value.name, value: field.value.id },
            ]);
          }
          return (
            <Box gap={2}>
              <Row>
                <Select
                  isDisabled={isDisabled}
                  placeholder="Click here to open the Google Drive picker..."
                  options={fileOptions}
                  value={field.value?.id ?? undefined}
                  onChange={(_) => {
                    // Do nothing. change handled by google drive picker
                    return;
                  }}
                  onOpen={() => {
                    handleOpenPicker(field.onChange);
                  }}
                />
              </Row>
            </Box>
          );
        }}
      />
    );
  },
};

const getComponent = (node: FormkitComponent) => BaseComponent[node.component];

const Node: FC<{ node: FormkitComponent; children?: ReactNode }> = ({
  node,
  children,
}) => {
  const context = useFormkitContext();
  const featureFlags = useFlags();
  const {
    watch,
    resetField,
    formState: { errors },
    getValues,
  } = useFormContext();

  const props = processReferences(
    node.props,
    { ...context, formState: getValues(), featureFlags },
    watch,
  ) as Record<string, unknown>;

  const isFieldDisabled = context.isFieldDisabled(node.key);

  const createError = (key: string) => {
    const rawError = get(errors, key)?.message;
    const error =
      typeof rawError === "string" ? rawError.replace(key, "This") : rawError;
    return error;
  };

  let error = createError(node.key);

  // Doing this because columns `{ from: string }` gets validate from inside out.
  // For example: a returned validation errors is `errors: { "eventId.from": "eventId.from is required."}`
  if (
    !error &&
    node.type === NodeType.Component &&
    [ComponentType.Column, ComponentType.ColumnOrConstant].includes(
      node.component,
    )
  ) {
    error = createError(`${node.key}.from`);
  }

  useEffect(() => {
    const value = watch(node.key);
    if (node.props?.default !== undefined && value === undefined) {
      resetField(node.key, { defaultValue: node.props.default });
    }
  }, []);

  const isChangedInDraft =
    !children &&
    context?.draftChanges?.find(({ key }) => {
      const nodePath = node.key.split(".");
      const keyPath = key.split(".");
      return isEqual(nodePath, keyPath.slice(0, nodePath.length));
    })?.op;

  const permission = usePermissionContext();
  // the following components require the satisfaction of this permission as well
  // Switch, Checkbox, Secret, TextArea, File, RichTextEditor, Editor, Mapping, Mappings, AssociatedMappings, RadioGroup, Column, ColumnOrConstant
  //
  // the following currently only use the unarybooleanvalue of disable
  // KeyValueMapping, Table, TunnelTable, NestedRadioGroup, NestedCheckBoxGroup
  //
  // the following only care about this permission, which is effectively the same
  // Button
  //

  // I want to define as much shared state as possible before actually rendering the component
  const Component = getComponent(node);
  const queryOptions = () => {
    switch (node.component) {
      case ComponentType.Code: {
        return props.content;
      }
      case ComponentType.Input: {
        return props.value;
      }
      case ComponentType.Button: {
        return props.url;
      }
      default: {
        return props.options;
      }
    }
  };
  const isPropDisabled = getUnaryBooleanValue(
    props["disable"] as boolean | UnaryBoolean,
  );

  const sharedProps: SharedProps = {
    name: node.key,
    error: error?.toString(),
    isSetup: context.isSetup,
    isDisabled: permission.unauthorized || isPropDisabled || isFieldDisabled,
    query: specialQuery(node.key, queryOptions() ?? []),
    fromQuery: specialQuery(node.key, props.fromOptions ?? []),
  };
  const allProps = { ...props, ...sharedProps };
  return (
    <Box
      sx={
        isChangedInDraft
          ? {
              position: "relative",
              "::after": {
                content: '""',
                top: 0,
                left: -5,
                display: "block",
                width: "4px",
                height: "100%",
                position: "absolute",
                borderRadius: "2px",
                backgroundColor:
                  isChangedInDraft === "add" ? "success.base" : "danger.base",
              },
            }
          : {}
      }
    >
      <Component {...allProps}>{children}</Component>

      <FieldError
        error={
          (allProps.query?.error as string) ||
          (allProps.fromQuery?.error as string) ||
          allProps.error
        }
      />
    </Box>
  );
};

interface FormNodeProps {
  node: FormkitNode;
  depth: number;
  context: {
    showLocks?: boolean;
    showOverrides?: boolean;
    isNodeVisible?: (node: FormkitNode) => boolean;
  } & Record<string, unknown>;
}

export const FormNode: FC<FormNodeProps> = ({
  node,
  depth,
  context,
}: FormNodeProps) => {
  const formNodeChildren = (node.children ?? []).map((node, index) => (
    <FormNode key={index} context={context} depth={depth + 1} node={node} />
  ));

  if (node.type === NodeType.Layout) {
    if (
      node.layout === LayoutType.Section ||
      node.layout === LayoutType.Accordion
    ) {
      const { heading, subheading } = parseHeadings(node, context);

      if (node.parent) {
        return (
          <Box
            p="0 !important"
            border="none !important"
            bg="transparent !important"
          >
            <Box mb={2}>
              <SectionHeading>{heading}</SectionHeading>
              {subheading && <Paragraph>{subheading}</Paragraph>}
            </Box>
            <Form>{formNodeChildren}</Form>
          </Box>
        );
      }

      const isSectionVisible = context.isNodeVisible?.(node) ?? true;

      if (!isSectionVisible) return null;

      if (node.layout === LayoutType.Accordion) {
        return (
          <AccordionSection
            label={node.heading ?? ""}
            description={node.subheading}
          >
            <Column gap={6}>{formNodeChildren}</Column>
          </AccordionSection>
        );
      }

      const componentChildren = node.children.filter(isComponent);

      if (node.size === "small") {
        return (
          <FormField
            isOptional={node.optional}
            label={heading && <Markdown disableParagraphs>{heading}</Markdown>}
            description={subheading ? <Markdown>{subheading}</Markdown> : null}
            rightContent={
              componentChildren.length > 0 ? (
                <>
                  {context.showLocks && (
                    <SyncTemplateLock nodes={componentChildren} />
                  )}
                  {context.showOverrides && (
                    <SyncOverride
                      childNodeKeys={componentChildren.map(({ key }) => key)}
                    />
                  )}
                </>
              ) : null
            }
          >
            {formNodeChildren}
          </FormField>
        );
      }

      const isRightContent =
        componentChildren.length > 0 &&
        (context.showLocks || context.showOverrides);

      return (
        <FormSection
          heading={heading}
          subheading={subheading}
          rightContent={
            isRightContent ? (
              <>
                {context.showLocks && (
                  <SyncTemplateLock nodes={componentChildren} />
                )}
                {context.showOverrides && (
                  <SyncOverride
                    childNodeKeys={componentChildren.map(({ key }) => key)}
                  />
                )}
              </>
            ) : undefined
          }
          isOptional={node.optional}
        >
          <Form>{formNodeChildren}</Form>
        </FormSection>
      );
    }

    if (node.layout === LayoutType.Form) {
      return <>{formNodeChildren}</>;
    }
  }

  if (node.type === NodeType.Component) {
    return <Node node={node}>{formNodeChildren}</Node>;
  }

  if (node.type === NodeType.Modifier) {
    return <Modifier node={node}>{formNodeChildren}</Modifier>;
  }

  return null;
};

type ProcessFormNodeProps = Omit<FormNodeProps, "depth" | "context"> & {
  depth?: FormNodeProps["depth"];
  context?: FormNodeProps["context"];
};

export const ProcessFormNode: FC<ProcessFormNodeProps> = ({
  node,
  depth = 0,
  context,
}) => {
  return (
    <FormNode
      context={{
        showLocks: false,
        showOverrides: false,
        isNodeVisible: () => true,
        ...context,
      }}
      depth={depth}
      node={node}
    />
  );
};

import { useToast } from "@hightouchio/ui";
import {
  useUpdateAudienceTemplateMutation,
  useDeleteAudienceTemplateMutation,
  UpdateAudienceTemplateInput,
} from "src/graphql";

export const useMutations = ({ id }: { id: string }) => {
  const { toast } = useToast();

  const updateAudienceTemplate = useUpdateAudienceTemplateMutation();
  const deleteAudienceTemplate = useDeleteAudienceTemplateMutation();

  const updateName = async (name: string) => {
    try {
      await updateAudienceTemplate.mutateAsync({
        id,
        input: { name },
      });
      toast({
        id: "update-audience-template-name",
        title: "Audience template name was updated",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "update-audience-template-name",
        title: "Audience template name was not updated",
        message: error.message,
        variant: "error",
      });
    }
  };

  const updateDescription = async (description: string) => {
    try {
      await updateAudienceTemplate.mutateAsync({
        id,
        input: { description },
      });
      toast({
        id: "update-audience-template-description",
        title: "Audience template description was updated",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "update-audience-template-description",
        title: "Audience template description was not updated",
        message: error.message,
        variant: "error",
      });
    }
  };

  const updateTemplate = async (
    input: UpdateAudienceTemplateInput,
    opts: { showToast?: boolean } = { showToast: true },
  ) => {
    try {
      await updateAudienceTemplate.mutateAsync({ id, input });
      if (opts.showToast) {
        toast({
          id: "update-audience-template",
          title: "Audience template was updated",
          variant: "success",
        });
      }
    } catch (error) {
      toast({
        id: "update-audience-template",
        title: "Audience template was not updated",
        message: error.message,
        variant: "error",
      });
    }
  };

  const deleteTemplate = async () => {
    try {
      await deleteAudienceTemplate.mutateAsync({ id });
      toast({
        id: "delete-audience-template",
        title: "Audience template was deleted",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "delete-audience-template",
        title: "Audience template was not deleted",
        message: error.message,
        variant: "error",
      });
    }
  };

  return {
    updateDescription,
    updateName,
    updateTemplate,
    deleteTemplate,
  };
};

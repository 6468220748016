import { useLayoutEffect, useRef, useState } from "react";

import {
  Box,
  ChakraPopover,
  ChakraPopoverBody,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Row,
  Text,
  useDisclosure,
} from "@hightouchio/ui";
import { TextWithTooltip } from "src/components/text-with-tooltip";

const LEGEND_ITEM_MIN_WIDTH = 120;
// 16px gap + 120px item width
const EFFECTIVE_ITEM_MIN_WIDTH = 136;

export type LegendProps = {
  series: {
    key: string;
    legendName: string;
    color: string;
  }[];
  onHover: (key: string) => void;
};

export const Legend = ({ series, onHover }: LegendProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [visibleChildCount, setVisibleChildCount] = useState(0);

  useLayoutEffect(() => {
    const width = ref.current?.offsetWidth ?? 0;
    setVisibleChildCount(Math.floor(width / EFFECTIVE_ITEM_MIN_WIDTH));
  }, [ref.current?.offsetWidth]);

  const visibleChildren = series.slice(0, visibleChildCount);
  const hiddenChildren = series.slice(visibleChildCount);

  return (
    <Row
      gap={4}
      justify="space-between"
      pl="60px"
      width="100%"
      onMouseLeave={onClose}
    >
      <Row
        ref={ref}
        display="grid"
        gridTemplateColumns={`repeat(auto-fit, minmax(${LEGEND_ITEM_MIN_WIDTH}px, 1fr))`}
        gridTemplateRows="1fr"
        gridAutoColumns="0px"
        gridAutoRows="0px"
        columnGap={4}
        py={4}
        width="100%"
        overflow="hidden"
      >
        {visibleChildren.map(({ key, legendName, color }, index) => (
          <Row
            key={key}
            gap={2}
            alignItems="center"
            justify="center"
            overflow="hidden"
            onMouseEnter={() => onHover(key)}
          >
            <ColorIcon color={color} />
            <TextWithTooltip
              placement={
                index === visibleChildren.length - 1 ? "top-start" : "top"
              }
              color="text.secondary"
              size="sm"
              message={legendName}
            >
              {legendName}
            </TextWithTooltip>
          </Row>
        ))}
      </Row>

      {hiddenChildren.length > 0 && (
        <ChakraPopover isLazy isOpen={isOpen} onClose={onClose}>
          <ChakraPopoverTrigger>
            <Row
              as={Text}
              alignSelf="center"
              color="text.secondary"
              whiteSpace="nowrap"
              onMouseEnter={onOpen}
            >
              {hiddenChildren.length} more
            </Row>
          </ChakraPopoverTrigger>

          <ChakraPopoverContent
            borderRadius="lg"
            border="none"
            maxHeight="300px"
          >
            <ChakraPopoverBody
              display="flex"
              flexDirection="column"
              background="text.primary"
              borderRadius="lg"
              boxShadow="sm"
              color="white"
              gap={2}
              overflowY="auto"
              onMouseLeave={onClose}
            >
              {hiddenChildren.map(({ key, legendName, color }) => (
                <Row key={key} align="center" gap={2}>
                  <ColorIcon color={color} />
                  <Text color="white" size="sm">
                    {legendName}
                  </Text>
                </Row>
              ))}
            </ChakraPopoverBody>
          </ChakraPopoverContent>
        </ChakraPopover>
      )}
    </Row>
  );
};

const ColorIcon = ({ color }: { color: string }) => (
  <Box bg={color} borderRadius="3px" h={4} w={4} flexShrink={0} />
);

import { useOutletContext } from "src/router";
import { OutletContext } from "..";
import {
  Text,
  Column,
  Row,
  Badge,
  Alert,
  FormField,
  TextInput,
  Select,
  Heading,
  Button,
  NumberInput,
  IconButton,
  ExternalLinkIcon,
  useToast,
  Checkbox,
  Combobox,
  Textarea,
} from "@hightouchio/ui";
import { Editor } from "src/components/editor";
import json5 from "json5";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { Controller } from "react-hook-form";
import { Value } from "@sinclair/typebox/value";
import {
  CollectionInputDryRunTypebox,
  ModelConfigurationTypebox,
} from "@hightouch/lib/customer-data/decision-engine/types";
import {
  useSetDecisionEngineConfigMutation,
  useDryRunDecisionEngineMutation,
  useEligibleTargetAudiencesQuery,
  useAnalyzeDecisionEngineTagsForMessageQuery,
  useGetTagsForMessagesBackgroundQuery,
  useRunSqlResultQuery,
  RunSqlResultQuery,
  DryRunDecisionEngineMutationVariables,
} from "src/graphql";
import { useState, useEffect } from "react";
import { noop } from "ts-essentials";
import { Link } from "src/router";
import { Card } from "src/components/card";

export const Engine = () => {
  const { engine } = useOutletContext<OutletContext>();

  const mutation = useSetDecisionEngineConfigMutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await mutation.mutateAsync({
        decisionEngineId: engine.id,
        config: {
          name: data.name,
          feature_model_id: Number(data.feature_model_id),
          user_feature_schema: json5.parse(data.user_feature_schema),
          output_schema: data.output_schema,
          attribution: data.attribution,
          measurement_window_days: data.measurement_window_days
            ? Number(data.measurement_window_days)
            : undefined,
          message_completion_interval_days:
            data.message_completion_interval_days
              ? Number(data.message_completion_interval_days)
              : undefined,
          interactions_historical_days_to_pull:
            data.interactions_historical_days_to_pull
              ? Number(data.interactions_historical_days_to_pull)
              : undefined,
          touch_cached_interactions_older_than_days:
            data.touch_cached_interactions_older_than_days
              ? Number(data.touch_cached_interactions_older_than_days)
              : undefined,
          slack_channel: data.slack_channel,
          scheduling_timezone: data.scheduling_timezone,
          ignore_s3_state: data.ignore_s3_state,
        },
      });
    },
    values: {
      attribution: engine.config.attribution ?? {
        window: { unit: null, value: 1 },
      },
      measurement_window_days: engine.config.measurement_window_days ?? "30",
      feature_model_id: engine.config.feature_model_id ?? "",
      name: engine.config.name ?? "",
      output_schema: engine.config.output_schema ?? "",
      message_completion_interval_days:
        engine.config.message_completion_interval_days ?? "",
      interactions_historical_days_to_pull:
        engine.config.interactions_historical_days_to_pull ?? "",
      touch_cached_interactions_older_than_days:
        engine.config.touch_cached_interactions_older_than_days ?? "",
      user_feature_schema: engine.config.user_feature_schema
        ? JSON.stringify(engine.config.user_feature_schema, null, 2)
        : "",
      slack_channel: engine.config.slack_channel ?? "",
      scheduling_timezone: engine.config.scheduling_timezone,
      ignore_s3_state: engine.config.ignore_s3_state,
    },
  });

  return (
    <Form form={form}>
      <Column gap={6}>
        <Card overflow="hidden" footer={<FormActions />} gap={4}>
          <Row align="center" gap={2}>
            <Heading>Engine</Heading>
            <Badge
              variant={
                engine.status === "TRAINING"
                  ? "info"
                  : engine.status === "READY"
                    ? "success"
                    : "subtle"
              }
            >
              {engine.status.toUpperCase()}
            </Badge>
            <Badge variant={engine.enabled ? "success" : "subtle"}>
              {engine.enabled ? "ENABLED" : "DISABLED"}
            </Badge>
          </Row>
          <Text size="sm" color="text.secondary">
            {engine.id}
          </Text>

          {mutation.data?.setDecisionEngineConfig.__typename ===
            "DecisionEngineError" && (
            <Alert
              type="error"
              variant="inline"
              title="Error"
              message={JSON.stringify(
                mutation.data.setDecisionEngineConfig.error,
              )}
            />
          )}

          <Row gap={8}>
            <Column gap={4}>
              <Controller
                name="name"
                render={({ field }) => (
                  <FormField label="Name">
                    <TextInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="feature_model_id"
                render={({ field }) => (
                  <FormField label="Feature Model ID">
                    <TextInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="output_schema"
                render={({ field }) => (
                  <FormField label="Output Schema">
                    <TextInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="message_completion_interval_days"
                render={({ field }) => (
                  <FormField label="Days to complete message">
                    <TextInput {...field} type="number" />
                  </FormField>
                )}
              />
              <Controller
                name="interactions_historical_days_to_pull"
                render={({ field }) => (
                  <FormField label="Days to pull interactions">
                    <TextInput {...field} type="number" />
                  </FormField>
                )}
              />
              <Controller
                name="touch_cached_interactions_older_than_days"
                render={({ field }) => (
                  <FormField label="Touch cached interactions older than days">
                    <TextInput {...field} type="number" />
                  </FormField>
                )}
              />
              <Controller
                name="measurement_window_days"
                render={({ field }) => (
                  <FormField label="Measurement Window Days">
                    <NumberInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="slack_channel"
                render={({ field }) => (
                  <FormField label="Slack Channel">
                    <TextInput {...field} />
                  </FormField>
                )}
              />
              <Controller
                name="scheduling_timezone"
                render={({ field }) => (
                  <FormField label="Scheduling Timezone">
                    <TextInput {...field} placeholder="America/Los_Angeles" />
                  </FormField>
                )}
              />
              <Controller
                name="ignore_s3_state"
                render={({ field }) => (
                  <FormField label="Ignore S3 State">
                    <Checkbox
                      isChecked={field.value}
                      onChange={field.onChange}
                    />
                  </FormField>
                )}
              />
            </Column>
            <Column flex={1} gap={4}>
              <Controller
                name="user_feature_schema"
                render={({ field }) => (
                  <Column overflow="hidden" flex={1} maxHeight="400px">
                    <Row gap={2} align="center" width="fit-content" py={1}>
                      <Text fontWeight="medium" mb={1}>
                        user_feature_schema
                      </Text>
                      {engine.config.feature_model_id && (
                        <Link
                          href={`/schema-v2/view/query?source=${engine.segment.connection?.id}&id=${engine.config.feature_model_id}`}
                        >
                          <IconButton
                            icon={ExternalLinkIcon}
                            aria-label="Link to feature model"
                            variant="tertiary"
                            onClick={noop}
                          />
                        </Link>
                      )}
                    </Row>
                    <Editor language="json" bg="base.background" {...field} />
                  </Column>
                )}
              />
            </Column>
          </Row>
        </Card>

        <DryRun />

        <ChannelTags />

        <MessageTags />
      </Column>
    </Form>
  );
};

const DryRun = () => {
  const { engine } = useOutletContext<OutletContext>();
  const flows = engine.flows;
  const dryRunEngine = useDryRunDecisionEngineMutation();
  const modelsQuery = useEligibleTargetAudiencesQuery(
    {
      parentModelId: engine.segment.id,
    },
    {
      select: (data) => data.segments,
    },
  );

  const [actionModelConfigError, setActionModelConfigError] = useState<
    string | undefined
  >(undefined);

  const [collectionModelConfigError, setCollectionModelConfigError] = useState<
    string | undefined
  >(undefined);

  const form = useHightouchForm({
    onSubmit: async (data) => {
      const input: DryRunDecisionEngineMutationVariables = {
        id: engine.id,
        actionModelConfiguration: data.actionModelConfiguration
          ? json5.parse(data.actionModelConfiguration)
          : undefined,
        collectionModelConfiguration: data.collectionModelConfiguration
          ? json5.parse(data.collectionModelConfiguration)
          : undefined,
        banditConfiguration: data.banditConfiguration
          ? json5.parse(data.banditConfiguration)
          : undefined,
        forceAllUsers: data.forceAllUsers,
        messageCompletionIntervalDays:
          data.messageCompletionIntervalDays != null
            ? Number(data.messageCompletionIntervalDays)
            : undefined,
        timing: data.timing ? json5.parse(data.timing) : undefined,
        stackOverride: data.stackOverride ?? "production",
      };

      if (data.audience_id != undefined) {
        input.overrideAudienceId = data.audience_id.toString();
      }

      if (data.flow_id != undefined) {
        input.flowId = data.flow_id.toString();
      }

      await dryRunEngine.mutateAsync(input);
    },
  });

  // Basically used to do validation on the model config
  async function actionModelConfigurationChangeHandler(
    e: string,
    onChange: (value: string) => void,
  ) {
    // We allow an empty config
    if (e.length == 0) {
      setActionModelConfigError(undefined);
      return;
    }

    try {
      const jsonConfig = json5.parse(e);
      const errors = Array.from(
        Value.Errors(ModelConfigurationTypebox, jsonConfig),
      );
      if (errors.length > 0) {
        setActionModelConfigError(
          errors
            .map((e) => `Error with property ${e.path}: ${e.message}`)
            .join("\n"),
        );
        return;
      }

      onChange(e);
      setActionModelConfigError(undefined);
    } catch (error) {
      console.error("Parsing error: ", error);
      setActionModelConfigError(error.message);
    }
  }

  // Basically used to do validation on the model config
  async function collectionModelConfigurationChangeHandler(
    e: string,
    onChange: (value: string) => void,
  ) {
    // We allow an empty config
    if (e.length == 0) {
      setCollectionModelConfigError(undefined);
      return;
    }

    try {
      const jsonConfig = json5.parse(e);
      const errors = Array.from(
        Value.Errors(CollectionInputDryRunTypebox, jsonConfig),
      );
      if (errors.length > 0) {
        setCollectionModelConfigError(
          errors
            .map((e) => `Error with property ${e.path}: ${e.message}`)
            .join("\n"),
        );
        return;
      }

      onChange(e);
      setCollectionModelConfigError(undefined);
    } catch (error) {
      console.error("Parsing error: ", error);
      setCollectionModelConfigError(error.message);
    }
  }

  return (
    <Form form={form}>
      <Card maxHeight="1500px" overflow="hidden" gap={4}>
        <Column>
          <Heading>Dry Run</Heading>
        </Column>
        <Controller
          name="audience_id"
          render={({ field }) => (
            <FormField
              label="Target override audience"
              description="If omitted, defaults to the audience configured on the agent."
            >
              <Combobox
                {...field}
                isClearable={true}
                isLoading={modelsQuery.isLoading}
                optionValue={(option) => option.id}
                optionLabel={(option) => option.name}
                options={modelsQuery.data ?? []}
              />
            </FormField>
          )}
        />

        <Controller
          name="flow_id"
          render={({ field }) => (
            <FormField
              label="Target agent"
              description="If omitted, runs all enabled agents."
            >
              <Combobox
                {...field}
                isClearable={true}
                optionValue={(option) => option.id}
                optionLabel={(option) => option.name}
                options={flows ?? []}
              />
            </FormField>
          )}
        />

        <Controller
          name="actionModelConfiguration"
          render={({ field }) => (
            <FormField
              label="Action model configuration overrides"
              error={actionModelConfigError}
            >
              <Column overflow="hidden" flex={1} height="200px">
                <Editor
                  language="json"
                  bg="base.background"
                  {...field}
                  onChange={(e) =>
                    actionModelConfigurationChangeHandler(e, field.onChange)
                  }
                />
              </Column>
            </FormField>
          )}
        />

        <Controller
          name="collectionModelConfiguration"
          render={({ field }) => (
            <FormField
              label="Collection model configuration overrides"
              error={collectionModelConfigError}
            >
              <Column overflow="hidden" flex={1} height="200px">
                <Editor
                  language="json"
                  bg="base.background"
                  {...field}
                  onChange={(e) =>
                    collectionModelConfigurationChangeHandler(e, field.onChange)
                  }
                />
              </Column>
            </FormField>
          )}
        />

        <Controller
          name="banditConfiguration"
          render={({ field }) => (
            <FormField label="Bandit configuration overrides">
              <Column overflow="hidden" flex={1} height="200px">
                <Editor
                  language="json"
                  bg="base.background"
                  {...field}
                  onChange={(e) => field.onChange(e)}
                />
              </Column>
            </FormField>
          )}
        />

        <Controller
          name="timing"
          render={({ field }) => (
            <FormField label="Timing overrides">
              <Column overflow="hidden" flex={1} height="200px">
                <Editor language="json" bg="base.background" {...field} />
              </Column>
            </FormField>
          )}
        />

        <Controller
          name="forceAllUsers"
          render={({ field }) => (
            <FormField label="Force all users">
              <Checkbox
                isChecked={field.value}
                isDisabled={false}
                onChange={field.onChange}
              />
            </FormField>
          )}
        />

        <Controller
          name="messageCompletionIntervalDays"
          render={({ field }) => (
            <FormField label="Days to complete message">
              <TextInput {...field} type="number" />
            </FormField>
          )}
        />

        <Controller
          name="stackOverride"
          render={({ field }) => (
            <FormField
              label="Target Stack Override"
              description="If provided, the stack will be overridden for this run. Default is production."
            >
              <Select
                {...field}
                options={[
                  { label: "Production", value: "production" },
                  // Before adding Canaries to this list, ensure their infra has been deployed
                  // and CircleCI has been configured.
                  { label: "Canary 1", value: "canary_1" },
                  { label: "Canary 2", value: "canary_2" },
                ]}
              />
            </FormField>
          )}
        />

        <Row>
          <Button
            variant="primary"
            isDisabled={
              dryRunEngine.isLoading ||
              modelsQuery.isLoading ||
              actionModelConfigError !== undefined
            }
            onClick={async () => {
              await form.submit();
            }}
          >
            Run dry run
          </Button>
        </Row>
      </Card>
    </Form>
  );
};

const ChannelTags = () => {
  const { engine } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const [tagsError, setTagsError] = useState("");
  const [shouldPoll, setShouldPoll] = useState(false);
  const [channel, setChannel] = useState("");

  const { data: jobId } = useGetTagsForMessagesBackgroundQuery(
    {
      decisionEngineChannelId: channel,
    },
    {
      select: (data) => data.getTagsForMessagesBackground,
      enabled: channel?.length > 0,
    },
  );

  const [tags, setTags] = useState<any>();

  useEffect(() => {
    setShouldPoll(true);
  }, [jobId]);

  useRunSqlResultQuery(
    {
      jobId: String(jobId),
      page: 0,
    },
    {
      enabled: Boolean(jobId),
      refetchInterval: shouldPoll ? 1000 : 0,
      onError: () => {
        setShouldPoll(false);
        toast({
          id: "tags",
          title: "Error",
          message: tagsError,
          variant: "error",
        });
      },
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }
        if (
          data.backgroundPreviewQueryResult.__typename === "FailedQueryResponse"
        ) {
          setTagsError(data.backgroundPreviewQueryResult.error);
        } else {
          const parsedTags = parseTagsFromSource(data);
          setTags(parsedTags);
        }
        setShouldPoll(false);
        setChannel("");
      },
    },
  );

  const form = useHightouchForm({
    onSubmit: async (data) => {
      setChannel(data.channel);
    },
  });

  return (
    <>
      <Form form={form}>
        <Card gap={4}>
          <Column>
            <Heading>Get tags for channel</Heading>
          </Column>
          <Controller
            name="channel"
            render={({ field }) => (
              <FormField label="Channels">
                <Select
                  {...field}
                  options={engine.channels}
                  optionValue={(value) => value.id}
                  optionLabel={(value) => value.type}
                />
              </FormField>
            )}
          />

          <Row>
            <Button variant="primary" onClick={form.submit} size="lg">
              Get tags
            </Button>
          </Row>

          {tags && JSON.stringify(tags, null, 2)}
        </Card>
      </Form>
    </>
  );
};

const MessageTags = () => {
  const { messages } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const [tagsError, setTagsError] = useState("");
  const [shouldPoll, setShouldPoll] = useState(false);
  const [message, setMessage] = useState("");
  const [messageTags, setMessageTags] = useState<any>();
  const [tags, setTags] = useState("");

  const form = useHightouchForm({
    onSubmit: async (data) => {
      setMessage(data.message);
      setTags(JSON.parse(data.tags));
    },
  });

  const { data: jobId } = useAnalyzeDecisionEngineTagsForMessageQuery(
    {
      messageId: message,
      tags,
    },
    {
      select: (data) => data.analyzeDecisionEngineTagsForMessage,
      enabled: message?.length > 0,
    },
  );

  useEffect(() => {
    setShouldPoll(true);
  }, [jobId]);

  useRunSqlResultQuery(
    {
      jobId: String(jobId),
      page: 0,
    },
    {
      enabled: Boolean(jobId),
      refetchInterval: shouldPoll ? 1000 : 0,
      onError: () => {
        setShouldPoll(false);
        toast({
          id: "tags",
          title: "Error",
          message: tagsError,
          variant: "error",
        });
      },
      onSuccess: (data) => {
        if (!data.backgroundPreviewQueryResult) {
          return;
        }
        if (
          data.backgroundPreviewQueryResult.__typename === "FailedQueryResponse"
        ) {
          setTagsError(data.backgroundPreviewQueryResult.error);
        } else {
          const parsedTags = parseTagsFromSource(data);
          setMessageTags(parsedTags);
        }
        setShouldPoll(false);
        setMessage("");
      },
    },
  );

  return (
    <Form form={form}>
      <Card gap={4}>
        <Column>
          <Heading>Tags for Message</Heading>
        </Column>

        <Controller
          name="message"
          render={({ field }) => (
            <FormField label="Message">
              <Select
                {...field}
                options={messages}
                optionValue={(value) => value.id}
                optionLabel={(value) => value.name}
              />
            </FormField>
          )}
        />

        <Controller
          name="tags"
          render={({ field }) => (
            <FormField label="Tags">
              <Textarea {...field} />
            </FormField>
          )}
        />

        <Row>
          <Button variant="primary" onClick={form.submit} size="lg">
            Get tags
          </Button>
        </Row>

        {messageTags && JSON.stringify(messageTags, null, 2)}
      </Card>
    </Form>
  );
};

export function parseTagsFromSource(data: RunSqlResultQuery) {
  if (
    !data ||
    data.backgroundPreviewQueryResult?.__typename === "FailedQueryResponse" ||
    !data.backgroundPreviewQueryResult
  ) {
    return null;
  }

  const rawData = Object.values(
    data.backgroundPreviewQueryResult.rows[0],
  ) as any;
  if (typeof rawData[0] === "string") {
    const dataAsObject = JSON.parse(rawData[0]);
    const choices = dataAsObject.choices[0];
    // Remove any ` backticks from the string
    const cleanedChoices = choices.replace(/`/g, "").replace("json", "");
    const parsedTags = JSON.parse(cleanedChoices);

    return parsedTags;
  }

  return JSON.parse(rawData[0]["choices"][0]["messages"]);
}

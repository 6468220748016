import { Column, FormField, Heading, TextInput } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useOutletContext } from "src/router";
import { useUpdateEventWarehouseDestinationMutation } from "src/graphql";
import { OutletContext } from "./types";
import { FormBar } from "src/components/form/form-bar";
import { Form, useHightouchForm } from "src/components/form";
import { FormSection } from "src/components/form-section";
import { SwitchFormField } from "src/components/switch-form-field";

const schema = yup.object({
  schemaName: yup.string().required(),
  // These are false by default.
  writePagesAndScreensToTracks: yup.boolean().optional(),
  // These are enabled by default.
  enableTrackSubtables: yup.boolean().optional(),
  enableJsonExplosion: yup.boolean().optional(),
});

type SchemaSettings = yup.InferType<typeof schema>;

export const SchemaSettings = () => {
  const { type, destination } = useOutletContext<OutletContext>();

  const { mutateAsync: update } = useUpdateEventWarehouseDestinationMutation();

  if (type === "forwarding") {
    return null;
  }

  const form = useHightouchForm({
    values: destination.schema_settings as SchemaSettings,
    resolver: yupResolver(schema),
    onSubmit: async (data) => {
      const updatedSchemaSettings = {
        ...destination.schema_settings,
        ...data,
      };
      await update({
        id: destination.id,
        input: {
          schema_settings: updatedSchemaSettings,
        },
      });
    },
  });

  return (
    <Form form={form}>
      <Column gap={6}>
        <Heading>Schema settings</Heading>

        <Controller
          name="schemaName"
          render={({ field }) => (
            <FormSection>
              <FormField
                label="Schema name"
                description="Customize the schema Hightouch uses to write events into your warehouse. By default, the schema name is based on the Event Source name."
              >
                <TextInput {...field} placeholder="Add schema name..." />
              </FormField>
            </FormSection>
          )}
        />
        <Controller
          name="enableTrackSubtables"
          render={({ field }) => (
            <FormSection>
              <SwitchFormField
                label="Per-event tables"
                description="When disabled, Hightouch only writes to the tracks table, and includes the full event properties on the tracks table."
                aria-label="Switch creating a table per-event type."
                isChecked={field.value ?? true}
                onChange={field.onChange}
              />
            </FormSection>
          )}
        />
        <Controller
          name="enableJsonExplosion"
          render={({ field }) => (
            <FormSection>
              <SwitchFormField
                label="Convert properties and context fields to columns"
                description="When disabled, Hightouch writes the event properties and
                  context as JSON. This makes some SQL analysis simpler, but
                  makes it harder to tell what fields are defined for each
                  event."
                aria-label="Convert properties and context fields to columns."
                isChecked={field.value ?? true}
                onChange={field.onChange}
              />
            </FormSection>
          )}
        />
        <Controller
          name="writePagesAndScreensToTracks"
          render={({ field }) => (
            <FormSection>
              <SwitchFormField
                label="Write Page and Screen events to the Tracks table"
                description="This is helpful when analyzing all user interactions in a
                  single table. By default, Page and Screen events are written to separate tables."
                aria-label="Enable writing 'page' and 'screen' events to the 'tracks' table."
                isChecked={Boolean(field.value)}
                onChange={field.onChange}
              />
            </FormSection>
          )}
        />
      </Column>
      <FormBar
        permission={{
          v1: { resource: "workspace", grant: "update" },
          v2: {
            resource: "workspace",
            grant: "can_update",
          },
        }}
      />
    </Form>
  );
};

import { FC } from "react";

import { Column, Text } from "@hightouchio/ui";

import { MAX_CARD_WIDTH } from "src/pages/journeys/constants";

import { NODE_LIBRARY_OPTIONS } from "./constants";
import { NodeOption } from "./node-option";
import { JourneyNodeCategory } from "./types";

export const NodeLibrary: FC = () => {
  return (
    <Column
      width={`${MAX_CARD_WIDTH}px`}
      py={4}
      gap={2}
      justify="space-between"
      bg="white"
      border="1px solid"
      borderColor="base.divider"
      borderRadius="md"
      boxShadow="md"
    >
      <Text size="lg" fontWeight="semibold" mx="4">
        Tile library
      </Text>
      <Text size="sm" mx="4" color="text.secondary">
        Drag tiles onto the canvas to build your journey
      </Text>

      <Column as="ul" p={0}>
        <Text
          size="md"
          color="text.secondary"
          mx={4}
          my={2}
          fontWeight="semibold"
          textTransform="uppercase"
        >
          Activation
        </Text>
        {NODE_LIBRARY_OPTIONS.filter(
          (option) => option.category === JourneyNodeCategory.Activation,
        ).map((option) => (
          <NodeOption key={option.label} {...option} />
        ))}

        <Text
          size="md"
          color="text.secondary"
          mx={4}
          my={2}
          fontWeight="semibold"
          textTransform="uppercase"
        >
          Waits
        </Text>
        {NODE_LIBRARY_OPTIONS.filter(
          (option) => option.category === JourneyNodeCategory.Waits,
        ).map((option) => (
          <NodeOption key={option.label} {...option} />
        ))}

        <Text
          size="md"
          color="text.secondary"
          mx={4}
          my={2}
          fontWeight="semibold"
          textTransform="uppercase"
        >
          Flow control
        </Text>
        {NODE_LIBRARY_OPTIONS.filter(
          (option) => option.category === JourneyNodeCategory.FlowControl,
        ).map((option) => (
          <NodeOption key={option.label} {...option} />
        ))}
      </Column>
    </Column>
  );
};

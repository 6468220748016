import { Controller, useFieldArray, type SubmitHandler } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Column,
  DeleteIcon,
  FormField,
  IconButton,
  PlusIcon,
  Row,
  Switch,
  TextInput,
  Text,
} from "@hightouchio/ui";

import { Form, useHightouchForm } from "src/components/form";
import type { EventSourceFormProps } from "./types";
import { FormBar } from "src/components/form/form-bar";
import { Card } from "src/components/card";

type WebhookConfig = {
  eventName: string | { column: string };
  headers: string[];
};

const schema = yup.object().shape({
  eventName: yup.lazy((val) =>
    typeof val === "string"
      ? yup.string()
      : yup
          .object()
          .shape({
            column: yup.string().required("Event name cannot be empty"),
          })
          .required(),
  ),
  headers: yup.array(yup.string().required("Header cannot be empty")),
});

export default function WebhookForm({
  id,
  mode,
  source,
  onSubmit,
}: EventSourceFormProps) {
  const handleSubmit: SubmitHandler<WebhookConfig> = (config) => {
    return onSubmit({
      config,
      tunnelId: null,
      credentialId: null,
    });
  };

  const form = useHightouchForm<WebhookConfig>({
    resolver: yupResolver(schema),
    values: {
      eventName: "",
      headers: [],
      ...source?.config,
    },
    onSubmit: (data) => handleSubmit(data),
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "headers" as never, // react-hook-form types are wrong
  });

  return (
    <Form form={form}>
      <form id={id} onSubmit={form.handleSubmit(handleSubmit)}>
        <Column gap={6}>
          <Card>
            <Controller
              name="eventName"
              control={form.control}
              render={({ field, fieldState }) => {
                const isColumn =
                  field.value != null && typeof field.value !== "string";

                const error =
                  fieldState.error?.message ||
                  (isColumn && (fieldState.error as any)?.column?.message);

                return (
                  <FormField
                    label="Event Name"
                    error={error}
                    description={
                      isColumn
                        ? "The field used to parse the event name from the request body."
                        : "The event name to use for all events."
                    }
                    tip={
                      isColumn && !error
                        ? "Use dot notation for nested data access."
                        : undefined
                    }
                    isOptional={!isColumn}
                  >
                    <Box display="flex" alignItems="center" gap={4}>
                      <TextInput
                        {...field}
                        placeholder={
                          isColumn ? "data.event.name" : `${source.name} Event`
                        }
                        value={
                          isColumn
                            ? (field.value as { column: string }).column
                            : (field.value as string)
                        }
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          field.onChange(isColumn ? { column: value } : value);
                        }}
                        isInvalid={Boolean(error)}
                      />

                      <Box display="flex" alignItems="center" gap={2}>
                        <Text
                          textTransform="uppercase"
                          size="sm"
                          fontWeight="semibold"
                          color="text.secondary"
                        >
                          Use column
                        </Text>
                        <Switch
                          aria-label="Use column."
                          isChecked={isColumn}
                          onChange={(isColumn) =>
                            field.onChange(isColumn ? { column: "" } : "")
                          }
                        />
                      </Box>
                    </Box>
                  </FormField>
                );
              }}
            />
          </Card>
          <Card>
            <FormField
              label="Header Allowlist"
              description="Specify any HTTP headers you want to include in the event payload."
              isOptional
            >
              <Column mt={4} gap={4}>
                {fields.map((field, idx) => (
                  <Row key={field.id} align="center" gap={4}>
                    <Controller
                      name={`headers.${idx}`}
                      control={form.control}
                      render={({ field, fieldState: { error } }) => (
                        <TextInput
                          {...field}
                          isInvalid={Boolean(error)}
                          placeholder="Header-Name"
                        />
                      )}
                    />
                    <IconButton
                      type="button"
                      variant="danger"
                      aria-label="Delete condition"
                      icon={DeleteIcon}
                      onClick={() => remove(idx)}
                    />
                  </Row>
                ))}
                <Row>
                  <Button
                    type="button"
                    variant="secondary"
                    icon={PlusIcon}
                    onClick={() => append("")}
                  >
                    Add header
                  </Button>
                </Row>
              </Column>
            </FormField>
          </Card>
        </Column>
      </form>
      {mode === "edit" && (
        <FormBar
          permission={{
            v1: { resource: "workspace", grant: "update" },
            v2: {
              resource: "workspace",
              grant: "can_update",
            },
          }}
        />
      )}
    </Form>
  );
}

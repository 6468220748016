import { FC } from "react";

import {
  ClipboardButton,
  Column,
  FormField,
  Row,
  TextInput,
} from "@hightouchio/ui";
import { UnreachableCaseError } from "ts-essentials";

import { useOutletContext } from "src/router";
import { EventSourceType } from "src/events/types";
import { FormSection } from "src/components/form-section";
import { useEventDebuggerQuery } from "src/graphql";
import { useRegionConfig } from "src/utils/use-region-config";
import { SetupAndroid } from "./samples/android";
import { SetupHttp } from "./samples/http";
import { SetupIOS } from "./samples/ios";
import { SetupJava } from "./samples/java";
import { SetupJavascript } from "./samples/javascript";
import { SetupNode } from "./samples/node";
import { SetupPython } from "./samples/python";
import { SetupRuby } from "./samples/ruby";
import { SetupGo } from "./samples/go";
import { SetupPHP } from "./samples/php";
import { SetupReactNative } from "./samples/react-native";
import { SetupCSharp } from "./samples/csharp";
import { SetupWebhook } from "./samples/webhook";
import { SetupKafka } from "./samples/kafka";
import { SetupConfluentKafka } from "./samples/confluent-kafka";
import { SetupPubSub } from "./samples/pubsub";
import { SetupFlutter } from "./samples/flutter";
import { SetupProps } from "./samples/types";
import { OutletContext } from ".";

export const Setup: FC = () => {
  const { source } = useOutletContext<OutletContext>();
  const sourceType = source.type as EventSourceType;

  /**
   * Mimic the user being "in the debugger".
   * If they test their source and _then_ go to the debugger,
   * before the presenceKey TTL, we'll already have some events for them from earlier.
   */
  useEventDebuggerQuery({ input: { sourceId: source.id } });

  const { endpoint: apiHost } = useRegionConfig().events;

  return (
    <Column gap={6}>
      {getSetup({
        sourceType,
        writeKey: source.write_key ?? "<WRITE_KEY>",
        sourceId: source.id ?? "<SOURCE_ID>",
        apiHost,
      })}

      {(source.write_key || apiHost) && (
        <FormSection>
          {source.write_key && (
            <FormField
              label="Write key"
              description="The write key is already included in the snippet above."
            >
              <Row align="center" gap={2}>
                <TextInput width="lg" isReadOnly value={source.write_key} />
                <ClipboardButton text={source.write_key} />
              </Row>
            </FormField>
          )}
          {apiHost && (
            <FormField
              label="API Host"
              description="The API Host is already included in the snippet above."
            >
              <Row align="center" gap={2}>
                <TextInput width="lg" isReadOnly value={apiHost} />
                <ClipboardButton text={apiHost} />
              </Row>
            </FormField>
          )}
        </FormSection>
      )}
    </Column>
  );
};

const getSetup = (params: SetupProps) => {
  switch (params.sourceType) {
    case EventSourceType.NODE:
      return <SetupNode {...params} />;
    case EventSourceType.IOS:
      return <SetupIOS {...params} />;
    case EventSourceType.ANDROID:
      return <SetupAndroid {...params} />;
    case EventSourceType.RUBY:
      return <SetupRuby {...params} />;
    case EventSourceType.GO:
      return <SetupGo {...params} />;
    case EventSourceType.PHP:
      return <SetupPHP {...params} />;
    case EventSourceType.REACT_NATIVE:
      return <SetupReactNative {...params} />;
    case EventSourceType.JAVA:
      return <SetupJava {...params} />;
    case EventSourceType.HTTP:
      return <SetupHttp {...params} />;
    case EventSourceType.PYTHON:
      return <SetupPython {...params} />;
    case EventSourceType.JAVASCRIPT:
      return <SetupJavascript {...params} />;
    case EventSourceType.CSHARP:
      return <SetupCSharp {...params} />;
    case EventSourceType.WEBHOOK:
      return <SetupWebhook {...params} />;
    case EventSourceType.KAFKA:
      return <SetupKafka {...params} />;
    case EventSourceType.CONFLUENT_KAFKA:
      return <SetupConfluentKafka {...params} />;
    case EventSourceType.PUBSUB:
      return <SetupPubSub {...params} />;
    case EventSourceType.FLUTTER:
      return <SetupFlutter {...params} />;
    default:
      throw new UnreachableCaseError(params.sourceType);
  }
};

import {
  Box,
  Text,
  Row,
  Column,
  FormField,
  Checkbox,
  CheckboxGroup,
  Heading,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller } from "react-hook-form";

import { Link, useParams } from "src/router";
import { ActionBar } from "src/components/action-bar";
import { FeatureFull } from "src/components/feature-gates";
import { usePermissionContext } from "src/components/permission/permission-context";
import { useUser } from "src/contexts/user-context";
import { useSourceQuery, useUpdateSourceV2Mutation } from "src/graphql";
import { FormActions, useHightouchForm, Form } from "src/components/form";
import { DocsLink } from "src/components/docs-link";

export type SourceWarehouseHistoryConfig = {
  changelog?: {
    audience: boolean;
  } | null;
  snapshot?: {
    audience: boolean;
  } | null;
  syncRuns?: {
    audience: boolean;
  } | null;
  shardBySyncId?: boolean | null;
};

export const SourceSyncLog = () => {
  const { id } = useParams<{ id: string }>();
  const { data: source } = useSourceQuery(
    { id: String(id) },
    { select: (data) => data.connections_by_pk, suspense: true },
  );

  const { workspace } = useUser();
  const updatePermission = usePermissionContext();
  const {
    appWarehouseSyncLogsEnabled,
    appAudienceHoldoutEnabled,
    appWarehouseSyncLogsShardingEnabled,
  } = useFlags();

  const form = useHightouchForm({
    onSubmit: (data) =>
      updateSource({
        id: String(source!.id),
        source: data,
        append: {},
      }),
    defaultValues: {
      warehouse_history_config: {
        changelog: source?.warehouse_history_config?.changelog
          ? {
              audience: Boolean(
                source?.warehouse_history_config?.changelog?.audience,
              ),
            }
          : undefined,
        snapshot: source?.warehouse_history_config?.snapshot
          ? {
              audience: Boolean(
                source?.warehouse_history_config?.snapshot?.audience,
              ),
            }
          : undefined,
        syncRuns: source?.warehouse_history_config?.syncRuns
          ? {
              audience: Boolean(
                source?.warehouse_history_config?.syncRuns?.audience,
              ),
            }
          : undefined,
        shardBySyncId: source?.warehouse_history_config?.shardBySyncId,
      } satisfies SourceWarehouseHistoryConfig,
      audience_snapshotting_enabled: source!.audience_snapshotting_enabled,
      audience_holdout_enabled: source!.audience_holdout_enabled,
    },
  });

  const { mutateAsync: updateSource } = useUpdateSourceV2Mutation();

  const { control } = form;

  return (
    <FeatureFull
      enabled={
        workspace?.organization?.plan?.sku === "business_tier" ||
        appWarehouseSyncLogsEnabled
      }
      featureDetails={{
        pitch: "Row-level sync observability right in your data warehouse",
        description:
          "With warehouse sync logs, Hightouch will write your sync results back into your data warehouse provider. You can then use your own BI tool or SQL editor to run custom analysis or monitor the health of your pipelines.",
        bullets: [
          "Audit whether individual records were successfully synced to your business tool",
          "Monitor for common sync errors and create custom alerts",
          "Integrate with your existing BI dashboards to visualize usage trends",
          "Recommended for businesses that wish to bring their own observability tooling",
        ],
        video: {
          src: "https://cdn.sanity.io/files/pwmfmi47/production/c81b1d3e32df55f57ca5cdec94543144baaebad4.mp4",
        },
      }}
      featureName="warehouse sync logs"
    >
      <Form form={form}>
        <Column gap={6}>
          <Box>
            <Row mb={2} align="center" justifyContent="space-between">
              <Heading>Warehouse sync logs</Heading>
              <DocsLink
                name="warehouse sync logs"
                href="/syncs/warehouse-sync-logs"
              />
            </Row>
            <Column>
              <Text>
                Warehouse sync logs writes information on the status of each row
                of your sync back into your warehouse.
              </Text>
              <Text>
                Specific use cases include{" "}
                <Link
                  href={`${
                    import.meta.env.VITE_DOCS_URL
                  }/syncs/warehouse-sync-logs/#get-the-most-common-sync-error`}
                >
                  analyzing common syncs errors
                </Link>
                ,{" "}
                <Link
                  href={`${
                    import.meta.env.VITE_DOCS_URL
                  }/syncs/warehouse-sync-logs/#track-when-users-entered-and-exited-a-model`}
                >
                  visualizing when users enter and exit audiences
                </Link>
                , and{" "}
                <Link
                  href={`${
                    import.meta.env.VITE_DOCS_URL
                  }/syncs/warehouse-sync-logs/#get-the-current-rows-in-all-models`}
                >
                  tracking the status of rows across syncs
                </Link>
                .
              </Text>
            </Column>
          </Box>
          <FormField
            mt={8}
            description="Choose what you would like to track in the warehouse. The data will be written after each sync run completes."
            label="Tables"
          >
            <CheckboxGroup>
              <Controller
                name="warehouse_history_config.snapshot"
                control={control}
                render={({ field }) => (
                  <>
                    <Checkbox
                      description="Tracks the latest status of each row in the sync. This is useful for understanding the overall health of your syncs."
                      isChecked={Boolean(field.value)}
                      isDisabled={updatePermission.unauthorized}
                      label="Sync snapshots"
                      onChange={(value) => {
                        field.onChange(value.target.checked ? {} : null);
                      }}
                    />
                    {field.value && (
                      <Box pl={6}>
                        <Checkbox
                          description="Only enable this sync log table for audience syncs"
                          isChecked={Boolean(field.value.audience)}
                          isDisabled={updatePermission.unauthorized}
                          label="Audience syncs only"
                          onChange={(value) => {
                            field.onChange({
                              audience: value.target.checked,
                            });
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
              />

              <Controller
                control={control}
                name="warehouse_history_config.changelog"
                render={({ field }) => (
                  <>
                    <Checkbox
                      description="Tracks every operation performed by Hightouch. Includes the result of the operation, as well as any error messages from syncing."
                      isChecked={Boolean(field.value)}
                      isDisabled={updatePermission.unauthorized}
                      label="Changelog"
                      onChange={(value) => {
                        field.onChange(value.target.checked ? {} : null);
                      }}
                    />
                    {field.value && (
                      <Box pl={6}>
                        <Checkbox
                          description="Only enable this sync log table for audience syncs"
                          isChecked={Boolean(field.value.audience)}
                          isDisabled={updatePermission.unauthorized}
                          label="Audience syncs only"
                          onChange={(event) => {
                            field.onChange({
                              audience: event.target.checked,
                            });
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
              />

              <Controller
                control={control}
                name="warehouse_history_config.syncRuns"
                render={({ field }) => (
                  <>
                    <Checkbox
                      description="Contains a log of all the sync runs. The changelog and snapshot tables can be JOINed to this table for more information on when the sync occurred and how it was configured."
                      isChecked={Boolean(field.value)}
                      isDisabled={updatePermission.unauthorized}
                      label="Sync runs"
                      onChange={(event) => {
                        field.onChange(event.target.checked ? {} : null);
                      }}
                    />
                    {field.value && (
                      <Box pl={6}>
                        <Checkbox
                          description="Only enable this sync log table for audience syncs"
                          isChecked={Boolean(field.value.audience)}
                          isDisabled={updatePermission.unauthorized}
                          label="Audience syncs only"
                          onChange={(event) => {
                            field.onChange({
                              audience: event.target.checked,
                            });
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
              />

              {appWarehouseSyncLogsShardingEnabled && (
                <Controller
                  name="warehouse_history_config.shardBySyncId"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      description="For sync snapshot and changelog data, create a
	                            separate table for each sync rather than a single
	                            snapshot table and a single changelog table. This
	                            avoids data warehouse concurrency limits per table
	                            which may impact customers with extremely high sync
	                            volumes."
                      isChecked={Boolean(field.value)}
                      isDisabled={updatePermission.unauthorized}
                      label="Shard snapshots and changelogs by sync id"
                      onChange={field.onChange}
                    />
                  )}
                />
              )}

              <Controller
                name="audience_snapshotting_enabled"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    description={
                      <>
                        Save snapshots of audience member IDs in your data
                        warehouse after each sync of an audience. Check out{" "}
                        <Link href="https://hightouch.com/docs/customer-studio/audience-snapshots">
                          the docs
                        </Link>{" "}
                        to learn more.
                      </>
                    }
                    isChecked={Boolean(field.value)}
                    isDisabled={updatePermission.unauthorized}
                    label="Audience snapshots"
                    onChange={field.onChange}
                  />
                )}
              />

              {appAudienceHoldoutEnabled && (
                <Controller
                  name="audience_holdout_enabled"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      description={
                        <>
                          Save rows that were not synced to the destination
                          after each sync of an audience. Check out{" "}
                          <Link href="https://hightouch.com/docs/customer-studio/holdout-group-logs">
                            the docs
                          </Link>{" "}
                          to learn more.
                        </>
                      }
                      isChecked={Boolean(field.value)}
                      isDisabled={updatePermission.unauthorized}
                      label="Audience holdout group logs"
                      onChange={field.onChange}
                    />
                  )}
                />
              )}
            </CheckboxGroup>
          </FormField>
          <ActionBar>
            <FormActions
              permission={{
                v2: {
                  resource: "source",
                  grant: "can_update",
                  id: source!.id,
                },
              }}
            />
          </ActionBar>
        </Column>
      </Form>
    </FeatureFull>
  );
};

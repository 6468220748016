import { FC } from "react";

import { CodeSnippet, Heading, Text } from "@hightouchio/ui";

import { Link } from "src/router";

import { SetupProps } from "./types";

function getWebhookCurlCode(webhookUrl: string): string {
  return `
  curl -X POST \\
    -H "Content-type: application/json" \\
    -d '{ "name": "Order Completed" }' \\
    '${webhookUrl}'
  `;
}

export const SetupWebhook: FC<SetupProps> = (props) => {
  const webhookUrl = `https://${props.apiHost}/webhooks/${props.writeKey}`;

  return (
    <>
      <Heading>Webhook Details</Heading>
      <Text>
        Your webhook is setup to receive HTTP requests at the URL below. You can
        edit the configuration <Link href="../configuration">here</Link>.
      </Text>
      <CodeSnippet label="Webhook URL" code={webhookUrl} />
      <Heading>Using the webhook</Heading>
      <CodeSnippet label="Terminal" code={getWebhookCurlCode(webhookUrl)} />
    </>
  );
};

import { isEqual } from "lodash";
import { isPresent } from "ts-extras";

import { getPropertyNameFromColumn } from "src/components/explore/visual/utils";
import {
  ColumnType,
  Condition,
  ConditionType,
  DefaultOperators,
  EventCondition,
  type JourneyEventColumn,
  initialEventCondition,
  initialNumberOfCondition,
  initialPropertyCondition,
  initialSetCondition,
  isRelatedJourneyEventColumn,
  isMergedColumn,
  isRelatedColumn,
  isTraitColumn,
  isTransformedColumn,
  NumberOfCondition,
  PropertyCondition,
  SegmentSetCondition,
  TraitType,
} from "src/types/visual";

import {
  AudienceColumn,
  EventColumn,
  FilterOption,
  GlobalColumn,
  InlineTraitColumn,
  MetadataType,
  PropertyColumn,
  RelationColumn,
  TraitColumn,
  TraitTemplateColumn,
} from "./constants";
import { FilterColumnOption } from "./types";

export const getIconProps = (
  type: FilterOption | null | undefined,
  options?: { invertColors?: boolean },
):
  | {
      color?: string;
      bg: string;
      icon: JSX.Element;
    }
  | undefined => {
  if (!type) {
    return undefined;
  }

  switch (type) {
    case FilterOption.All:
      return {
        color: options?.invertColors ? GlobalColumn.color : undefined,
        bg: options?.invertColors ? "white" : GlobalColumn.color,
        icon: GlobalColumn.icon,
      };
    case FilterOption.Property:
      return {
        color: options?.invertColors ? PropertyColumn.color : undefined,
        bg: options?.invertColors ? "white" : PropertyColumn.color,
        icon: PropertyColumn.icon,
      };
    case FilterOption.Relation:
      return {
        color: options?.invertColors ? RelationColumn.color : undefined,
        bg: options?.invertColors ? "white" : RelationColumn.color,
        icon: RelationColumn.icon,
      };
    case FilterOption.Event:
    case FilterOption.EventProperty:
      return {
        color: options?.invertColors ? EventColumn.color : undefined,
        bg: options?.invertColors ? "white" : EventColumn.color,
        icon: EventColumn.icon,
      };
    case FilterOption.Audience:
      return {
        color: options?.invertColors ? AudienceColumn.color : undefined,
        bg: options?.invertColors ? "white" : AudienceColumn.color,
        icon: AudienceColumn.icon,
      };
    case FilterOption.Trait:
      return {
        color: options?.invertColors ? TraitColumn.color : undefined,
        bg: options?.invertColors ? "white" : TraitColumn.color,
        icon: TraitColumn.icon,
      };
    case FilterOption.TraitTemplate:
      return {
        color: options?.invertColors ? TraitTemplateColumn.color : undefined,
        bg: options?.invertColors ? "white" : TraitTemplateColumn.color,
        icon: TraitTemplateColumn.icon,
      };
    case FilterOption.InlineTrait:
      return {
        color: InlineTraitColumn.color,
        bg: "white",
        icon: InlineTraitColumn.icon,
      };
  }
};

export const getSearchPlaceholderText = (filterType: FilterOption) => {
  switch (filterType) {
    case FilterOption.All:
      return "Search all filters...";
    case FilterOption.Property:
      return "Search properties...";
    case FilterOption.Relation:
      return "Search relations...";
    case FilterOption.Event:
    case FilterOption.EventProperty:
      return "Search events...";
    case FilterOption.Audience:
      return "Search audiences...";
    case FilterOption.Trait:
    case FilterOption.TraitTemplate:
    case FilterOption.InlineTrait:
      return "Search traits...";
  }
};

export const isColumnSelected = <TCondition extends Condition>(
  condition: TCondition | undefined,
  column: FilterColumnOption,
) => {
  if (!condition) {
    return false;
  }

  switch (column.type) {
    case FilterOption.Property:
      return (
        condition.type === ConditionType.Property &&
        isEqual(condition.property, column.value)
      );
    case FilterOption.Relation:
      return (
        condition.type === ConditionType.NumberOf &&
        condition.relationshipId === column.value.relationshipId
      );
    case FilterOption.Event:
      return (
        condition.type === ConditionType.Event &&
        condition.eventModelId === column.value.eventModelId &&
        condition.relationshipId === column.value.relationshipId
      );
    case FilterOption.EventProperty:
      return (
        condition.type === ConditionType.Property &&
        isRelatedJourneyEventColumn(condition.property) &&
        condition.property.column.modelId === column.value.modelId
      );
    case FilterOption.Audience:
      return (
        condition.type === ConditionType.SegmentSet &&
        condition.modelId === column.value.modelId
      );
    case FilterOption.Trait:
    case FilterOption.TraitTemplate:
      return (
        condition.type === ConditionType.Property &&
        (isRelatedColumn(condition.property) ||
          isTransformedColumn(condition.property)) &&
        isTraitColumn(condition.property.column) &&
        column.value.column.type === "trait" &&
        condition.property.column.traitDefinitionId ===
          column.value.column.traitDefinitionId
      );
    default:
      return false;
  }
};

export const buildConditionFromColumn = (
  column: FilterColumnOption,
):
  | PropertyCondition
  | NumberOfCondition
  | EventCondition
  | SegmentSetCondition => {
  switch (column.type) {
    case FilterOption.Property:
    case FilterOption.Trait:
    case FilterOption.TraitTemplate:
      return {
        ...initialPropertyCondition,
        type: ConditionType.Property,
        propertyType: column.propertyType ?? null,
        property: column.value,
        propertyOptions: {
          caseSensitive:
            column.type === FilterOption.Property
              ? (column.case_sensitive ?? undefined)
              : undefined,
          traitType:
            column.type === FilterOption.TraitTemplate
              ? "trait_template"
              : column.type === FilterOption.Trait
                ? "trait"
                : undefined,
        },
        operator: column.propertyType
          ? DefaultOperators[column.propertyType]
          : null,
        value: null,
      };
    case FilterOption.EventProperty:
      // Event property column does not have any column information
      // default to the initial property condition
      return {
        ...initialPropertyCondition,
        type: ConditionType.Property,
        propertyType: null,
        property: {
          type: "related",
          path: [],
          column: {
            type: "journey_event",
            modelId: column.value.modelId,
            // Nothing to select yet
            name: "",
          } satisfies JourneyEventColumn,
        },
        propertyOptions: {},
        operator: null,
        value: null,
      };
    case FilterOption.InlineTrait:
      return {
        type: ConditionType.Property,
        property: {
          type: "related",
          column: {
            type: "inline_trait",
            traitType: TraitType.Average,
            traitConfig: {},
            conditions: [],
            relationshipId: "",
          },
          path: [],
        },
        propertyType: ColumnType.Unknown,
        propertyOptions: {
          traitType: "inline_trait",
        },
        operator: null,
        value: null,
      };
    case FilterOption.Relation:
      return {
        ...initialNumberOfCondition,
        relationshipId: column.value.relationshipId,
      };
    case FilterOption.Event:
      return {
        ...initialEventCondition,
        relationshipId: column.value.relationshipId,
        eventModelId: column.value.eventModelId,
      };
    case FilterOption.Audience:
      return {
        ...initialSetCondition,
        modelId: column.value.modelId,
      };
  }
};

export const getSelectedColumn = (
  condition: Condition | undefined,
  columnOptions: FilterColumnOption[],
) => {
  if (!condition) {
    return null;
  }

  switch (condition.type) {
    case ConditionType.Property:
      /** INLINE TRAIT COLUMN */
      // eslint-disable-next-line no-case-declarations
      const traitId =
        (isTransformedColumn(condition.property) ||
          isRelatedColumn(condition.property)) &&
        isTraitColumn(condition.property.column)
          ? condition.property.column.traitDefinitionId
          : null;

      if (traitId) {
        return columnOptions.find(
          ({ type, value }) =>
            [FilterOption.Trait, FilterOption.TraitTemplate].includes(type) &&
            (isTraitColumn(value.column) ||
              isTransformedColumn(value.column)) &&
            value.column.traitDefinitionId === traitId,
        );
      }

      /** JOURNEY EVENT COLUMN */
      // eslint-disable-next-line no-case-declarations
      const eventModelId = isRelatedJourneyEventColumn(condition.property)
        ? condition.property.column.modelId
        : null;

      if (eventModelId) {
        return columnOptions
          .filter(({ type }) => type === FilterOption.EventProperty)
          .find(({ value }) => value.modelId === eventModelId);
      }

      return columnOptions.find(
        ({ type, value }) =>
          type === FilterOption.Property && isEqual(value, condition.property),
      );
    case ConditionType.NumberOf:
      return columnOptions.find(
        ({ type, value }) =>
          type === FilterOption.Relation &&
          condition.relationshipId === value.relationshipId,
      );
    case ConditionType.Event:
      return columnOptions.find(
        ({ type, value }) =>
          type === FilterOption.Event &&
          value.eventModelId === condition.eventModelId &&
          value.relationshipId === condition.relationshipId,
      );
    case ConditionType.SegmentSet:
      return columnOptions.find(
        ({ type, value }) =>
          type === FilterOption.Audience && value.modelId === condition.modelId,
      );

    default:
      return null;
  }
};

export const getValueText = (condition: Condition) => {
  switch (condition.type) {
    case ConditionType.Property:
      if (typeof condition.property === "string") {
        return condition.property;
      }

      return getPropertyNameFromColumn(condition.property);
  }

  return undefined;
};

export const getSetupLink = (type: FilterOption, parentModelId?: number) => {
  switch (type) {
    case FilterOption.All:
    case FilterOption.Property:
    case FilterOption.Trait:
    case FilterOption.TraitTemplate:
      return isPresent(parentModelId)
        ? `/schema/parent-models/${parentModelId}`
        : "/schema/parent-models";
    case FilterOption.Relation:
      return "/schema/related-models/new";
    case FilterOption.Event:
    case FilterOption.EventProperty:
      return "/schema/events/new";
    case FilterOption.Audience:
      return "/audiences/new";
    case FilterOption.InlineTrait:
      return ""; // should never reach here because there is nothing to set up
  }
};

export const getMetadata = (column: FilterColumnOption | undefined | null) => {
  if (!column) {
    return null;
  }

  if (
    column.type === FilterOption.Trait ||
    column.type === FilterOption.TraitTemplate
  ) {
    // trait column
    return {
      modelName:
        column.value.type === "transformed"
          ? (column.parentModel?.name ?? "")
          : (column.relatedModel?.name ?? ""),
      type: column.relatedModel?.event
        ? MetadataType.Event
        : MetadataType.Relation,
    };
  } else if (
    column.type === FilterOption.Property &&
    isMergedColumn(column.value)
  ) {
    // merged column
    return {
      modelName: column.modelName,
      type: MetadataType.MergedColumn,
    };
  }

  return null;
};

export const getModelName = (
  column: FilterColumnOption | undefined | null,
): string | null | undefined => {
  if (!column) {
    return null;
  }

  switch (column.type) {
    case FilterOption.Trait:
      return column.relatedModel?.name;
    case FilterOption.Property:
      return column.modelName;
    default:
      return null;
  }
};

export function getRelationshipLabel(
  relationshipName: string | null,
  modelName: string,
) {
  if (!relationshipName && !modelName) {
    return "";
  }

  if (!relationshipName) {
    return modelName;
  }

  return `${modelName} (via ${relationshipName})`;
}

export function countRelationsByModel(
  relations: { to_model: { name: string } }[],
): Record<string, number> {
  return relations.reduce<Record<string, number>>((accum, event) => {
    const modelName = event.to_model.name;
    if (!(modelName in accum)) {
      accum[modelName] = 0;
    }

    accum[modelName]! += 1;
    return accum;
  }, {});
}

import { FC } from "react";

import { Column } from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";

import { ActionBar, ActionBarHeight } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { ScheduleManager } from "src/components/schedule";
import { useScheduleState } from "src/components/schedule/schedule-manager";
import { ScheduleType } from "src/components/schedule/types";
import { useUpdateIdentityResolutionGraphMutation } from "src/graphql";
import {
  OutputTable,
  useOutputTable,
} from "src/pages/identity-resolution/forms";
import {
  GraphVersion,
  IDRConfigurationFormSchema,
} from "src/pages/identity-resolution/utils";
import { useOutletContext } from "src/router";

import { OutletContext } from ".";

export const Configuration: FC = () => {
  const { graph } = useOutletContext<OutletContext>();

  const updateMutation = useUpdateIdentityResolutionGraphMutation();
  const { validateSchedule } = useScheduleState("identity graph");

  const form = useHightouchForm({
    values: {
      output_table: graph.output_table,
      schedule:
        graph.schedule === null
          ? { type: ScheduleType.MANUAL }
          : graph.schedule,
    },
    resolver: yupResolver(IDRConfigurationFormSchema),
    onSubmit: async (data) => {
      if (!validateSchedule(data.schedule)) {
        throw new Error("Invalid schedule");
      }

      await updateMutation.mutateAsync({
        input: {
          graphId: graph.id,
          outputTable: data.output_table,
          schedule: data.schedule?.type === "manual" ? null : data.schedule,
          schedulePaused: data.schedule === null,
          scheduleUpdatedAt:
            data.schedule !== graph.schedule
              ? new Date().toISOString()
              : undefined,
        },
      });
    },
  });

  const { validationState } = useOutputTable({
    outputTableName: form.watch("output_table"),
    graph,
  });

  return (
    <Form form={form}>
      <Column gap={6} maxW="2xl" mb={`${ActionBarHeight + 16}px`}>
        <OutputTable
          isIDRv2={graph.version === GraphVersion.V2}
          validationState={validationState}
        />

        <Controller
          name="schedule"
          control={form.control}
          render={({ field }) => {
            return (
              <ScheduleManager
                resource="identity graph"
                schedule={field.value || { type: "manual" }}
                setSchedule={field.onChange}
                types={[
                  ScheduleType.MANUAL,
                  ScheduleType.INTERVAL,
                  ScheduleType.CRON,
                  ScheduleType.CUSTOM,
                ]}
                includeStartAndEnd={false}
              />
            );
          }}
        />
      </Column>
      <ActionBar>
        <FormActions
          permission={{
            v2: {
              resource: "idr",
              grant: "can_update",
              id: graph.id,
            },
          }}
        />
      </ActionBar>
    </Form>
  );
};

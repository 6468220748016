import { FC, Suspense } from "react";

import { Outlet, useParams, useNavigate } from "src/router";
import * as Sentry from "@sentry/react";

import {
  Column,
  Menu,
  MenuActionsButton,
  MenuList,
  Row,
  useToast,
} from "@hightouchio/ui";
import { InnerScrollableDetailPage } from "src/components/layout";
import { Warning } from "src/components/warning";
import { PageSpinner } from "src/components/loading";

import {
  FunctionsSetInput,
  useDeleteFunctionMutation,
  useFunctionVersionLatestQuery,
  useUpdateFunctionMutation,
} from "src/graphql";
import {
  PermissionedDeleteButton,
  PermissionedEditableDescription,
  PermissionedEditableHeading,
} from "src/components/permission";

export const FunctionLayout: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const deleteFunctionMutation = useDeleteFunctionMutation();
  const updateFunctionMutation = useUpdateFunctionMutation();

  const { data: fn } = useFunctionVersionLatestQuery(
    {
      id: String(id),
    },
    {
      enabled: Boolean(id),
      select: (data) => data.functions?.[0],
      suspense: true,
    },
  );

  if (!fn) {
    return (
      <Warning subtitle="It may have been deleted" title="Function not found" />
    );
  }

  const updateFunction = async (object: FunctionsSetInput) => {
    try {
      await updateFunctionMutation.mutateAsync({
        id: fn.id,
        versionNumber: fn.version_number,
        object,
      });

      toast({
        id: "update-function",
        title: "Function updated",
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "update-function",
        title: "Failed to update function",
        message: error.message,
        variant: "error",
      });
    }
  };

  return (
    <InnerScrollableDetailPage
      tabDepth={5}
      title={`Edit function | ${fn.name}`}
      crumbs={[{ label: "All functions", link: "/events/functions" }]}
      tabs={[
        {
          title: "Code",
          path: "code",
          contentFullHeight: true,
          contentFullWidth: true,
        },
        {
          title: "Connected syncs",
          path: "syncs",
          count: fn.function_resources.length,
          size: "full",
        },
      ]}
      header={
        <Row align="center" justify="space-between">
          <Column minWidth={0} gap={2}>
            <PermissionedEditableHeading
              permission={{
                v1: { resource: "workspace", grant: "update" },
                v2: {
                  resource: "workspace",
                  grant: "can_update",
                },
              }}
              placeholder="Add a name..."
              size="lg"
              value={fn.name}
              onChange={(name) => updateFunction({ name })}
            />

            <PermissionedEditableDescription
              permission={{
                v1: { resource: "workspace", grant: "update" },
                v2: {
                  resource: "workspace",
                  grant: "can_update",
                },
              }}
              placeholder="Add a description..."
              value={fn.description ?? ""}
              onChange={(description) => updateFunction({ description })}
            />
          </Column>
          <Menu>
            <MenuActionsButton variant="secondary" />
            <MenuList>
              <PermissionedDeleteButton
                permission={{
                  v1: { resource: "workspace", grant: "update" },
                  v2: {
                    resource: "workspace",
                    grant: "can_update",
                  },
                }}
                variant="menu"
                label="function"
                onDelete={() =>
                  deleteFunctionMutation.mutateAsync({ id: fn.id })
                }
                onSuccess={() => {
                  navigate("/events/functions");
                }}
              />
            </MenuList>
          </Menu>
        </Row>
      }
    >
      <Suspense fallback={<PageSpinner />}>
        <Outlet context={{ function: fn }} />
      </Suspense>
    </InnerScrollableDetailPage>
  );
};

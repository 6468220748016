import { useState } from "react";

import { Column, Paragraph, SectionHeading, Text } from "@hightouchio/ui";

import { Table } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { EventDrawer } from "./components/drawer";
import { FailedEvent, useFailedEventsQuery } from "src/graphql";
import { useOutletContext } from "src/router";
import { OutletContext } from "..";
import { formatDateOrDatetimeFromEpoch } from "src/utils/time";

export const ForwardingSyncFailuresArchived = () => {
  const { sync } = useOutletContext<OutletContext>();

  const { data: events } = useFailedEventsQuery(
    {
      input: {
        syncId: sync.id,
        type: "ARCHIVED",
      },
    },
    { select: (data) => data.getFailedEvents.events, suspense: true },
  );

  const [selectedEvent, setSelectedEvent] = useState<FailedEvent>();

  return (
    <>
      <Column>
        <SectionHeading>Archived failures</SectionHeading>
        <Paragraph color="text.secondary">
          Failed events are archived after 8 hours of retrying.
        </Paragraph>
      </Column>
      <Table
        columns={[
          {
            name: "Event type",
            max: "15%",
            cell: ({ payload }) => (
              <TextWithTooltip fontWeight="medium">
                {payload.type}
              </TextWithTooltip>
            ),
          },
          {
            name: "Event name",
            max: "20%",
            cell: ({ payload }) => (
              <TextWithTooltip fontWeight="medium">
                {payload.event ?? "--"}
              </TextWithTooltip>
            ),
          },
          {
            name: "Received at",
            max: "20%",
            cell: ({ firstAttemptAt }) => (
              <Text fontWeight="medium">
                {formatDateOrDatetimeFromEpoch(firstAttemptAt)}
              </Text>
            ),
          },
          {
            name: "Attempts",
            max: "10%",
            cell: ({ totalAttempts }) => (
              <Text fontWeight="medium">{totalAttempts}</Text>
            ),
          },
          {
            name: "Error",
            cell: ({ errorMsg }) => (
              <TextWithTooltip fontWeight="medium">
                {errorMsg || ""}
              </TextWithTooltip>
            ),
          },
        ]}
        data={events}
        onRowClick={(event) => setSelectedEvent(event)}
        placeholder={{
          title: "No archived failures",
          body: "Failed events are archived after 8 hours of retrying.",
        }}
      />
      <EventDrawer
        title="Archived failure"
        event={selectedEvent}
        onClose={() => {
          setSelectedEvent(undefined);
        }}
      />
    </>
  );
};

import { useEffect, useMemo } from "react";

import immutableUpdate from "immutability-helper";
import { UseFormSetValue } from "react-hook-form";

import { useHightouchForm } from "src/components/form";
import { AnalyticsFrequency, NormalizationType } from "src/types/visual";

import {
  AnalyticsActions,
  ChartFormState,
  ChartTab,
  GroupByColumn,
  isSyntheticMetric,
  MeasuringMode,
  MeasuringSelection,
  TimeOptions,
} from "src/pages/analytics/types";
import { graphTypeRequiresAllFrequency } from "../utils";
import {
  DEFAULT_GROUP_BY_COLUMNS,
  DEFAULT_METRIC_SELECTION,
  defaultFormState,
  defaultFunnelChartState,
  defaultInsightsChartState,
} from "./constants";
import { ALL_DATA_MEASURING_SELECTION } from "../constants";

type UseChartStateArgs = {
  useUrlState?: boolean;
  parentModelId?: number;
};

export const useChartState = ({
  useUrlState,
  parentModelId,
}: UseChartStateArgs) => {
  const form = useHightouchForm({
    // not using submit in this product.
    // instead, the polling happens when form is valid
    onSubmit: () => Promise.resolve(),
    values: { ...defaultFormState, parentModelId },
    queryKey: useUrlState ? "data" : undefined,
  });

  const setValue: UseFormSetValue<ChartFormState> = (name, value) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    form.setValue(name, value, { shouldDirty: true });

  const selectParentModel = (value: number) => {
    // This value is a bigint. Must be a number, but it's typed as a string
    form.reset({
      ...defaultFormState,
      graphType: form.getValues("graphType"),
      parentModelId: value,
    });
  };

  const selectMeasurement = (measuringSelection: MeasuringSelection) => {
    form.setValue("measuringSelection", measuringSelection);

    // Reset the form as certain options are dependent on the measuring scope
    setValue("metricSelection", DEFAULT_METRIC_SELECTION);
    setValue("groupByColumns", DEFAULT_GROUP_BY_COLUMNS);
    setValue("measuringMode", undefined);
  };

  /**
   * Set the measuring mode and whatever defaults are needed for that mode
   * Also reset any fields that could be dependent on the mode
   */
  const selectMeasurementMode = (mode: MeasuringMode) => {
    const metricSelections = form.getValues("metricSelection");

    if (mode === MeasuringMode.Incrementality) {
      const filteredMetricSelection = metricSelections
        .filter((metric) => !isSyntheticMetric(metric))
        .map((metric) => ({
          ...metric,
          normalization: NormalizationType.TreatmentSize,
        }));

      form.reset({
        ...form.getValues(),
        measuringMode: mode,
        groupByColumns: DEFAULT_GROUP_BY_COLUMNS,
        metricSelection:
          filteredMetricSelection?.length > 0
            ? filteredMetricSelection
            : DEFAULT_METRIC_SELECTION,
        cumulative: true,
      });
    } else {
      form.reset({
        ...form.getValues(),
        measuringMode: mode,
        groupByColumns: DEFAULT_GROUP_BY_COLUMNS,
        metricSelection: metricSelections.map((metric) => ({
          ...metric,
          normalization: undefined,
        })),
        cumulative: false,
      });
    }
  };

  const addGroupByColumn = (
    column: GroupByColumn | undefined,
    index: number,
  ) => {
    const groupByColumns = form.getValues("groupByColumns") ?? [];
    setValue(
      "groupByColumns",
      immutableUpdate(groupByColumns, { [index]: { $set: column } }),
    );
  };

  // TODO: combine these two functions into one
  const addGroupByColumns = (
    columns: (GroupByColumn | undefined)[],
    fromIndex: number,
  ) => {
    const groupByColumns = form.getValues("groupByColumns") ?? [];

    const prevGroupBys = groupByColumns.slice(0, fromIndex);
    const groupByUpdate = prevGroupBys.concat(columns);

    setValue("groupByColumns", groupByUpdate);
  };

  const removeGroupByColumns = (startIndex: number, endIndex?: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - Circular reference problem with Column types
    const groupByColumns = form.getValues("groupByColumns") ?? [];
    const newGroupByColumns = groupByColumns
      .slice(0, startIndex)
      .concat(groupByColumns.slice((endIndex ?? startIndex) + 1));

    setValue(
      "groupByColumns",
      newGroupByColumns.length > 0
        ? newGroupByColumns
        : DEFAULT_GROUP_BY_COLUMNS,
    );
  };

  const resetView = (chartTab: ChartTab) => {
    if (chartTab === ChartTab.Funnel) {
      form.reset({
        ...form.getValues(),
        measuringSelection: ALL_DATA_MEASURING_SELECTION,
        ...defaultFunnelChartState,
      });
    } else {
      form.reset({
        ...form.getValues(),
        measuringSelection: ALL_DATA_MEASURING_SELECTION,
        ...defaultInsightsChartState,
      });
    }
  };

  const setLookbackWindow = (value: TimeOptions) => {
    setValue("selectedDates", []);
    setValue("timeValue", value);
  };

  const setSelectedDates = (dates: Date[]) => {
    setValue(
      "selectedDates",
      dates.map((date) => date.toISOString()),
    );
    setValue("timeValue", TimeOptions.Custom);
  };

  const graphType = form.watch("graphType");

  // Ensure that rollupFrequency is not 'all' for certain graph types
  useEffect(() => {
    if (
      !graphTypeRequiresAllFrequency(graphType) &&
      form.getValues("rollupFrequency") === AnalyticsFrequency.All
    ) {
      setValue("rollupFrequency", AnalyticsFrequency.Weekly);
    }
  }, [graphType]);

  const setChartTab = (tab: ChartTab) => {
    form.setValue("chartTab", tab);
    resetView(tab);
  };

  const actions: AnalyticsActions = useMemo(
    () => ({
      addGroupByColumn,
      addGroupByColumns,
      removeGroupByColumns,
      resetView,
      selectParentModel,
      setLookbackWindow,
      setSelectedDates,
      selectMeasurement,
      selectMeasurementMode,
      setChartTab,
    }),
    [
      addGroupByColumn,
      addGroupByColumns,
      removeGroupByColumns,
      resetView,
      selectParentModel,
      setLookbackWindow,
      setSelectedDates,
      selectMeasurement,
      selectMeasurementMode,
      setChartTab,
    ],
  );

  return {
    form,
    ...actions,
  };
};
